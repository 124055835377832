import React from "react";

import "./Banner.css";

import BannerSqaure from "./bannerSqaure/BannerSqaure";

const Banner = () => {
  return (
    <>
      <div className="aboutDepartmentWebBanner">
        <BannerSqaure />
      </div>
    </>
  );
};

export default Banner;
