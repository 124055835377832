import { Link } from "react-router-dom";

const AnalyzesSquare = ({ img, textImg, header, text, btn, link }) => {
  return (
    <div className="openSquare analyzesSquare">
      <div className="imgAndText">
        <img className="analyzesSquareTextImg" src={textImg} alt="aa" />
        <img className="openSquareImg" src={img} alt="aa" />
        <div className="openSquareText">
          <div className="openSquareTextH">{header}</div>
          <div className="openSquareTextP">{text}</div>
        </div>
      </div>
      <Link className="openSquareBtn" to={link}>
        פתיחה
      </Link>
    </div>
  );
};

export default AnalyzesSquare;
