import '../Staff.css';
import OpenSquare from '../../../../regularPages/repeatsComponents/opensquare/OpenSquare';
import Search from '../../../../regularPages/repeatsComponents/search/Search'
import ListHeader from '../../../../regularPages/repeatsComponents/listheader/ListHeader'
import Head from '../../../../repeats/head/Head';
import Footer from '../../../../repeats/footer/Footer';

import StarRoundIcon from '@mui/icons-material/Star';

const ForNurse = () => {

	const PatientSquareArr = [
		{
			img: '/images/forNurse/Droppers.jpg',
			header: 'עירויים',
			text: '',
			link: '',
		},
		{
			img: '/images/forNurse/Injections.jpg',
			header: 'זריקות',
			text: '',
			link: '',
		},
		{
			img: '/images/forNurse/baby.jpg',
			header: 'טיפול בתינוק',
			text: '',
			link: '',
		},
		{
			img: '/images/forNurse/other.jpg',
			header: 'שונות',
			text: '',
			link: '',
		},
	]

	const ListHeaderObj = {
		header: 'מטופלת - ',
		headerSpan: 'שרה זילבר',
		par: 'מחלקת יולדות - ',
		parSpan: `חדר מס' 28, מיטה  מס' 2`,
	}

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='לאחות' />
				<div className='staffPage'>
					<Search icon={<StarRoundIcon className="searchStar" />} />
					<ListHeader
						header={ListHeaderObj.header}
						headerSpan={ListHeaderObj.headerSpan}
						par={ListHeaderObj.par}
						parSpan={ListHeaderObj.parSpan}
					/>
					<div className='staffSquaresDiv'>
						{
							PatientSquareArr.map((el, i) => {
								return (
									<OpenSquare
										img={el.img}
										header={el.header}
										text={el.text}
										link={el.link}
										key={i}
									/> 
								)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>

	)
}

export default ForNurse