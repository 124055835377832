import './ProductsList.css'
import React, { useEffect } from 'react';
import { Link } from "react-router-dom"

const ProductSquare = ({img, off, header, price, link, i}) => {

	useEffect(() => {
		if (off === '') {
			document.querySelectorAll('.productSquareImgOff')[i].style.backgroundColor = 'transparent'
		}
	},[]);

	return (
		<Link className='productSquareLink' to={link}>
			<div className='productSquare'>
				<div className='productSquareImg'>
					<img className='productSquareImgBG' src={img} />
					<img className='productSquareImgHeart' src='/images/web/productList/love.png' />
					<div className='productSquareImgOff'>{off}</div>
				</div>
				<div className='productSquareText'>
					<div className='productSquareTextH'>{header}</div>
					<div className='productSquareTextPrice'>{price}</div>
				</div>
			</div>
		</Link>
	)
}

export default ProductSquare