import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import banner from "../../../../../assets/images/web/club/banner.jpg";

const BannerSqaure = () => {
  const bannerArr = [
    {
      image: { banner },
      title: "סיור בחדרי הלידה",
      // detail: "24/04/2022",
      link: "#",
    },
    {
      image: { banner },
      title: "סיור בחדרי הלידה",
      // detail: "24/04/2022",
      link: "#",
    },
    {
      image: { banner },
      title: "סיור בחדרי הלידה",
      // detail: "24/04/2022",
      link: "#",
    },
  ];

  const slides = bannerArr.map((el, i) => (
    <Container>
      <Link to={"" + el.link + ""}>
        <img src={el.image.banner} className="bannerImg" alt="banner" />
        <div className="bannerDiv">
          <h1 className="bannerDivTitle">{el.title}</h1>
          {/*<p className="bannerDivDetail">{el.detail}</p>*/}
        </div>
      </Link>
    </Container>
  ));

  return (
    <>
      <Swiper className="mySwiper" slidesPerView={1} spaceBetween={5}>
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default BannerSqaure;