import FileBtn from './FileBtn'

const ShareFiles = () => {

	const btnsArr = [
		{
			text: 'PDF',
		},
		{
			text: 'מסמך',
		},
		{
			text: 'אקסל',
		},	
	]

	const pick = (e) => {
		const choosen = e.target
		const options = document.querySelectorAll('.fileBtn')
		for (let i = 0; i < options.length; i++) {
			options[i].classList.remove('fileChoosen')
		}
		choosen.classList.add('fileChoosen')
	}

	return (
		<div className='shareFilesBoard' >
			<div className='shareFilesBoardH' >שיתוף מסמכים:</div>
			<div className='shareFilesBtns' >
				{
					btnsArr.map((el, i) => {
						return (
							<FileBtn
								pick={pick} 
								text={el.text}
								key={i}
							/> 
						)
					})
				}
			</div>
		</div>
	)
}

export default ShareFiles