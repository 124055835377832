import './Search.css';

import SearchIcon from "@mui/icons-material/Search";

const Search = ({icon}) => {
	return (
		<div className='SearchDiv' >
			<div className='Search' >
				{/* <img className='SearchImg' src='/images/vitamins/search.png' /> */}
				<SearchIcon className='SearchImg' />
				<input placeholder='חפש שם' type='search' className='SearchInput' />
			</div>
			{icon}
		</div>
	)
}

export default Search