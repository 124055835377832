import React from "react";
import LikeList from "./likeList/LikeList";
import "./LikePage.css";

const LikePage = () => {
  return (
    <div className="likePage">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <LikeList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikePage;
