import React, { useRef, useState } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import { Link } from "react-router-dom";
import arrow from "../../../assets/images/double_arrow_down.png";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import img from "../../../assets/images/Image.jpg";

// import filter_icon from "../../../assets/images/Filter.png";
// import location_icon from "../../../assets/images/location.png";
// import search_icon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

import "./MarketPlace.css";

import Filter from "./filter/Filter";
import PopularServicesSquareList from "./PopularServicesSquare/PopularServicesSquareList";
import TabBtn from "../../regularPages/repeatsComponents/tabsMenu/TabBtn";
import MarketPlaceSquareList from "./MarketPlaceSquare/MarketPlaceSquareList";
import VandorsSquareList from "./VandorsSquare/VandorsSquareList";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const MarketPlace = () => {
  const [showfilter, setShowfilter] = useState(false);

  const [showdrp, setShowdrp] = useState(false);

  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "ייעוץ",
    },
    {
      text: "קורסים",
    },
    {
      text: "אבחונים",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="marketpages rtl">
          <MobileView className="mv">
            <Head text="חנות שירותים" />
          </MobileView>
          <section className="search_sec" ref={messagesEndRef}>
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <img src={filterIcon} alt="filter" />
              </Link>
              <Link to={"#"} className="location_icon">
                {/* <img src={location_icon} alt="location_icon" /> */}
                <LocationOnIcon />
              </Link>
              <div className="search_form">
                {/* <button type="submit" className="btn_sub">
                  <img src={search_icon} alt="Search" className="SearchImg" />
                </button> */}
                <SearchIcon className="SearchImg" />
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          <section className="service_sec">
            <div className="doctor_heading d-flex align-center justify-between sec_heading_area">
              <Link to={"#"} className="d-flex align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon />
                לכל השירותים{" "}
              </Link>
              <h3>שירותים פופלרים</h3>
            </div>

            <PopularServicesSquareList />
          </section>

          <section className="vandors_sec">
            <div className="doctor_heading d-flex align-center justify-between sec_heading_area">
              <Link to={"#"} className="d-flex align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon />
                כל החנויות{" "}
              </Link>
              <h3>חנויות מומלצות</h3>
            </div>

            <VandorsSquareList />
          </section>

          <section className="service_list_sec">
            <div className="tabs">
              <ul
                id="tabs-nav"
                className="tabsMenu list-unstyled"
              >
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <MarketPlaceSquareList />
                </div>
              </div>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>
          <div className="category_list_sec">
            <h3>קטגוריות מומלצות</h3>
            <div className="category_list">
              <div className="list_box">
                <div className="box_img">
                  <img src={img} alt="images" />
                  <h6>שירותים רפואיים</h6>
                </div>
              </div>
            </div>
          </div>
          <MobileView>
            <Footer />
          </MobileView>
        </div>
      </div>
    </>
  );
};

export default MarketPlace;
