import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom"

const Icon = ({ icon, link }) => {

	const url = useNavigate()
	const [className, setclassName] = useState('navIconLink');

	useEffect(() => {
		const pageIndex = document.querySelector('.navIconLink').baseURI.lastIndexOf('/')
		const linkIndex = link.lastIndexOf('/')
		const page = document.querySelector('.navIconLink').baseURI.slice(pageIndex + 1)
		const cleanLink = link.slice(linkIndex + 1)
		// console.log(page)
		// console.log(cleanLink)
		if (page === cleanLink) {
			setclassName('navIconLink' + ' thisIcon')
		} else {
			setclassName('navIconLink')
		}
	}, [url]);

	return (
		<div className='navIconDiv'>
			<Link to={link} className={className} >
				{icon}
			</Link>
		</div>
	)
}

export default Icon