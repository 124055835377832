import React from "react";
import { Link } from "react-router-dom";

import SearchSqaure from "./SearchSqaure";

const SearchSqaureList = ({ header, searchLink, section, btnText }) => {
  return (
    <>
      <div className="searchAllSection">
        <Link to={searchLink} className="searchAllSectionLink">
          {header}
        </Link>
        <div className="searchAllItemsDiv">
          {section.map((el, i) => {
            return (
              <SearchSqaure
                itemLink={el.itemLink}
                img={el.img}
                text={el.text}
                btnText={btnText}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SearchSqaureList;
