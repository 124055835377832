import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const VandorsSquareList = () => {
  const PopularServicesListArr = [
    {
      image: "/images/marketPlace/vandors1.jpg",
      title: "פוקס הום",
      link: "/vandordetails",
    },
    {
      image: "/images/marketPlace/vandors2.jfif",
      title: "קפה ג'ו",
      link: "/vandordetails",
    },
    {
      image: "/images/marketPlace/vandors1.jpg",
      title: "פוקס הום",
      link: "/vandordetails",
    },
    {
      image: "/images/marketPlace/vandors2.jfif",
      title: "קפה ג'ו",
      link: "/vandordetails",
    },
    {
      image: "/images/marketPlace/vandors1.jpg",
      title: "פוקס הום",
      link: "/vandordetails",
    },
  ];

  const slides = PopularServicesListArr.map((el, index) => (
    <Link to={"" + el.link + ""} key={index}>
      <div className="slide_box">
        <div className="box_img">
          <img src={el.image} alt="vandors" />
        </div>
        <h6>{el.title}</h6>
      </div>
    </Link>
  ));

  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={2.5}
        className="vandors_slider"
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default VandorsSquareList;
