import React from "react";
import { Link } from "react-router-dom";

const MyAppointmentSquareList = ({
  image,
  h6_text_1,
  h6_text_2,
  image_p_text,
  p_text,
  link,
}) => {
  return (
    <>
      <p className="image_time">{p_text}</p>
      <div className="box_img">
        <Link to={"" + link + ""}>
          <img src={image} alt="images" height={"155px"} width="100%" />
        </Link>

        <h6 className="box_img_h6">{h6_text_1}</h6>
        <h6>{h6_text_2}</h6>
        <div className="time_dt d-flex align-content-center justify-content-center dark">
          <p className="time_dt_p">{image_p_text}</p>
        </div>
      </div>
    </>
  );
};

export default MyAppointmentSquareList;
