import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./LikeList.css"
import heartImg from "../../../../assets/images/web/productsList/love.png";
import heart from "../../../../assets/images/web/productsList/love_1.png";

const LikeSquare = ({img, off, header, price, link, i}) => {
  useEffect(() => {
		if (off === '') {
			document.querySelectorAll('.likeSquareImgOff')[i].style.backgroundColor = 'transparent'
		}
	},[]);
	
   
    const [ImgH, setImg] = useState(false);

    const imgChangeHandler = () => {
        if(!ImgH) {
            setImg(true);
        }else{
            setImg(false)
        }
    };
	return (
		<Link className='likeSquareLink' to={link}>
			<div className='likeSquare'>
				<div className='likeSquareImg'>
					<img className='likeSquareImgBG' src={img} />
					<img src={ImgH ? heart : heartImg } alt='bulb-off' className='likeSquareImgHeart' onClick={imgChangeHandler}/>
					{/* <img className='likeSquareImgHeart' src='/images/web/productList/love.png' /> */}
					<div className='likeSquareImgOff'>{off}</div>
				</div>
				<div className='likeSquareText'>
					<div className='likeSquareTextH'>{header}</div>
					<div className='likeSquareTextPrice'>{price}</div>
				</div>
			</div>
		</Link>
  )
}

export default LikeSquare