import { Link } from "react-router-dom";

const InfoSquare = ({img, header, paragraph, link}) => {

	return (
		<Link to={link} className='infoSquare' >
			<div className='infoSquareImgDiv' >
				{/* <img className='infoSquareImg' src={img} /> */}
				{img}
			</div>
			<div className='infoSquareTextDiv' >
				<h1 className='infoSquareH' >{header}</h1>
				<div className='infoSquareP' >{paragraph}</div>
			</div>
		</Link>
	)
}

export default InfoSquare