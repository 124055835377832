import React, { useState, useEffect } from "react";
import "./Departments.css";
import { Link } from "react-router-dom";
import DepartmentSquare from "./DepartmentSquare";
import { DepartmentAction } from "../../../../actions/department";
import { useDispatch } from "react-redux";
import Toast from "../../../../helpers/toast";
import ServiceListHeader from "../../../regularPages/serviceList/ServiceListHeader";

const Departments = () => {
  const departmentAction = DepartmentAction();

  const dispatch = useDispatch();

  const [department, setDepartment] = useState([]);
  
  useEffect(() => {
    (async () => {
      //This is to get service list
      await departmentAction
        .getDepartments()
        .then((response) => {
          dispatch({ type: "UNSET_LOADER" });

          if (response) {
            if (response.success == true) {
              setDepartment(response.departments);
            } else {
              Toast.error(response.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    })();
  }, []);

  const departmensArr1 = [
    {
      header: "יולדות",
      img: "/images/hospital/obstetrics.jpg",
      link: "/gynecology",
    },
    {
      header: "גינקולוגיה",
      img: "/images/hospital/gyn.jpg",
      link: "/gynecology",
    },
    {
      header: "אמהות",
      img: "/images/hospital/mom.jpg",
      link: "/gynecology",
    },
  ];

  const departmensArr2 = [
    {
      header: "תינוקות",
      img: "/images/hospital/babies.jpg",
      link: "/gynecology",
    },
    {
      header: "ילדים",
      img: "/images/hospital/kids.jpg",
      link: "/gynecology",
    },
    {
      header: "ניתוחים",
      img: "/images/hospital/surgery.jpg",
      link: "/gynecology",
    },
  ];

  return (
    <div className="departmens">
      <ServiceListHeader text="המחלקות שלנו:" link="/departments" text2='לכל המחלקות' />
      {/* <div className="departmensHeader">
        <div className="departmensHeaderH">המחלקות שלנו:</div>
        <Link to="/departments" className="departmensHeaderImgDiv">
          <img
            className="departmensHeaderImg"
            src="/images/hospital/viewheb.png"
            alt="view"
          />
        </Link>
      </div> */}
      {/* <div className="departmensRow">
        {department.length > 0
          ? department.map((el, i) => {
              return (
                <DepartmentSquare
                  img={el.photo}
                  header={el.name}
                  index={i}
                  key={i}
                />
              );
            })
          : ""}
      </div> */}
      <div className="departmensRow">
        {departmensArr1.map((el, i) => {
          return (
            <DepartmentSquare
              img={el.img}
              header={el.header}
              link={el.link}
              index={i}
              key={i}
            />
          );
        })}
      </div>
      <div className="departmensRow">
        {departmensArr2.map((el, i) => {
          return (
            <DepartmentSquare
              img={el.img}
              header={el.header}
              link={el.link}
              index={i}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Departments;

// export const Departmens = [
//   {
//     src: "/images/hospital/team.jpg",
//     thumbnail: "/images/hospital/team.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "!הצוות שלנו", title: "!הצוות שלנו" }],
//     caption: "!הצוות שלנו",
//   },
//   {
//     src: "/images/hospital/birth.jpg",
//     thumbnail: "/images/hospital/birth.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "חדרי לידה ויולדות", title: "חדרי לידה ויולדות" }],
//     caption: "חדרי לידה ויולדות",
//   },
//   {
//     src: "/images/hospital/gyn.jpg",
//     thumbnail: "/images/hospital/gyn.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "גינקולוגיה", title: "גינקולוגיה" }],
//     caption: "גינקולוגיה",
//   },
//   {
//     src: "/images/hospital/orogyn.jpg",
//     thumbnail: "/images/hospital/orogyn.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "אורוגינקולוגיה", title: "אורוגינקולוגיה" }],
//     caption: "אורוגינקולוגיה",
//   },
//   {
//     src: "/images/hospital/gynonoko.jpg",
//     thumbnail: "/images/hospital/gynonoko.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "גינקואונקולוגיה", title: "גינקואונקולוגיה" }],
//     caption: "גינקואונקולוגיה",
//   },
//   {
//     src: "/images/hospital/poriyut.jpg",
//     thumbnail: "/images/hospital/poriyut.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "פוריות", title: "פוריות" }],
//     caption: "פוריות",
//   },
//   {
//     src: "/images/hospital/mom.jpg",
//     thumbnail: "/images/hospital/mom.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "אם ועובר", title: "אם ועובר" }],
//     caption: "אם ועובר",
//   },
//   {
//     src: "/images/hospital/ultra.jpg",
//     thumbnail: "/images/hospital/ultra.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "אולטרסטונד נשים", title: "אולטרסטונד נשים" }],
//     caption: "אולטרסטונד נשים",
//   },
//   {
//     src: "/images/hospital/premature.jpg",
//     thumbnail: "/images/hospital/premature.jpg",
//     thumbnailWidth: 138,
//     thumbnailHeight: 138,
//     tags: [{ value: "פגים וילדים", title: "פגים וילדים" }],
//     caption: "פגים וילדים",
//   },
// ];
