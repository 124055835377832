import './Loader.css';
import React, { useEffect } from 'react';

const Loader = (props) => {

	return (
		<>
			<div id={props.loader ? "pageLoader" : ""} >

			</div>
		</>
	)
}

export default Loader