import React, { useRef, useState } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import arrow from "../../../assets/images/double_arrow_down.png";

import filter_icon from "../../../assets/images/Filter.png";
import location_icon from "../../../assets/images/location.png";
import search_icon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

import clubCategory from "../../../assets/images/club-category.jpg";

import "./Club.css";

import Filter from "./filter/Filter";
import ClubSquareList from "./ClubSquare/ClubSquareList";

const Club = () => {
  const [showfilter, setShowfilter] = useState(false);

  const [showdrp, setShowdrp] = useState(false);

  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="PageDad">
        <div className="club rtl">
          <Head text="חברות מועדון" />
          <section className="search_sec" ref={messagesEndRef}>
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <img src={filterIcon} alt="filter" />
              </Link>
              <Link to={"#"} className="location_icon">
                {/* <img src={location_icon} alt="location_icon" /> */}
                <LocationOnIcon />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  {/* <img src={search_icon} alt="search" className="SearchImg" /> */}
                  <SearchIcon className="SearchImg" />
                </button>
                <input
                  type="search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          <section className="service_sec">
            <div className="doctor_heading d-flex align-center justify-between sec_heading_area">
              <Link to={"#"} className="d-flex align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon />
                לכל האירועים{" "}
              </Link>
              <h3>ארועי המעודון</h3>
            </div>
          </section>
          <div className="category_list_sec">
            <div className="category_list">
              <div className="list_box">
                <div className="box_img">
                  <img
                    src={clubCategory}
                    alt="images"
                    height={"155px"}
                    width="100%"
                    className="club_category_img"
                  />
                  <h6>
                    24/04/2022
                    <br />
                    סיור בחדרי הלידה
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <section className="service_list_sec">
            <h6>הטבות לחברות המועדון</h6>
            <div id="tabs-content">
              {/* Service Section */}
              <ClubSquareList />
              {/* Service Section */}
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Club;
