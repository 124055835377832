import "./ServiceListHeader.css";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const ServiceListHeader = ({ text, link, text2 }) => {
  return (
    <div
      className="service_heading d-flex align-center justify-between row-reverse"
      id="serviceListHeader"
    >
      <Link to={link}>
        <KeyboardDoubleArrowLeftIcon />
        {text2 ? text2 : 'פתחי הכל'}
      </Link>
      <h3>{text}</h3>
    </div>
  );
};

export default ServiceListHeader;
