import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from "../../../actions/user";
import Helper from "../../../helpers/api";

const AccountMain = () => {
  const dispatch = useDispatch();

  const userAction = UserAction();

  const user = useSelector((state) => state.user);

  const [daysCount, setDaysCount] = useState({
    daysBeen: "",
    weeksCount: "",
    daysLeft: "",
  });

  let [state, setState] = useState({
    personImg: "/images/contact/photo.png",
    name: "",
    weeksCount: "",
    countImg: "/images/account/count2.png",
    daysBeen: ``,
    daysLeft: "",
  });
  

  let { personImg, name, weeksCount, countImg, daysBeen, daysLeft } = state;

  useEffect(() => {
    (async () => {
      await userAction.getPregnancy().then((response) => {
        dispatch({ type: "UNSET_LOADER" });

        if (response) {
          setState((state) => ({
            ...state,
            daysBeen: `יום ${response.pastDays ? response.pastDays : 0} עברו`,
            weeksCount: `שבוע ${response.week ? response.week : 0} להריון`,
            daysLeft: `נשארו ${response.daysLeft ? response.daysLeft : 0} יום`,
          }));

          setDaysCount({
            daysBeen: response.pastDays,
            weeksCount: response.week,
            daysLeft: response.daysLeft,
          });
        }
      });
    })();

    if (user) {
      setState((state) => ({
        ...state,
        name:
          user.firstName && user.firstName != null
            ? user.firstName + " " + user.lastName
            : user.email,
        personImg:
          user.profileImage && user.profileImage != ""
            ? Helper.apiBaseUrl + "uploads" + user.profileImage
            : "/images/contact/photo.png",
      }));
    }
  }, [user?.id]);

  let count = (daysCount.daysBeen * 100) / 280;
  let totalCount = Math.round(count);
  if (totalCount > 100) {
    totalCount = 100;
  }

  return (
    <div className="accountMain row">
      <div className="accountMainImgDiv col-lg-3">
        <div className="roundBox">
          <div className="innerRoundBox">
            <img
              className="accountMainImg"
              src={personImg ? personImg : ""}
            />
          </div>
        </div>
      </div>
      <div className="accountMainText col-lg-9">
        <h1 className="accountMainTextH">{name}</h1>
        <p className="accountMainTextP">{weeksCount}</p>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "" + totalCount + "%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {totalCount}%
          </div>
        </div>
        <div className="accountMainTextDays">
          <div className="daysBeen">{daysBeen}</div>
          <div className="daysLeft">{daysLeft}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountMain;
