import './Account.css';
import React, { useEffect } from 'react';
import AccountMain from './AccountMain';
import InfoList from './InfoList';
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';

import { UserAction }  from '../../../actions/user'
import { useSelector } from 'react-redux';


const Account = () => {

	const loader = useSelector((state) => state.loader);
	const user = useSelector((state) => state.user);
	const userAction = UserAction();

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='שרה זילבר' />
				<div className='accountPage'>
					<AccountMain />
					<InfoList />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Account