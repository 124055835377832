import React from "react";

import BestDealsSquare from "./BestDealsSquare";

const BestDealsSquareList = () => {
  const BestDealsListArr = [
    {
      best_deals_img: "/images/products/Products_img5.png",
      title: "פוקס הום",
      price: "15$",
      link: "#",
    },
    {
      best_deals_img: "/images/products/Products_img6.png",
      title: "פייר קארין",
      price: "690$",
      link: "#",
    },
  ];

  return (
    <div className="list_box_area d-flex justify-content-between row gx-4">
      {BestDealsListArr.map((el, i) => {
        return (
          <BestDealsSquare key={i}
            best_deals_img={el.best_deals_img}
            title={el.title}
            price={el.price}
            link={el.link}
          />
        );
      })}
    </div>
  );
};

export default BestDealsSquareList;
