import MyBabyDate from './MyBabyDate'

const UserSection = ({day, month, img, header, description}) => {
	return (
		<section className='userSection' >
			<MyBabyDate num='4' day={day} month={month} />
			<main className='userSectionMain' >
				<div className='userSectionImgDiv' >
					<img className='userSectionImg' src={img} />
					<h1 className='userSectionH' >
						{header}
						<p className='userSectionP' >{description}</p>
					</h1>
				</div>
			</main>
		</section>
	)
}

export default UserSection