import "./Vitamins.css";
import VitaminsSquare from "./VitaminsSquare";
import Head from "../../repeats/head/Head";
import Search from "../../regularPages/repeatsComponents/search/Search";
import { Link } from "react-router-dom";

import StarRoundIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';

const Vitamins = () => {
  const vitaminsArr = [
    {
      img: "/images/vitamins/acid.jpg",
      text: 'ארבע-מאות מיקרוגרמים  (מק"ג) של חומצה פולית',
    },
    {
      img: "/images/vitamins/d.jpg",
      text: 'ארבע-מאות יחב"ל של ויטמין די',
    },
    {
      img: "/images/vitamins/b12.jpg",
      text: 'שש  מק"ג של ויטמין ב12',
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="תרופות וויטמנים" isBackArrow={true} />
        <div className="vitaminsPage">
          <div className="vitaminsMain">
            <Search icon={<StarRoundIcon className="searchStar" />} />
            <div className="vitaminsTextDiv">
              <h1 className="vitaminsTextH">הזמיני את התרופות והויטמנים שלך</h1>
              <div className="vitaminsTextP">בחרי בתרופות שאת צורכת כרגע</div>
            </div>
            <div className="vitaminsSquaresContainer">
              <div className="vitaminsSquaresDiv">
                {vitaminsArr.map((el, i) => {
                  return <VitaminsSquare img={el.img} text={el.text} key={i} />;
                })}
              </div>
              <div className="vitaminsAddBtnDiv">
                <div className="VitaminsSquareMain">
                  <img
                    className="VitaminsSquareImg"
                    src="/images/vitamins/add.jpg"
                  />
                  <h1 className="VitaminsSquareHeader">הוסיפי תרופה חדשה</h1>
                </div>
                {/* <img
                  className="VitaminsAddBtn"
                  src="/images/myBaby/cross.png"
                /> */}
                <AddIcon className="VitaminsAddBtn" />
              </div>
            </div>
          </div>
        </div>
        <div className="box_btn">
          <div className="container">
            <div className="btn_area">
              <Link to={"#"} className="go_btn">
                לאישור
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vitamins;
