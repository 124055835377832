import React, { useRef, useState } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

// import filter_icon from "../../../assets/images/Filter.png";
// import location_icon from "../../../assets/images/location.png";
// import search_icon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

import "./Products.css";

import TabBtn from "../../regularPages/repeatsComponents/tabsMenu/TabBtn";
import ProductsSquareList from "./ProductsSquare/ProductsSquareList";
import BestDealsSquareList from "./BestDealsSquare/BestDealsSquareList";
import { Link } from "react-router-dom";

import Filter from "./../marketplace/filter/Filter";

const Products = () => {

  const [showfilter, setShowfilter] = useState(false);
  const [showdrp, setShowdrp] = useState(false);
  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "עגלות תינוקות",
    },
    {
      text: "בגדים",
    },
    {
      text: "אביזרים",
    },
    {
      text: "צעצועים",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="products_pages rtl">
          <Head text="מוצרים" />

          <section className="search_sec" ref={messagesEndRef}>
            <div className="search_area">
              <Link
                  to={"#"}
                  className="filter_icon"
                  onClick={() => {
                    setShowfilter(!showfilter);
                    if (showfilter) {
                      scrollToTop();
                    } else {
                      scrollToBottom();
                    }
                  }}
                >
                <img src={filterIcon} alt="filter" />
              </Link>
              <Link to={"#"} className="location_icon">
                {/* <img src={location_icon} alt="location_icon" /> */}
                <LocationOnIcon />
              </Link>
              <div className="search_form">
                {/* <button type="submit" className="btn_sub">
                  <img src={search_icon} alt="Search" className="SearchImg" />
                </button> */}
                <SearchIcon className="SearchImg" />
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          <section className="service_list_sec">
            <div className="tabs">
              <ul
                id="tabs-nav"
                className="list-unstyled"
              >
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <ProductsSquareList />
                </div>
              </div>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>

          <section className="service_list_sec best_deals_sec">
            <h3>דילים פופלרים</h3>
            <div className="tabs">
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <BestDealsSquareList />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Products;
