import React, { useEffect } from "react";
import InfoSquare from "./InfoSquare";

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { FaHeartbeat } from 'react-icons/fa';
import { FaHospitalAlt } from 'react-icons/fa';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { GiSiren } from 'react-icons/gi';

const InfoList = () => {
  const containerArr = [
    {
      img: <PhoneEnabledIcon className='infoSquareImg' />,
      header: "פרטים אישיים",
      paragraph: "שם, טלפון, אימייל",
      link: "/contact",
    },
    {
      img: <FaHeartbeat className='infoSquareImg' />,
      header: "מידע בריאותי",
      paragraph: "היסטוריה  רפואית והריון",
      link: "/health",
    },
    {
      img: <FaHospitalAlt className='infoSquareImg' />,
      header: "בתי חולים",
      paragraph: "המידע  שלך ושל בית החולים",
      link: "/hospital",
    },
    {
      img: <CalendarMonthIcon className='infoSquareImg' />,
      header: "הפגישות שלי",
      paragraph: "אולטראסאונד ,בדיקות וכדומה",
      link: "/myappointment",
    },
    {
      img: <GiSiren className='infoSquareImg' />,
      header: "מספרי חירום",
      paragraph: "אנשי החירום שלך שיקבלו התראה",
      link: "",
    },
  ];

  return (
    <main className="infoList">
      {containerArr.map((el, i) => {
        return (
          <InfoSquare
            img={el.img}
            header={el.header}
            paragraph={el.paragraph}
            link={el.link}
            key={i}
          />
        );
      })}
    </main>
  );
};

export default InfoList;
