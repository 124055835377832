import React from "react";
import Head from "../../repeats/head/Head";

import ServiceBanner from "./ServiceBanner";
import DoctorList from "../../regularPages/doctorList/DoctorList";

import "./ServiceDetails.css";
import { Link } from "react-router-dom";

const ServiceDetails = () => {
  return (
    <>
      <div className="PageDad">
        <div className="service_details_pages rtl">
          <Head text="ייעוץ הריון" />
          <ServiceBanner />
          <DoctorList />
          <div className="service_details_container">
            <div className="service_paragraph">
              <div className="service_details_heading">
                <h3 className="service_paragraph_price">
                  40$
                </h3>
                <h3 className="service_paragraph_title">:שירותים</h3>
              </div>
              <p className="service_paragraph_p">
                אגף נשים ויולדות דוגל ב- איכות ומצוינות עם יחס אישי. הכניסה
                לחדרי הלידה מהירה ואינה כרוכה בהמתנה ממושכת ובמטרה להעניק חוויית
                לידה מיטיבה עבור היולדת ומשפחתה. חדרי הלידה הם מהחדשים בארץ
                ומצוידים בציוד המודרני ביותר. חדרי הלידה פרטיים, חדשים
                וממוחשבים, עם ריהוט נעים ומוסיקת רקע . החדרים מרווחים עם שירותים
                ומקלחות צמודים, טלוויזיה וטלפון. כל חדר מתפקד כיחידה טיפולית
                עצמאית המאפשר טיפול ביולדת וביילוד בהתאם לצורך.
              </p>
              <p className="service_paragraph_p">
                חלק מחדרי הלידה מוגדרים כחדרי "ללידה טבעית", דהיינו – ללידות שלא
                צפויה בהן התערבות רפואית מיוחדת. הלידות בחדרים אלו מנוהלות עם
                ההתערבות המינימלית ביותר המתבקשת מצד הצוות המטפל. הציוד הרפואי
                מוסתר אך נמצא זמין בעת הצורך. בכל החדרים כניסת משפחה נפרדת
                הנפתחת ע"י כרטיס מגנטי שנמסר למשפחה עם תחילת הלידה, כך שכניסת
                המלווים אינה מושפעת מהמתרחש בחדר הלידה, וכן, נשמרת פרטיות מרבית
                ליולדת ולמשפחה.
              </p>
              <p className="service_paragraph_p">
                הצוות המיילדותי מציע ותומך באפשרות ללידה טבעית המותאמת לצרכי
                היולדת ולבקשותיה. מיילדות חדר לידה מלוות באופן צמוד את היולדות
                בחדר הלידה ולעיתים אף נשארות לאחר סיום המשמרת על מנת לקבל את
                הלידה הקרבה. המערך כולל מיון יולדות הכולל 4 חדרי בדיקה, 2 חדרי
                ניתוח חדשים הנמצאים במתחם חדרי הלידה, חדר התאוששות, חדר השהייה,
                חדרי טיפולים, חדרי צוות ו- 9 חדרי לידה מרווחים.
              </p>
              <p className="service_paragraph_p">
                לאחר הלידה תשהי עם תינוקך כשעתיים בחדר הלידה להתאוששות שלאחריה
                תועברו למחלקת יולדות. תוכלי לבחור את שיטת האשפוז המתאימה לכם:
                אפס הפרדה, ביות מלא או גמיש.
              </p>
            </div>
          </div>
          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link to={"/appointment"} className="go_btn">
                  קביעת פגישה
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
