// import LogRocket from 'logrocket'

import { useDispatch } from "react-redux";
import AxiosApi from "../helpers/axios.api";

require('dotenv').config()

export function ServiceAction() {
  const dispatch = useDispatch();
  const vendor_id = process.env.REACT_APP_VENDOR_ID;  
  
  const getServiceList = async () => {

    dispatch({ type: "SET_LOADER" });
    return await AxiosApi.withoutToken("vendor/services/"+vendor_id, "GET", []);
  };

  return {
    getServiceList
  };
}
