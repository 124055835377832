import vBtn from '../../../assets/images/vitamins/victory.png'

const VitaminsSquare = ({img, text, btn}) => {
	return (
		<div className='VitaminsSquare'>
			<div className='VitaminsSquareMain'>
				<img className='VitaminsSquareImg' src={img} />
				<h1 className='VitaminsSquareHeader' >{text}</h1>
			</div>
			<img className='VitaminsSquareBtn' src={vBtn} />
		</div>
	)
}

export default VitaminsSquare