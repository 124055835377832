import React from "react";
import { Link } from "react-router-dom";

const DepartmentSqaure = ({img, title, link}) => {
  return (
    <>
      <Link to={"" + link + ""} className="sqaureListLink">
        <div className="sqaureListDiv">
          <div className="sqaureListImgDiv">
            <img src={img} className="sqaureListImg" alt="sqaure" />
            <h1 className="sqaureListTitle">{title}</h1>
          </div>
        </div>
      </Link>
    </>
  );
};

export default DepartmentSqaure;
