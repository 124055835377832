import "./MyBaby.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MyBabyDate from "./MyBabyDate";
import Add from "./Add";
import UserSectionList from "./UserSectionList";
import UserSection from "./UserSection";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ShareIcon from '@mui/icons-material/Share';
import Pregnancy from "../../../assets/images/tellUs/pregnancy.png";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const MyBaby = ({ setPageInfo }) => {
  useEffect(() => {
    const displayExplain = () => {
      const plusExplain = document.querySelector(".plusExplain");
      if (plusExplain.style.color === "gray") {
        plusExplain.style.color = "transparent";
        plusExplain.style.backgroundColor = "transparent";
      } else {
        plusExplain.style.color = "gray";
        plusExplain.style.backgroundColor = "white";
      }
    };
    const plus = document.querySelector(".plus");
    plus.addEventListener("mouseenter", displayExplain);
    plus.addEventListener("mouseleave", displayExplain);
  }, []);

  // const displayAdd = (className) => {
  // 	const screen = document.querySelector('.hospitalAndUserSections')
  // 	const addDate = document.querySelector(className)
  // 	if (addDate.style.display === 'flex') {
  // 		addDate.style.display = 'none'
  // 		screen.style.opacity = '100%'
  // 	} else {
  // 		addDate.style.display = 'flex'
  // 		screen.style.opacity = '50%'
  // 	}
  // }

  return (
    <div className="PageDad">
      <div className="page">
        <MobileView className="mv">
          <Head text="ההריון שלי" />
        </MobileView>
        <div className="myBaby">
          <div className="hospitalAndUserSections">
            <section className="mybabySection">
              <MyBabyDate day="28" month="פבר" />
              <main className="mybabySectionMain mybabySectionMain1">
                <div className="roundBox">
                  <div className="innerRoundBox">
                    <img
                      className="mybabySection1Img"
                      src={Pregnancy}
                    />
                  </div>
                </div>
                <div className="mybabySection1Text">
                  <h1 className="mybabySection1TextHeader">כרגע</h1>
                  <p className="mybabySection1TextP">שבוע חמישי</p>
                  <button className="mybabySection1TextBtn">
                    מידע על השבוע
                  </button>
                </div>
              </main>
            </section>
            <UserSection
              day="31"
              month="ינו"
              img="/images/myBaby/start.jpg"
              header="תחילת הריון"
              description="בשעה טובה ומוצלחת, אנו מאחלים לך הריון תקין ולידה קלה"
            />
            <UserSection
              day="05"
              month="פבר"
              img="/images/myBaby/orpit.jpg"
              header="בדיקת שקיפות עורפית "
              description="עלייך לגשת למרפאה בה את מטופלת או למרכזי בריאות האישה על מנת לבצע את הבדיקה"
            />
            <UserSection
              day="05"
              month="פבר"
              img="/images/myBaby/helbon.jpg"
              header="בדיקת חלבון עוברי"
              description="עלייך לגשת למרפאה בה את מטופלת או למרכז בריאות האישה על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="28"
              month="פבר"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="07"
              month="מרץ"
              img="/images/myBaby/ultra1.jpg"
              header="בדיקת אולטרסאונד שליש ראשון"
              description='"דופק": עלייך לגשת למרפאה או למרכז בריאות האישה'
            />
            <UserSection
              day="07"
              month="מרץ"
              img="/images/myBaby/blood.jpg"
              header="בדיקת דם ושתן"
              description="עלייך לגשת למרפאה או  לחילופין למרכז בריאות האישה על מנת לבדוק דם ושתן"
            />
            <section className="mybabySection">
              <MyBabyDate day="12" month="מרץ" />
              <main className="mybabySectionMain mybabySectionMain2">
                <img
                  className="mybabySection2MainImg"
                  src="/images/myBaby/hands4.jpg"
                />
                <div className="mybabySection2SubImages">
                  <img
                    className="mybabySection2SubImages1"
                    src="/images/myBaby/ultraBaby.jpg"
                  />
                  <img
                    className="mybabySection2SubImages2"
                    src="/images/myBaby/ultrasound.jpg"
                  />
                </div>
              </main>
            </section>
            <section className="mybabySection mybabySection3">
              <MyBabyDate day="27" month="מרץ" />
              <main className="mybabySectionMain">
                <img
                  className="mybabySection3Img"
                  src="/images/myBaby/yoga.jpg"
                />
                <div className="mybabySection3Text">
                  <h1 className="mybabySection3TextHeader">
                    יוגה לנשים בהריון
                  </h1>
                  <div className="mybabySection3TextP">
                    מרכז יוגה לוטוס, באר -שבע
                  </div>
                  <div className="mybabySection3TextTime">11:00 am</div>
                  <div className="mybabySection3TextGrades">
                    <i className="fas fa-solid fa-star star"></i>
                    <div className="mybabySection3TextGradesScore">4.8</div>
                    <div className="mybabySection3TextGradesReviews">
                      (מעל לאלף ביקורות)
                    </div>
                  </div>
                </div>
              </main>
            </section>
            <UserSection
              day="28"
              month="מרץ"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <section className="mybabySection mybabySection4">
              <MyBabyDate num="4" day="04" month="אפר" />
              <main className="mybabySectionMain mybabySectionMain4">
                <h1 className="mybabySection4Header">
                  יש לך פגישת ייעוץ אונליין עם הרופא שלך
                </h1>
                <img
                  className="mybabySection4Btn"
                  src="/images/myBaby/arrow.png"
                />
              </main>
            </section>
            <UserSection
              day="04"
              month="אפר"
              img="/images/myBaby/nipt.jpg"
              header="בדיקת NIPT"
              description="מומלץ לבצע בדיקה זו באופן פרטני"
            />
            <UserSection
              day="04"
              month="אפר"
              img="/images/myBaby/cvs.jpg"
              header="בדיקה סיסי שיליה CVS"
              description="עלייך לגשת למכון פרטי או לבית חולים על מנת לבצע את הבדיקה"
            />
            <UserSection
              day="11"
              month="אפר"
              img="/images/myBaby/orpit.jpg"
              header="בדיקת שקיפות עורפית "
              description="עלייך לגשת למרפאה בה את מטופלת או למרכזי בריאות האישה על מנת לבצע את הבדיקה"
            />
            <UserSection
              day="25"
              month="אפר"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="02"
              month="מאי"
              img="/images/myBaby/skira.jpg"
              header="בדיקת סקירת מערכות מוקדמות"
              description="עלייך לבצע בבית חולים באופן פרטני בדיקה זו על מנת לבדוק את שלמות מערכת הגוף ובדיקת תפקודו של העובר"
            />
            <UserSection
              day="16"
              month="מאי"
              img="/images/myBaby/helbon.jpg"
              header="בדיקת חלבון עוברי"
              description="עלייך לגשת למרפאה בה את מטופלת או למרכז בריאות האישה על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="16"
              month="מאי"
              img="/images/myBaby/shafir.jpg"
              header="בדיקת מי שפיר"
              description="עלייך לגשת לבתי החולים שבהסדר על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="23"
              month="מאי"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="06"
              month="יוני"
              img="/images/myBaby/skira2.jpg"
              header="סקירת מערכות מאוחרות"
              description="עלייך לגשת למרכזי בריאות האישה או למרפאות על מנת לבצע בדיקה זו	"
            />
            <UserSection
              day="06"
              month="יוני"
              img="/images/myBaby/ultra2.jpg"
              header="אולטרסאונד אקו לב עובר"
              description="עלייך לגשת לקרדיולוג ילדים מומחה בבית חולים או למכון פרטי על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="20"
              month="יוני"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="11"
              month="יולי"
              img="/images/myBaby/sucar.jpg"
              header="העמסת סוכר, בדיקות דם ושתן"
              description="עלייך לגשת למרכזי בריאות האישה או למרפאות על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="18"
              month="יולי"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="15"
              month="אוג"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="22"
              month="אוג"
              img="/images/myBaby/weight.jpg"
              header="הערכת משקל"
              description="עלייך לגשת למרפאה, מרכז בריאות האישה, מכון אולטרסאונד על מנת לבצע בדיקה זו"
            />
            <UserSection
              day="12"
              month="ספט"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="26"
              month="ספט"
              img="/images/myBaby/gbs.jpg"
              header="בדיקת תרבית ל-GBS"
              description="עלייך לגשת לרופא המטפל או לבצע בדיקה זו באופן עצמאי והלביא למרפאה שבה את מטופלת"
            />
            <UserSection
              day="03"
              month="אוק"
              img="/images/myBaby/trimester.jpg"
              header="מעקב אחות טרימסטר שלישי"
              description="נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב"
            />
            <UserSection
              day="10"
              month="אוק"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="17"
              month="אוק"
              img="/images/myBaby/trimester.jpg"
              header="מעקב אחות טרימסטר שלישי"
              description="נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב"
            />
            <UserSection
              day="24"
              month="אוק"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="31"
              month="אוק"
              img="/images/myBaby/trimester.jpg"
              header="מעקב אחות טרימסטר שלישי"
              description="נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב"
            />
            <UserSection
              day="07"
              month="נוב"
              img="/images/myBaby/nurse.jpg"
              header="מעקב אחות"
              description="עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות."
            />
            <UserSection
              day="08"
              month="נוב"
              img="/images/myBaby/tomuch.jpg"
              header="מעקב הריון עודף"
              description="עלייך לגשת לקופת החולים או לבית החולים בו את מטופלת"
            />
            <UserSectionList />
          </div>
          <div>
            <div className="fixedBtns">
              <div className="white">
                <Link to="/mybaby/add" className="plusLink">
                  <AddRoundedIcon className="plus" />
                  {/* <img
                    className="plus"
                    src="/images/myBaby/cross.png"
                    width="30px"
                  /> */}
                </Link>
                <div className="plusExplain">העלי קבצים</div>
                <ShareIcon className="share" />
                {/* <i className="share fas fa-solid fa-share"></i> */}
              </div>
            </div>
          </div>
          {/*<Add displayAdd={displayAdd} />*/}
        </div>
      </div>
      <MobileView>
        <Footer />
      </MobileView>
    </div>
  );
};

export default MyBaby;
