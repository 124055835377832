import React from "react";
import { Container, FormControl, InputGroup } from "react-bootstrap";

import "./Banner.css";

import Search from "@mui/icons-material/Search";
import MicIcon from '@mui/icons-material/Mic';

const Banner = () => {
  return (
    <>
      <div className="doctorsSearchBanner">
        <Container>
          <div className="bannerContent">
            <div className="bannerDiv d-flex justify-content-center">
              <h1 className="bannerDivH1">רופאים</h1>
            </div>
            <div className="bannerSearchDiv d-flex justify-content-center">
              <MicIcon className="microphone" />
              <InputGroup className="mb-3 bannerSearchDivInputGroup">
                <FormControl
                  type="search"
                  className="bannerSearchDivInput"
                  placeholder="חיפוש"
                  aria-label="Search"
                  aria-describedby="search"
                />
                <InputGroup.Text id="search">
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
