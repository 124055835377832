import React from "react";

import arrow from "../../../assets/images/double_arrow_down.png";
import star from "../../../assets/images/star.svg";

import doctor1 from "../../../assets/images/doctor/doctor1.jpg";
import doctor2 from "../../../assets/images/doctorlist/doctor2.jpg";
import doctor3 from "../../../assets/images/doctorlist/doctor3.jpg";
import doctor4 from "../../../assets/images/doctorlist/doctor4.jpg";
import doctor5 from "../../../assets/images/doctor/doctor5.png";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "./DoctorList.css";
import DoctorListSqaure from "./DoctorListSquare/DoctorListSqaure";

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const DoctorList = () => {
  return (
    <div className="doctors_sec">
      <div className="container">
        <div className="doctors_area">
          <div className="doctor_heading align-center justify-between">
            <Link to={"/doctors"} className="align-center">
              {/* <img src={arrow} alt="double-arrow" /> */}
              <KeyboardDoubleArrowLeftIcon />
              לכל הרופאים
            </Link>
            <h3>: הרופאים שלנו</h3>
          </div>
          <div className="row doctor_box_area">
            <DoctorListSqaure />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorList;
