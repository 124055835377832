import React from "react";
import { Link } from "react-router-dom";
import three_dots from "../../../assets/images/three_dots.svg";

const DepartmentBanner = () => {
  return (
    <div className="banner">
      <Link to={"#"} className="menu_btn">
        <img src={three_dots} alt="three_dots" />
      </Link>
      <div className="banner_content">
        <h1 className="w-100">המרכז לטיפול בהריון</h1>
        <h6>חולון, הלוחמים 62</h6>
      </div>
    </div>
  );
};

export default DepartmentBanner;
