import "./Health.css";
import AnalyzesSquare from "../../hospitalStay/analyzes/AnalyzesSquare";
import DayPicker from "../../regularPages/repeatsComponents/daypicker/DayPicker";
import HealthSquare from "./HealthSquare";
import Search from "../../regularPages/repeatsComponents/search/Search";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import StarRoundIcon from '@mui/icons-material/Star';

const Health = () => {
  const healthSquareArr = [
    {
      img: "/images/health/pressure.jpg",
      textImg: "/images/health/great.png",
      header: "לחץ",
      text: "110/70",
      btn: "/images/health/add.png",
      link: "",
    },
    {
      img: "/images/health/temperature.jpg",
      textImg: "/images/health/great.png",
      header: "חום גוף",
      text: "36.6",
      btn: "/images/health/add.png",
      link: "",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="מידע בריאותי" />
        <div className="birthPlanPage healthPage">
          <Search icon={<StarRoundIcon className="searchStar" />} />
          <DayPicker />
          <div className="birthPlanSquaresDiv">
            {healthSquareArr.map((el, i) => {
              return (
                <AnalyzesSquare
                  img={el.img}
                  textImg={el.textImg}
                  header={el.header}
                  text={el.text}
                  btn={el.btn}
                  link={el.link}
                  key={i}
                />
              );
            })}
            <HealthSquare
              img="/images/health/others.png"
              header="תסמינים אחרים"
              text="בחרי תסמינים"
              btn="/images/health/add.png"
              link=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Health;
