import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Banner from "./banner/Banner";

import "./DepartmentsSearch.css";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const DepartmentsSearch = () => {
  let navigate = useNavigate();

  const DepartmentList = [
    {
      title: "גינקולוגיה",
      button_tex: "קראי עוד",
    },
    {
      title: "מחלקת יולדות",
      button_tex: "קראי עוד",
    },
    {
      title: "רפואת ילדים",
      button_tex: "קראי עוד",
    },
    {
      title: "תרפיה",
      button_tex: "קראי עוד",
    },
    {
      title: "קרדיולוגיה",
      button_tex: "קראי עוד",
    },
    {
      title: "ניתוחים",
      button_tex: "קראי עוד",
    },
    {
      title: "מחלקת טראומה",
      button_tex: "קראי עוד",
    },
    {
      title: "אורתופדיה",
      button_tex: "קראי עוד",
    },
    {
      title: "מחלקת זיהומים",
      button_tex: "קראי עוד",
    },
    {
      title: "אונוקולגיה",
      button_tex: "קראי עוד",
    },
  ];

  const goToDepartment = () => {
    navigate("/web/page/department");
  };

  return (
    <>
      <div className="departmentsSearchPages">
        <Banner />

        <Container className="departmentListContainer mt-5">
          {DepartmentList.map((el, i) => {
            return (
              <div
                className="departmentDetail d-flex align-items-center justify-content-between mt-3"
                key={i}
              >
                <Button
                  className="departmentDetailBtn"
                  onClick={goToDepartment}
                >
                  <Row>
                    <Col sm={1} className="departmentDetailArrowIcon">
                      <ArrowBackIosIcon />
                    </Col>
                    <Col sm={11} className="departmentDetailBtnText">
                      {el.button_tex}
                    </Col>
                  </Row>
                </Button>
                <h1 className="departmentDetailTitle">{el.title}</h1>
              </div>
            );
          })}
        </Container>
        <div className="departmentSearchPagesMoreBtnDiv d-flex justify-content-center align-items-center mt-5">
          <Button className="departmentSearchPagesMoreBtn">עוד...</Button>
        </div>
      </div>
    </>
  );
};

export default DepartmentsSearch;
