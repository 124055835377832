import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

function App() {
  // console.log(window.location.href);
  // if (isBrowser && window.location.href.includes(/web/page/) === '/') {
  //   window.location.href = '/web/page/home'
  // } else if (isMobile && window.location.href === '/web/page/home') {
  //   window.location.href = '/'
  // }
  // const navigate = useNavigate();
  // navigate(isBrowser ? "/web/page/home" : isMobile ? "/" : "", { replace: true });

  return (
    <BrowserRouter>
      {/* <Routes> */}
      <MainRoutes />
      {/* </Routes> */}
    </BrowserRouter>
  );
}

export default App;
