import React from "react";
import { Container } from "react-bootstrap";
import Banner from "./banner/Banner";
import SearchList from "./SearchList.js";
import departments from "../../../../assets/images/search/Masking.jpg";
import doctor from "../../../../assets/images/search/Card1.jpg";
import treatments from "../../../../assets/images/search/Card_2.jpg";
import contact from "../../../../assets/images/search/Masking1.jpg";
import about_us from "../../../../assets/images/search/Masking3.jpg";

const SearchPage = () => {
  const SearchImage = [
    {
      img: contact,
      text: "אנשי קשר",
      link: "#",
    },
    {
      img: doctor,
      text: "רופאים",
      link: "/web/page/doctorsearch",
    },
    {
      img: treatments,
      text: "טיפולים",
      link: "/web/page/treatmentsearch",
    },
    {
      img: departments,
      text: "מחלקות",
      link: "/web/page/departmentsearch",
    },
    {
      img: about_us,
      text: "אודות",
      link: "#",
    },
  ];
  return (
    <div className="searchWebPage">
      <Banner />
      <Container className="searchPagesContainer d-flex justify-content-center mt-4">
        {SearchImage.map((el, i) => {
          return (
            <SearchList image={el.img} text={el.text} link={el.link} key={i} />
          );
        })}
      </Container>
    </div>
  );
};

export default SearchPage;
