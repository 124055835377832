import React, { Component } from "react";
import { Link } from "react-router-dom"

const FooterSquare = ({ link, img, header, text, detail }) => {
	return (
		<Link to={link} className='footerSquare'>
			{img}
			<div className='footerSquareH' >{header}</div>
			<div className='footerSquareP' >{text}</div>
			<div className='footerSquareDetail' >{detail}</div>
		</Link>
	)
}

export default FooterSquare