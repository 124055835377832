import React from "react";

import heart from "../../../../assets/images/icons/heart.svg";
import ProductsSquare from "./ProductsSquare";

const ProductsSquareList = () => {
  const ProductsListArr = [
    {
      product_img: "/images/products/Products_img1.png",
      heart_img: { heart },
      title: "עגלת תינוקת",
      detail: "עגלה משולבת",
      price: "315$",
      link: "/productdetails",
      product_img_key: 1,
    },
    {
      product_img: "/images/products/Products_img2.png",
      heart_img: { heart },
      title: "צעצועים",
      detail: "משחקים מעץ",
      price: "15$",
      link: "/productdetails",
      product_img_key: 2,
    },
    {
      product_img: "/images/products/Products_img4.png",
      heart_img: { heart },
      title: "חליפות",
      detail: "100% כותנה",
      price: "600$",
      link: "/productdetails",
      product_img_key: 3,
    },
    {
      product_img: "/images/products/Products_img3.png",
      heart_img: { heart },
      title: "גלגל ים",
      detail: "אוקיינוס",
      price: "15$",
      link: "/productdetails",
      product_img_key: 4,
    },
  ];

  return (
    <div className="list_box_area d-flex justify-content-between row gx-4">
      {ProductsListArr.map((el, i) => {
        return (
          <ProductsSquare key={i}
            product_img={el.product_img}
            heart_img={el.heart_img}
            title={el.title}
            detail={el.detail}
            price={el.price}
            link={el.link}
            product_img_key={el.product_img_key}
          />
        );
      })}
    </div>
  );
};

export default ProductsSquareList;
