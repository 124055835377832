import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const MyHealthSquareList = () => {
    const MyHealthListArr = [
        {
            image: "/images/myHealth/my_health_img1.png",
            title: "הגוף שלי",
            link: "/mybody",
        },
        {
            image: "/images/myHealth/my_health_img2.jpg",
            title: "אורח חיים בריא",
            link: "/fitness",
        },
        {
            image: "/images/myHealth/my_health_img3.jpg",
            title: "פרוצדורות",
            link: "/procedures",
        },
    ];

    const slides = MyHealthListArr.map((el, index) => (
        <div className="" key={index}>
            <Link to={"" + el.link + ""}>
                <div className="my_health_box">
                    <div className="my_health_box_img">
                        <img src={el.image} alt="my_health1" />
                        <h6>{el.title}</h6>
                    </div>
                </div>
            </Link>
        </div>
    ));

    return (
        <>
            <Swiper spaceBetween={15} slidesPerView={3}>
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default MyHealthSquareList;