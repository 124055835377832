import HealthSquare from './HealthSquare'

const HealthList = () => {

	const HealthListArr = [
		{
			link: '',
			// bg: '/images/mybody/steps.jpg',
			header: 'הצעדים שלי',
			par: 'היום עלייך ללכת  4785 צעדים',
			percentImg: '/images/mybody/60.png',
			percent: '60%',
		},
		{
			link: '',
			// bg: '/images/mybody/sleep.jpg',
			header: 'השינה שלי',
			par: 'היום יש לישון 8 שעות שינה',
			percentImg: '/images/mybody/90.png',
			percent: '90%',
		},
		{
			link: '',
			// bg: '/images/mybody/water.jpg',
			header: 'כמות המים היומית',
			par: 'היום יש לשתות 2 ליטר מים',
			percentImg: '/images/mybody/90.png',
			percent: '90%',
		},		
	]

	return (
		<div className='healthList' >
					{
						HealthListArr.map((el, i) => {
							return (
								<HealthSquare
									link={el.link} 
									// bg={el.bg}
									header={el.header}
									par={el.par}
									percentImg={el.percentImg}
									percent={el.percent}
									key={i}
								/> 
							)
						})
					}
		</div>
	)
}

export default HealthList