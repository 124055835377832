const ProcedursSquare = ({header, text, day, date}) => {

	return (
		<div className='procedursSquare'>
			<div className='procedursSquareDate'>
				<div className='procedursSquareDateDay'>{day}</div>
				<div className='procedursSquareDateDate'>{date}</div>
			</div>
			<div className='procedursSquareTextDiv'>
				<h1 className='procedursSquareTextDivH'>{header}</h1>
				<div className='procedursSquareTextDivP'>{text}</div>
			</div>
		</div>
	)
}

export default ProcedursSquare