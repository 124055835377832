import React from "react";
import { Container } from "react-bootstrap";

import Banner from "./banner/Banner";

import "./SearchAll.css";

import SearchSqaureList from "./searchSqaure/SearchSqaureList";

const SearchAll = () => {
  const searchAllArr = [
    {
      searchLink: "/web/page/doctorsearch",
      header: "רופאים",
      btnText: 'קביעת פגישה',
      section: [
        {
          img: "/images/web/searchAll/search1.jpg",
          text: 'ד"ר חנה לוי - גינקלוגית',
          itemLink: "/doctor",
        },
        {
          img: "/images/web/searchAll/search2.jpg",
          text: 'ד"ר  הילה כהן - גינקלוגית',
          itemLink: "/doctor",
        },
      ],
    },
    {
      searchLink: "/web/page/departmentsearch",
      header: "מחלקות",
      btnText: 'לדף המחלקה',
      section: [
        {
          img: "/images/web/searchAll/search3.jpg",
          text: "גינקלוגיה",
          itemLink: "/web/page/aboutdepartment",
        },
      ],
    },
    {
      searchLink: "/procedures",
      header: "פרוצדורות",
      btnText: 'לדף הפרוצדורה',
      section: [
        {
          img: "/images/web/searchAll/search4.jpg",
          text: "מתן ויטמנים",
          itemLink: "#",
        },
        {
          img: "/images/web/searchAll/search5.jpg",
          text: "עיסוי התאוששות לאחר פריקת מפרק",
          itemLink: "#",
        },
      ],
    },
    {
      searchLink: "#",
      header: "שירותים",
      btnText: 'לדף השירות',
      section: [
        {
          img: "/images/web/searchAll/search6.jpg",
          text: "אולטרסאונד של איברי החלל התוך-בטני",
          itemLink: "#",
        },
        {
          img: "/images/web/searchAll/search7.jpg",
          text: "ייעוץ אונליין לבחירת התכנית המתאימה לך",
          itemLink: "#",
        },
      ],
    },
    {
      searchLink: "/blog",
      header: "בלוגים",
      btnText: 'לקריאה',
      section: [
        {
          img: "/images/web/searchAll/search8.jpg",
          text: "החיוך המודע הראשון של התינוק",
          itemLink: "/postPage2",
        },
        {
          img: "/images/web/searchAll/search9.jpg",
          text: 'תרופה נגד צרבת "סמטקה"',
          itemLink: "/postPage1",
        },
      ],
    },
    {
      searchLink: "/products",
      header: "מוצרים",
      btnText: 'לדף המוצר',
      section: [
        {
          img: "/images/web/searchAll/search10.jpg",
          text: "צעצועים לפעוטות - $25",
          itemLink: "/web/page/productdetails",
        },
      ],
    },
  ];

  return (
    <>
      <div className="searchAllWebPages">
        <Banner />

        <Container className="mt-5">
          {searchAllArr.map((el, i) => {
            return (
              <SearchSqaureList
                searchLink={el.searchLink}
                header={el.header}
                btnText={el.btnText}
                section={el.section}
                key={i}
              />
            );
          })}
        </Container>
      </div>
    </>
  );
};

export default SearchAll;
