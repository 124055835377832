const initState = {
  userSectionsArr: [],
  addDate: "",
  notificationsArr: [
    {
      img: "/images/notifications/babyPaint.jpg",
      massageBeforeBold: "מזל טוב! העובר שלך בן ",
      bold: "6 שבועות",
      massageAfterBold: " היום",
      date: "11:00 ,היום",
      formatDate: "28 Feb 2022 11:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/notifications/dr.jpg",
      massageBeforeBold: "קבעת פגישת ייעוץ עם הרופא שלך ",
      bold: "חנה לוי",
      massageAfterBold: "",
      date: "12:30 ,15 מרץ 2022",
      formatDate: "15 Mar 2022 11:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/notifications/vitamin.jpg",
      massageBeforeBold: "!זה הזמן לקחת את התרופה שלך ",
      bold: " B12 ויטמין ",
      massageAfterBold: "",
      date: "08:10 ,14 מרץ 2022",
      formatDate: "14 Mar 2022 08:10:00 GMT",
      btn: "/images/notifications/doneBtn.png",
    },
    {
      img: "/images/myBaby/start.jpg",
      massageBeforeBold:
        "בשעה טובה ומוצלחת, אנו מאחלים לך הריון תקין ולידה קלה",
      bold: "",
      massageAfterBold: "",
      date: "09:40, 31 ינואר 2022",
      formatDate: "31 Jan 2022 09:40:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 ,היום",
      formatDate: "28 Feb 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 28 מרץ  2022",
      formatDate: "28 Mar 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 25 אפריל  2022",
      formatDate: "25 Apr 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 23 מאי  2022",
      formatDate: "23 May 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 20 יוני  2022",
      formatDate: "20 Jun 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 18 יולי  2022",
      formatDate: "18 Jul 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 15 אוגוסט  2022",
      formatDate: "15 Aug 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 12 ספטמבר  2022",
      formatDate: "12 Sep 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 10 אוקטובר  2022",
      formatDate: "10 Oct 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 24 אוקטובר  2022",
      formatDate: "24 Oct 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות:",
      massageAfterBold:
        "עלייך לגשת לקופה שבה את נמצאת או לטיפת חלב על מנת לבדוק  לחץ דם, משקל, חלבון בשתן ובצקות.",
      date: "09:00 , 07 נובמבר  2022",
      formatDate: "07 Nov 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/nurse.jpg",
      massageBeforeBold: "",
      bold: "בדיקת NIPT:",
      massageAfterBold: " מומלץ לבצע בדיקה זו באופן פרטני",
      date: "09:00 , 04 אפריל  2022",
      formatDate: "04 Apr 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/ultra1.jpg",
      massageBeforeBold: "",
      bold: 'בדיקת אולטרסאונד שליש ראשון - "דופק":',
      massageAfterBold: "עלייך לגשת למרפאה או למרכז בריאות האישה",
      date: "09:00 , 07 מרץ  2022",
      formatDate: "07 Mar 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/blood.jpg",
      massageBeforeBold: "",
      bold: "בדיקת דם ושתן:",
      massageAfterBold:
        "עלייך לגשת למרפאה או  לחילופין למרכז בריאות האישה על מנת לבדוק דם ושתן",
      date: "09:00 , 07 מרץ  2022",
      formatDate: "07 Mar 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/cvs.jpg",
      massageBeforeBold: "",
      bold: "בדיקת סיסי שיליה CVS:",
      massageAfterBold:
        "עלייך לגשת למכון פרטי או לבית חולים על מנת לבצע את הבדיקה.",
      date: "09:00 , 04 אפריל  2022",
      formatDate: "04 Apr 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/orpit.jpg",
      massageBeforeBold: "",
      bold: "בדיקת שקיפות עורפית:",
      massageAfterBold:
        "עלייך לגשת למרפאה בה את מטופלת או למרכזי בריאות האישה על מנת לבצע את הבדיקה",
      date: "09:00 , 11 אפריל  2022",
      formatDate: "11 Apr 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/orpit.jpg",
      massageBeforeBold: "",
      bold: "בדיקת שקיפות עורפית:",
      massageAfterBold:
        "עלייך לגשת למרפאה בה את מטופלת או למרכזי בריאות האישה על מנת לבצע את הבדיקה",
      date: "09:00 , 05 פברואר  2022",
      formatDate: "05 Feb 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/skira.jpg",
      massageBeforeBold: "",
      bold: "בדיקת סקירת מערכות מוקדמות:",
      massageAfterBold:
        "עלייך לבצע בבית חולים באופן פרטני בדיקה זו על מנת לבדוק את שלמות מערכת הגוף  ותפקודו של העובר.",
      date: "09:00 , 02 מאי  2022",
      formatDate: "02 May 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/helbon.jpg",
      massageBeforeBold: "",
      bold: "בדיקת חלבון עוברי:",
      massageAfterBold:
        "עלייך לגשת למרפאה בה את מטופלת או למרכז בריאות האישה על מנת לבצע בדיקה זו.",
      date: "09:00 , 05 פברואר  2022",
      formatDate: "05 Feb 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/helbon.jpg",
      massageBeforeBold: "",
      bold: "בדיקת חלבון עוברי:",
      massageAfterBold:
        "עלייך לגשת למרפאה בה את מטופלת או למרכז בריאות האישה על מנת לבצע בדיקה זו.",
      date: "09:00 , 16 מאי  2022",
      formatDate: "16 May 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/shafir.jpg",
      massageBeforeBold: "",
      bold: "בדיקת מי שפיר:",
      massageAfterBold: "עלייך לגשת לבתי החולים שבהסדר על מנת לבצע בדיקה זו.",
      date: "09:00 , 16 מאי  2022",
      formatDate: "16 May 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/skira2.jpg",
      massageBeforeBold: "",
      bold: "בדיקת סקירת מערכות מאוחרות:",
      massageAfterBold:
        "עלייך לגשת למרכזי בריאות האישה או למרפאות על מנת לבצע בדיקה זו.",
      date: "09:00 , 06 יוני  2022",
      formatDate: "06 Jun 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/ultra2.jpg",
      massageBeforeBold: "",
      bold: "אולטרסאונד אקו לב עובר:",
      massageAfterBold:
        "עלייך לגשת לקרדיולוג ילדים מומחה בבית חולים או למכון פרטי על מנת לבצע בדיקה זו.",
      date: "09:00 , 06 יוני  2022",
      formatDate: "06 Jun 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/sucar.jpg",
      massageBeforeBold: "",
      bold: "העמסת סוכר, בדיקות דם ושתן:",
      massageAfterBold:
        " עלייך לגשת למרכזי בריאות האישה או למרפאות על מנת לבצע בדיקה זו.",
      date: "09:00 , 11 יולי  2022",
      formatDate: "11 Jul 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/weight.jpg",
      massageBeforeBold: "",
      bold: "הערכת משקל:",
      massageAfterBold:
        "עלייך לגשת למרפאה, מרכז בריאות האישה, מכון אולטרסאונד על מנת לבצע בדיקה זו.",
      date: "09:00 , 22 אוגוסט  2022",
      formatDate: "22 Aug 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/gbs.jpg",
      massageBeforeBold: "",
      bold: "בדיקת תרבית ל-GBS:",
      massageAfterBold:
        "עלייך לגשת לרופא המטפל או לבצע בדיקה זו באופן עצמאי והלביא למרפאה שבה את מטופלת.",
      date: "09:00 , 26 ספטמבר  2022",
      formatDate: "26 Sep 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/trimester.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות טרימסטר שלישי:",
      massageAfterBold: "נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב.",
      date: "09:00 , 03 אוקטובר 2022",
      formatDate: "03 Oct 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/trimester.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות טרימסטר שלישי:",
      massageAfterBold: "נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב.",
      date: "09:00 , 17 אוקטובר 2022",
      formatDate: "17 Oct 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/trimester.jpg",
      massageBeforeBold: "",
      bold: "מעקב אחות טרימסטר שלישי:",
      massageAfterBold: "נא גשי לאחות במרכזי בריאות האישה על מנת לבצע מעקב.",
      date: "09:00 , 31 אוקטובר 2022",
      formatDate: "31 Oct 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
    {
      img: "/images/myBaby/tomuch.jpg",
      massageBeforeBold: "",
      bold: "מעקב הריון עודף:",
      massageAfterBold: "עלייך לגשת לקופת החולים או לבית החולים בו את מטופלת.",
      date: "09:00 , 08 נובמבר 2022",
      formatDate: "08 Nov 2022 09:00:00 GMT",
      btn: "/images/notifications/okBtn.png",
    },
  ],
};

export default function reducer(state = initState, action = {}) {
  const addContent = document.querySelector(".addContent");
  switch (action.type) {
    case "CHANGEDATE":
      return { ...state, addDate: action.payload };
    case "ADD":
      const addHeader = document.querySelector(".addHeader").value;
      const addDescription = document.querySelector(".addDescription").value;
      const filesArr = document.querySelector(".galleryInput").files;
      let addImg = "";
      if (filesArr.length > 0) {
        const file = filesArr[0];
        const imageUrl = URL.createObjectURL(file);
        addImg = imageUrl;
      }
      let newUserSectionsContentArr = [...state.userSectionsArr];
      // const date = document.querySelectorAll('.addDateInput').value
      const addDay = state.addDate[8] + state.addDate[9];
      let addMonth = state.addDate[5] + state.addDate[6];
      const addYear =
        state.addDate[0] +
        state.addDate[1] +
        state.addDate[2] +
        state.addDate[3];
      // let addDay = pickDateArr[0].textContent
      // if (addDay[0] === 'T') {
      // 	addDay = addDay.substring(5)
      // }
      // let addMonth = pickDateArr[1].textContent
      // if (addMonth[0] === 'T') {
      // 	addMonth = addMonth.substring(5)
      // }
      switch (addMonth) {
        case "01":
          addMonth = "ינו";
          break;
        case "02":
          addMonth = "פבר";
          break;
        case "03":
          addMonth = "מרץ";
          break;
        case "04":
          addMonth = "אפר";
          break;
        case "05":
          addMonth = "מאי";
          break;
        case "06":
          addMonth = "יוני";
          break;
        case "07":
          addMonth = "יולי";
          break;
        case "08":
          addMonth = "אוג";
          break;
        case "09":
          addMonth = "ספט";
          break;
        case "10":
          addMonth = "אוק";
          break;
        case "11":
          addMonth = "נוב";
          break;
        case "12":
          addMonth = "דצמ";
          break;
      }
      // const addYear = pickDateArr[2].textContent
      newUserSectionsContentArr.push({
        header: addHeader,
        description: addDescription,
        img: addImg,
        day: addDay,
        month: addMonth,
        year: addYear,
      });
      return { ...state, userSectionsArr: newUserSectionsContentArr };

    default:
      return { ...state };
  }
}
