import { Link } from "react-router-dom";

import Helper from "../../../../helpers/api";

const DepartmentSquare = (props) => {
  return (
    <div className="departmentSquareDiv">
      <Link to={"/departments/gynecology"} key={props.index}>
        <div className="departmentSquare">
          <img 
            className="departmentSquareImg" 
            src={props.img}
            // src={props.img ? Helper.apiBaseUrl + "uploads" + props.img : "/images/hospital/obstetrics.jpg"} 
            alt="aa" 
          />
          <div id="departmentSquareH">{props.header}</div>
        </div>
      </Link>
    </div>
  );
};

export default DepartmentSquare;
