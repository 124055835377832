import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default class Toast {

  static position(name) {
    return toast.POSITION[name]
  }

  static info(message) {
    toast.info(message, {
      autoClose: 5000
    })
  }

  static success(message) {
    toast.success(message, {
      autoClose: 5000
    });
  }

  static warning(message) {
    toast.warn(message, {
      autoClose: 5000
    })
  }

  static error(message) {
    toast.error(message, {
      autoClose: 5000
    })
  }
}
