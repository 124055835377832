import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Toast from "../../../../helpers/toast";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { ServiceAction } from "../../../../actions/service";

const PopularServicesSquareList = () => {
  const dispatch = useDispatch();

  const serviceAction = ServiceAction();

  const [service, setService] = useState([]);

  useEffect(() => {
    (async () => {
      //This is to get service list
      await serviceAction
        .getServiceList()
        .then((response) => {
          dispatch({ type: "UNSET_LOADER" });

          if (response) {
            if (response.success == true) {
              dispatch({ type: "SERVICE_LIST", payload: response.services });
              setService(response.services);
            } else {
              Toast.error(response.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    })();
  }, []);

  const PopularServicesListArr = [
    {
      image: "/images/marketPlace/market_place2.jpg",
      title: "לידה",
      link: "/service/1",
    },
    {
      image: "/images/marketPlace/market_place1.jpg",
      title: "תזונה נכונה",
      link: "/service/2",
    },
    {
      image: "/images/marketPlace/pregnant.jpeg",
      title: "הריון",
      link: "/service/3",
    },
    {
      image: "/images/fitness/stretching.jpg",
      title: "אורח חיים בריא",
      link: "/service/4",
    },
    {
      image: "/images/marketPlace/breath.jpg",
      title: "לנשום נכון",
      link: "/service/5",
    },
  ];

  // const slides = service.map((el, index) => (
    const slides = PopularServicesListArr.map((el, index) => (
    <Link 
      // to={"/service/" + el.id + ""} 
      to={el.link} 
      key={index}
    >
      <div className="slide_box">
        <div className="box_img">
          <img 
            src={el.image} 
            alt={el.title} 
            // alt={el.name} 
          />
        </div>
        <h6>
          {el.title}
          {/* {el.name} */}
        </h6>
      </div>
    </Link>
  ));

  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={2.5}
        className="service_slider"
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PopularServicesSquareList;
