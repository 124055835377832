import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import leftArrow from "../../../assets/images/icons/leftArrow.png";

const PregnantSquareList = () => {
  const PregnantListArr = [
    {
      image: "/images/club/club_image2.png",
      title: "קורס הכנה ללידה",
      description: "עוד מידע על תזונה לנשים בהריון",
      arrow_img: { leftArrow },
      link: "#",
    },
    {
      image: "/images/club/club_image3.jpg",
      title: "קורס  שינה נכונה",
      description: "עוד מידע על קורס שינה נכונה",
      arrow_img: { leftArrow },
      link: "#",
    },
    {
      image: "/images/club/club_image2.png",
      title: "קורס הכנה ללידה",
      description: "עוד מידע על תזונה לנשים בהריון",
      arrow_img: { leftArrow },
      link: "#",
    },
  ];

  const slides = PregnantListArr.map((el, index) => (
    <Link to={"" + el.link + ""} key={index}>
      <div className="my_club_div">
        <div className="my_club_div_image_div">
          <img src={el.image} className="my_club_div_img" alt="aa" />
        </div>
        <div className="my_club_div_details">
          <h3>{el.title}</h3>
          <p>{el.description}</p>
        </div>
        <img
          className="right_arrow_img"
          src={el.arrow_img.leftArrow}
          alt="right-arrow"
        />
      </div>
    </Link>
  ));

  return (
    <>
      <Swiper spaceBetween={30}>
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PregnantSquareList;
