import Search from "../../regularPages/repeatsComponents/search/Search";
import React from "react";
import Head from "../../repeats/head/Head";
import "./Review.css";

import img from "../../../assets/images/team1.png";
import DoctorReview from "./DoctorReview";
import Footer from "../../repeats/footer/Footer";

import StarRoundIcon from '@mui/icons-material/Star';

const Review = () => {
  const data = {
    image: img,
    header: `ד"ר חנה לוי`,
    text: "גינקולוגית",
    r_text: "דעתך עוזרת לנו להשתפר",
    r_header: "מה דעתך על הייעוץ?",
  };

  return (
    <div className="PageDad">
      <div className="page rtl">
        <Head text="ביקורת לאחר ייעוץ" />
        <div className="review_pages rtl">
          <Search icon={<StarRoundIcon className="searchStar" />} />

          <div className="main_div">
            <p>
              הרופאה שלך – <span className="doctor_name">חנה לוי</span>
            </p>
            <div className="review_date">
              <div className="reviewSquare">
                <div className="reviewSquareDateAndText">
                  <div className="reviewSquareDate">
                    <div className="reviewSquareDateDay">שישי</div>
                    <div className="reviewSquareDateDate">11.03</div>
                  </div>
                  <div className="reviewSquareTextDiv">
                    <h1 className="reviewSquareTextDivH">תאריך הייעוץ</h1>
                    <div className="reviewSquareTextDivP">אונליין</div>
                  </div>
                </div>
                <div className="reviewSquareTextcheck">
                  <input type={"checkbox"} className="reviewSquareTextCheckbox" />
                </div>
              </div>
            </div>
            <DoctorReview data={data} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Review;
