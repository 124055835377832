import React, { Component } from "react";
import ServiceListHeader from '../../regularPages/serviceList/ServiceListHeader'
import MyHealthSquare from './MyHealthSquare'

import mybody from '../../../assets/images/web/home/mybody.jpg'
import fitness from '../../../assets/images/web/home/fitness.jpg'
import procedures from '../../../assets/images/web/home/procedure.jpg'

const MyHealth = () => {

	const MyHealthListArr = [
		{
			img: mybody,
			h6_text: "הגוף שלי",
			link: "/mybody",
		},
		{
			img: fitness,
			h6_text: "אורח חיים בריא",
			link: "/fitness",
		},
		{
			img: procedures,
			h6_text: "פרוצדורות",
			link: "/procedures",
		},
	];

	return (
		<div className='myHealth'>
			<ServiceListHeader text='הבריאות שלי' link='/pregnancy' text2='לתפריט המלא' />
			<div className='myHealthArr'>
				{
					MyHealthListArr.map((el, i) => {
						return (
							<MyHealthSquare
								img={el.img}
								link={el.link}
								h6_text={el.h6_text}
								key={i}
							/>
						)
					})
				}
			</div>
		</div>
	)
}

export default MyHealth