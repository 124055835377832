import React from "react";
import Head from "../../repeats/head/Head";
import DepartmentBanner from "./DepartmentBanner";
import DoctorList from "../../regularPages/doctorList/DoctorList";
import ServiceList from "../../regularPages/serviceList/ServiceList";

import "./DepartmentDetails.css";
import { Link } from "react-router-dom";

const DepartmentDetails = () => {

  return (
    <>
      <div className="PageDad">
        <div className="department_details_pages rtl">
          <Head text="בתי חולים" />
          <DepartmentBanner />
          <DoctorList />
          <ServiceList />
          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link to={"/hospitalstay"} className="go_btn">
                  לאשפוז
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentDetails;
