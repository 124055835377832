import React, { useEffect } from "react";
import InfoSquare from "./InfoSquare";

const InfoList = () => {
  const containerArr = [
    {
      img: "/images/contact/phone.png",
      header: "פרטים אישיים",
      paragraph: "שם, טלפון, אימייל",
      link: "#",
    },
    {
      img: "/images/account/cardiogram.png",
      header: "מידע בריאותי",
      paragraph: "היסטוריה  רפואית והריון",
      link: "#",
    },
    {
      img: "/images/account/hospital.png",
      header: "בתי חולים",
      paragraph: "המידע  שלך ושל בית החולים",
      link: "#",
    },
    {
      img: "/images/account/calendar.png",
      header: "הפגישות שלי",
      paragraph: "אולטראסאונד ,בדיקות וכדומה",
      link: "#",
    },
    {
      img: "/images/account/emergency.png",
      header: "מספרי חירום",
      paragraph: "אנשי החירום שלך שיקבלו התראה",
      link: "#",
    },
  ];

  return (
    <main className="infoList">
      {containerArr.map((el, i) => {
        return (
          <InfoSquare
            img={el.img}
            header={el.header}
            paragraph={el.paragraph}
            link={el.link}
            key={i}
          />
        );
      })}
    </main>
  );
};

export default InfoList;
