import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Banner from "./banner/Banner";
import DoctorListSqaure from "../home/DoctorListSquare/DoctorListSqaure";
import ServicesSqaureList from "./servicesSqaure/ServicesSqaureList";
import BlogSquareList from "../home/BlogSquare/BlogSquareList";
import SqaureList from "./sqaureList/SqaureList";

import "./Department.css";

const Department = () => {
  return (
    <>
      <div className="departmentWebPages rtl">
        {/* <Container> */}
          <Banner />
          <DoctorListSqaure />
          <SqaureList />
          <ServicesSqaureList />
          <BlogSquareList />
        {/* </Container> */}
      </div>
    </>
  );
};

export default Department;
