import "./Account.css";
import React, { useEffect } from "react";
import AccountMain from "./AccountMain";
import InfoList from "./InfoList";
import { UserAction } from "../../../actions/user";
import { useSelector } from "react-redux";

const Account = () => {
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const userAction = UserAction();

  useEffect(() => {
    (async () => {})();

    return function () {};
  }, []);

  return (
    <div className="container accountPages">
      <div className="webAccountPage">
        <AccountMain />
        <InfoList />
      </div>
    </div>
  );
};

export default Account;
