import React from 'react'
import TabBtn from "../../../regularPages/repeatsComponents/tabsMenu/TabBtn";
import LikeSquare from "./LikeSquare.js"

const LikeList = () => {
	const LikeListArr = [
    {
        img: "/images/web/productList/preparation.jpg",
        off: '45%',
        header: "קורס הכנה ללידה",
        price: '115$',
        link: "#",
    },
    {
        img: "/images/web/productList/yoga85.jpg",
        off: '',
        header: "קורס יוגה לנשים בהריון",
        price: '85$',
        link: "#",
    },
    {
        img: "/images/web/productList/consultation.jpg",
        off: '',
        header: "יעוץ לנשים בהריון",
        price: '40$',
        link: "#",
    },
    {
        img: "/images/web/productList/nutrition.jpg",
        off: '',
        header: "תכנית תזונה לנשים בהריון",
        price: '145$',
        link: "#",
    },
    {
        img: "/images/web/productList/carriage.jpg",
        off: '15%',
        header: "עגלת תינוק",
        price: '315$',
        link: "#",
    },
    {
        img: "/images/web/productList/game.jpg",
        off: '10%',
        header: "משחקים לפעוט",
        price: '15$',
        link: "#",
    },
    {
        img: "/images/web/productList/yoga90.jpg",
        off: '',
        header: "קורס יוגה לנשים בהריון",
        price: '90$',
        link: "#",
    },
    {
        img: "/images/web/productList/management.jpg",
        off: '',
        header: "ניהול הריון",
        price: '600$',
        link: "#",
    },
    {
        img: "/images/web/productList/pierre.jpg",
        off: '',
        header: "פייר קארדן",
        price: '600$',
        link: "#",
    },
    {
        img: "/images/web/productList/fox.jpg",
        off: '',
        header: "מוצרים לבית",
        price: '15$',
        link: "#",
    },
    {
        img: "/images/web/productList/suit.jpg",
        off: '',
        header: "חליפות",
        price: '600$',
        link: "#",
    },
    {
        img: "/images/web/productList/ring.jpg",
        off: '',
        header: "גלגל ים",
        price: '15$',
        link: "#",
    },
];

const tabsMenu = [
  {
    text: "הכל",
  },
  {
    text: "לידה",
  },
  {
    text: "ייעוץ",
  },
  {
    text: "בתי חולים",
  },
  {
    text: "קורסים",
  },
  {
    text: "מוצרים",
  },
  {
    text: "אבחונים",
  },
];

const active = (e) => {
const choosen = e.target.parentElement;
const options = document.querySelectorAll(".tabsMenuLi");
for (let i = 0; i < options.length; i++) {
  options[i].classList.remove("active");
}
choosen.classList.add("active");
};

return (
<div className='likeList'>
  <ul
    id="tabs-nav"
    className="tabsMenu list-unstyled d-flex"
  >
    {tabsMenu.map((el, i) => {
            return (
              <TabBtn active={active} text={el.text} key={i} i={i} />
            );
          })}
  </ul>
  <div className='linkSquareContainer'>
    {
      LikeListArr.map((el, i) => {
        return (
          <LikeSquare
          img={el.img}
          off={el.off}
          header={el.header}
          price={el.price}
          link={el.link}
          i={i}
          key={i}
          />
        )
      })
    }
  </div>
</div>
)
}

export default LikeList