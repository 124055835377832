import './Procedures.css';
import ProceduresSquare from './ProceduresSquare'
import Search from '../../regularPages/repeatsComponents/search/Search'
import ListHeader from '../../regularPages/repeatsComponents/listheader/ListHeader'
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';

import StarRoundIcon from '@mui/icons-material/Star';

const Procedures = () => {

	const ProceduresSquareArr = [
		{
			header: 'אולטרסאונד ראשון',
			text: 'התיעצות, שלב ראשון',
			day: 'שני',
			date: '28.02',
		},
		{
			header: 'אולטרסאונד שני',
			text: 'שיעור עם מדריך',
			day: 'שישי',
			date: '11.04',
		},
		{
			header: 'אולטרסאונד שלישי',
			text: 'שיעור עם מדריך',
			day: 'חמישי',
			date: '25.08',
		},
		{
			header: 'לידה' ,
			text: 'שיעור עם מדריך',
			day: 'חמישי',
			date: '10.11',
		},
		{
			header: 'בדיקה לאחר לידה',
			text: 'בקרת קליטה',
			day: 'שישי',
			date: '11.11',
		},
	]

	const ListHeaderObj = {
		header: 'הגניקולוגית שלך: - ',
		headerSpan: 'חנה לוי',
		par: 'המילדת - ',
		parSpan: 'לאה מזרחי',
	}

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='הפרוצדורות שלי' />
				<div className='procedursPage'>
					<Search icon={<StarRoundIcon className="searchStar" />} />
					<ListHeader
						header={ListHeaderObj.header}
						headerSpan={ListHeaderObj.headerSpan}
						par={ListHeaderObj.par}
						parSpan={ListHeaderObj.parSpan}
					/>
					<div className='procedursSquaresDiv'>
						{
							ProceduresSquareArr.map((el, i) => {
								return (
									<ProceduresSquare
										header={el.header}
										text={el.text}
										day={el.day}
										date={el.date}
										key={i} 
									/> 
								)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>

	)
}

export default Procedures