import CheckList from "./CheckList";

const BirthPlanSquare = ({ header, text, content, i }) => {
  const openContent = (e) => {
    let content;
    if (i > 1) {
      content = e.target.parentElement.nextSibling;
    } else if (i > 0) {
      content = e.target.parentElement.nextSibling.nextSibling;
    } else {
      content = e.target.parentElement.nextSibling.nextSibling.nextSibling;
    }
    const square = e.target.parentElement.parentElement;
    if (content.style.display === "block") {
      content.style.display = "none";
      square.style.height = "93px";
      e.target.src = "/images/birthplan/arrow_down.png";
    } else {
      content.style.display = "block";
      square.style.height = "unset";
      e.target.src = "/images/birthplan/arrow_up.png";
    }
  };

  return (
    <div className="birthPlanSquare">
      <div className="birthPlanSquareTop">
        <div className="birthPlanSquareTextDiv">
          <h1 className="birthPlanSquareTextDivH">{header}</h1>
          <div className="birthPlanSquareTextDivP">{text}</div>
        </div>
        <img
          onClick={openContent}
          className="birthPlanSquareBtn"
          src="/images/birthplan/arrow_down.png"
          alt="aa"
        />
      </div>
      <CheckList />
      <div className="staffContent">
        רופאה: חנה לוי
        <br />
        אחות: לאה מזרחי
        <br />
        אשת תמיכה: חווה פרישברג
        <br />
        דולה: דנה בלום
      </div>
      <div className="dateContent">בלה בלה בלה בלה בלה בלה בלה</div>
    </div>
  );
};

export default BirthPlanSquare;
