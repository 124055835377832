import "./Analyzes.css";
import AnalyzesSquare from "./AnalyzesSquare";
import Search from "../../regularPages/repeatsComponents/search/Search";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import StarRoundIcon from '@mui/icons-material/Star';

const Analyzes = () => {
  const analyzesSquareArr = [
    {
      img: "/images/analyzes/blood.jpg",
      textImg: "/images/analyzes/ready.png",
      header: "ספירת דם מלאה",
      text: "התקבלה  - 10.03.2022",
      btn: "/images/analyzes/redView.png",
      link: "",
    },
    {
      img: "/images/analyzes/vitamin.jpg",
      textImg: "/images/analyzes/process.png",
      header: "D-אבחון ויטמין",
      text: "יהיה מוכן - 15.03.2022",
      btn: "/images/analyzes/grayView.png",
      link: "",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="האבחונים שלי" />
        <div className="birthPlanPage">
          <Search icon={<StarRoundIcon className="searchStar" />} />
          <div className="birthPlanSquaresDiv">
            {analyzesSquareArr.map((el, i) => {
              return (
                <AnalyzesSquare
                  img={el.img}
                  textImg={el.textImg}
                  header={el.header}
                  text={el.text}
                  btn={el.btn}
                  link={el.link}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Analyzes;
