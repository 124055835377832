import React, { useEffect, useState } from "react";
import ServiceItem from "./ServiceItem";
import ServiceListHeader from "./ServiceListHeader";
import Service1 from "../../../assets/images/service/consultation.jpg";
import "./ServiceList.css";
import { ServiceAction } from "../../../actions/service";
import { useDispatch } from "react-redux";
import Toast from "../../../helpers/toast";

const ServiceList = () => {
  const dispatch = useDispatch();

  const serviceAction = ServiceAction();

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    (async () => {
      //This is to get service list
      await serviceAction
        .getServiceList()
        .then((response) => {
          dispatch({ type: "UNSET_LOADER" });

          if (response) {
            if (response.success == true) {
              dispatch({ type: "SERVICE_LIST", payload: response.services });
              setServiceList(response.services);
            } else {
              Toast.error(response.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    })();
  }, []);

  const serviceListArr = [
    {
      header: "יעוץ אונליין",
      price: "50$",
      par: "התקשרי לרופאה שלך בכל זמן",
      img: Service1,
    },
    {
      header: "ניהול הריון",
      price: "100$",
      par: `פגישות רופא, בדיקות, אולטרסאונד וכדו'`,
      img: Service1,
    },
  ];

  return (
    <div className="service_list_sec">
      <div className="container">
        <div className="doctors_area">
          <ServiceListHeader text="השירותים שלנו:" link="#" text2='לכל השירותים' />
          <div className="service_box_area">
            {/* {serviceList.map((el, i) => { */}
            {serviceListArr.map((el, i) => {
              return (
                <ServiceItem
                  header={el.header}
                  price={el.price}
                  par={el.par}
                  img={Service1}
                  // header={el.name}
                  // price={el.price + "$"}
                  // par={el.description}
                  // img={Service1}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
