import "./HospitalList.css";
import React from "react";
import ServiceListHeader from "../../../regularPages/serviceList/ServiceListHeader";
import HospitalSqaure from "./HospitalSqaure";

import star from "../../../../assets/images/star.svg";

const HospitalList = () => {
  const HospitalListArr = [
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "המרכז לטיפול בהריון",
      p_text: "חולון, הלוחמים 62",
      span_text: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "בית חולים וולפסון",
      p_text: "חולון הלוחמים 62",
      span_text: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "בית חולים לנשים",
      p_text: "באר שבע, שדרות רגר 40",
      span_text: "4.7 (1075 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "המרכז לטיפול בהריון",
      p_text: "חולון, הלוחמים 62",
      span_text: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "בית חולים וולפסון",
      p_text: "חולון הלוחמים 62",
      span_text: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "בית חולים לנשים",
      p_text: "באר שבע, שדרות רגר 40",
      span_text: "4.7 (1075 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "המרכז לטיפול בהריון",
      p_text: "חולון, הלוחמים 62",
      span_text: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
    {
      image: "/images/web/hospital/hospital1.jpg",
      h4_text: "בית חולים וולפסון",
      p_text: "חולון הלוחמים 62",
      span_text: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "/web/page/hospital",
    },
  ];

  return (
    <>
      <ServiceListHeader text="בתי חולים" link="/web/page/hospitals" text2='לכל בתי החולים' />
      <div className="webHospitalList">
        <div className="hospital_list_box_area">
          {HospitalListArr.map((el, i) => {
            return (
              <HospitalSqaure
                image={el.image}
                h4_text={el.h4_text}
                p_text={el.p_text}
                span_text={el.span_text}
                star_image={el.star_image}
                link={el.link}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HospitalList;
