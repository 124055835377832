import BlogItem from "../postList/blogItem/BlogItem";

const PostList = () => {
  const postListArr = [
    {
      link: "/PostPage1",
      img: "/images/blogPostList/blog1.jpg",
      header1: "ביקור ראשון אצל הגינקולוג:",
      header2: "מידע חשוב",
      text: "6 דברים שצריך לדעת לפני ביקור אצל גינקולוג",
      blogClass: "blog row-rev",
      blogContentClass: "blogContent pad",
    },
    {
      link: "/PostPage2",
      img: "/images/blogPostList/blog2.jpg",
      header1: "דכאון במהלך",
      header2: "ההריון",
      text: "בהריון ובשבועות הראשונים שלאחר הלידה, אמא חדשה עוברת רכבת הרים של רגשות.",
      blogClass: "blog",
      blogContentClass: "blogContent",
    },
    {
      link: "/PostPage1",
      img: "/images/blogPostList/blog3.jpg",
      header1: "ביקור ראשון אצל הגינקולוג:",
      header2: "מידע חשוב",
      text: "6 דברים שצריך לדעת לפני ביקור אצל גינקולוג",
      blogClass: "blog row-rev",
      blogContentClass: "blogContent pad",
    },
    {
      link: "/PostPage2",
      img: "/images/blogPostList/blog4.jpg",
      header1: "דכאון במהלך",
      header2: "ההריון",
      text: "בהריון ובשבועות הראשונים שלאחר הלידה, אמא חדשה עוברת רכבת הרים של רגשות.",
      blogClass: "blog",
      blogContentClass: "blogContent",
    },
  ];

  return (
    <div className="postList">
      {postListArr.map((el, i) => {
        return (
          <BlogItem
            link={el.link}
            img={el.img}
            header1={el.header1}
            header2={el.header2}
            text={el.text}
            blogClass={el.blogClass}
            blogContentClass={el.blogContentClass}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default PostList;
