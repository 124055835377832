import StarRoundIcon from '@mui/icons-material/Star';

const Star = ({checked, privetClassName, rating}) => {
	const className = `${privetClassName} fa fa-star review_star ${checked}`
	return (
        // <span onClick={rating} className={className} ></span>
		<StarRoundIcon onClick={rating} className={className} />
	)
}

export default Star