import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import star from "../../../../assets/images/star.svg";

import { useSelector } from "react-redux";

import { UserAction } from "../../../../actions/user";

import Helper from "../../../../helpers/api";

const DoctorListSqaure = () => {

  const doctor = useSelector((state) => state.doctor);

  const userAction = UserAction();

  useEffect(() => {
    userAction.getDoctorsList();
  }, []);

  const DoctorListArr = [
    {
      image: "/images/doctor/doctor1.jpg",
      title: "חנה לוי",
      sub_title: "גניקולוגית",
      rating: "4.9",
      star_image: { star },
      link: "/doctor",
    },
    {
      image: "/images/doctor/doctor2.jpg",
      title: "חווה פרישברג",
      sub_title: "מילדת",
      rating: "4.8",
      star_image: { star },
      link: "/doctor",
    },
    {
      image: "/images/doctor/doctor3.jpg",
      title: "דנה בלום",
      sub_title: "דולה",
      rating: "4.7",
      star_image: { star },
      link: "/doctor",
    },
    {
      image: "/images/doctor/doctor4.jpg",
      title: "לאה מזרחי",
      sub_title: "אחות",
      rating: "4.6",
      star_image: { star },
      link: "/doctor",
    },
    {
      image: "/images/doctor/doctor5.png",
      title: "לאה מזרחי",
      sub_title: "אחות",
      rating: "4.6",
      star_image: { star },
      link: "/doctor",
    },
  ];

  // const slides = doctor.data.length > 0 ? doctor.data.map((el, i) => (
    const slides = DoctorListArr.length > 0 ? DoctorListArr.map((el, i) => (
    <div className="" key={i}>
      <Link to={"/doctor"}>
        <div className="doctor_box">
          <div className="box_img">
            <img 
              // src={el.profileImage ? Helper.apiBaseUrl + "uploads" + el.profileImage : "/images/doctor/doctor1.jpg"} 
              src={el.image}
              alt="doctor" 
              className="doctor_box_img" 
            />
          </div>
          <h6>
            {/* {el.firstName ? el.firstName : "חנה לוי"} */}
            {el.title ? el.title : "חנה לוי"}
          </h6>
          <p>
            {/* {el.position ? el.position : "גינקולוג"} */}
            {el.sub_title ? el.sub_title : "גינקולוג"}
          </p>
          <div className="start_con d-flex align-center justify-center">
            <span>{el.rating ? el.rating : "4.9"}</span>
            <img src={star} alt="star" />
          </div>
        </div>
      </Link>
    </div>
  )) : "";

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={4}
      >
        { slides.length > 0 ?
        slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        )) : ""
        }
      </Swiper>
    </>
  );
};

export default DoctorListSqaure;
