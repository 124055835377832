import Btn from './Btn'

import Off from '../call/Off'

import { ImFileText2 } from 'react-icons/im';

const BtnsBoard = () => {

	const btnsArr = [
		{
			img: false,
			icon: <ImFileText2 className='videoBtnImg fileBtnImg' />,
		},
		{
			img: '/images/video/video.png',
		},
		{
			img: '/images/video/mic.png',
		},	
		{
			img: '/images/video/reverse.png',
		},
	]

	return (
		<div className='videoBtnsBoard' >
			<Off />
			<div className='videoBtnsBoardNotOff' >
				{
					btnsArr.map((el, i) => {
						return (
							<Btn 
								img={el.img}
								icon={el.icon}
								key={i}
								i={i}
								btnsArr={btnsArr}
							/> 
						)
					})
				}
			</div>
		</div>
	)
}

export default BtnsBoard