import "./MenuSearch.css";
import { Link } from "react-router-dom";
import MenuSearchSection from "./MenuSearchSection";

const MenuSearch = () => {
  const menuSearchArr = [
    {
      SearchLink: "/web/page/doctorsearch",
      header: "רופאים",
      section: [
        {
          img: "/images/web/doctorsSearch/doctor2.jpg",
          text: 'ד"ר חנה לוי - גינקלוגית',
          itemLink: "/doctor",
        },
        {
          img: "/images/web/doctorsSearch/doctor3.jpg",
          text: 'ד"ר  הילה כהן- גינקלוגית',
          itemLink: "/doctor",
        },
      ],
    },
    {
      SearchLink: "#",
      header: "בלוגים",
      section: [
        {
          img: "/images/web/menuSearch/baby.jpg",
          text: "החיוך המודע הראשון של התינוק",
          itemLink: "/postPage2",
        },
        {
          img: "/images/web/menuSearch/drug.jpg",
          text: 'תרופה נגד צרבת "סמטקה"',
          itemLink: "/postPage1",
        },
      ],
    },
    {
      SearchLink: "#",
      header: "מוצרים",
      section: [
        {
          img: "/images/web/menuSearch/toy.jpg",
          text: "צעצועים לפעוטות",
          itemLink: "productdetails",
        },
      ],
    },
    {
      SearchLink: "/web/page/departmentsearch",
      header: "מחלקות",
      section: [
        {
          img: "/images/web/menuSearch/gynecology.jpg",
          text: "גינקלוגיה",
          itemLink: "aboutdepartment",
        },
      ],
    },
  ];

  const undisplaySearch = () => {
    document.querySelector(".menuSearch").style.display = "none";
  };

  return (
    <div onClick={undisplaySearch} className="menuSearch">
      {menuSearchArr.map((el, i) => {
        return (
          <MenuSearchSection
            SearchLink={el.SearchLink}
            header={el.header}
            section={el.section}
            key={i}
          />
        );
      })}
      <Link to="/web/page/searchall" className="menuSearchMore">
        לעוד תוצאות עבור לעמוד חיפוש
      </Link>
    </div>
  );
};

export default MenuSearch;
