import "./HeadNotifications.css";
import { connect } from "react-redux";
import React from "react";
import HeadNotificationsSquare from "./HeadNotificationsSquare";

const HeadNotifications = ({ notificationsArr }) => {
  return (
    <main className="headNotifications">
      {notificationsArr.map((el, i) => {
        while (i < 2) {
          return (
            <HeadNotificationsSquare
              img={el.img}
              massageBeforeBold={el.massageBeforeBold}
              bold={el.bold}
              massageAfterBold={el.massageAfterBold}
              date={el.date}
              btn={el.btn}
              i={i}
              key={i}
            />
          );
        }
      })}
      <div className="notificationsEmptyWrap">
        <h1 className="notificationsEmpty">אין התראות חדשות</h1>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationsArr: state.reducer.notificationsArr,
  };
};

export default connect(mapStateToProps)(HeadNotifications);
