import React from "react";

import chaticon from "../../../assets/images/chat-icon.png";
import review1 from "../../../assets/images/review1.png";
import review2 from "../../../assets/images/review2.png";

import arrow from "../../../assets/images/double_arrow_down.png";
import { Link } from "react-router-dom";
import singlearrow from "../../../assets/images/arrow.png";
import three_dots from "../../../assets/images/black_three_dots.png";

import ServiceList from "../../regularPages/serviceList/ServiceList";
import ServiceListHeader from "../../regularPages/serviceList/ServiceListHeader";

import "./Doctor.css";

import envelope from "../../../assets/images/envelope-icon.png";
import facebook from "../../../assets/images/facebook-icon.png";
import twitter from "../../../assets/images/twitter-icon.png";
import graduationcap from "../../../assets/images/doctor/graduation.png";
import graduationcapdark from "../../../assets/images/graduation-cap-dark.png";
import star from "../../../assets/images/star-icon.png";
import StarRoundIcon from '@mui/icons-material/Star';
import chatIcon from '../../../assets/images/footer/chat.png';

const Doctor = () => {
  return (
    <>
      <div className="PageDad">
        <div className="doctor_pages rtl">
          <div className="doctor_banner">
            <Link to={"/"} className="back_arrow">
              <img src={singlearrow} alt="arrow" />
            </Link>
            <Link to={"#"} className="menu_btn">
              <img src={three_dots} alt="three_dots" />
            </Link>
            <div className="banner_content">
              <h1>ד"ר חנה לוי</h1>
              <h6>גניקולוגית</h6>
              <div className="doctor_heading align-center justify-between all_review_section">
                <button className="all_reviews">כל הביקורות</button>
                <small>
                  <StarRoundIcon className="starImg" />
                  {/* <img src={star} alt="" /> */}
                  4.9 (1000 ביקורות){" "}
                </small>
              </div>
            </div>
          </div>
          <div className="doctors_sec doctors_details_sec">
            <div className="container">
              <div className="doctors_area">
                <div className="doctor_heading align-center justify-between">
                  <h6>מידע</h6>
                </div>
                <p className="doc_desc">
                  שלום שמי חנה סמית אני רופאה ותרפיסטית. במידה ויש לך שאלות, נא
                  צרי עימי קשר לקבלת מענה.
                </p>
                <div className="chat_icon">
                  <img class="chat" src={chatIcon}/>
                  {/* <img src={chaticon} alt="" /> */}
                </div>
                <div className="doctor_heading align-center justify-between">
                  <h6>ליצירת קשר</h6>
                </div>
                <div className="doctor_icons">
                  <p>
                    hana_levi@gmail.com <img src={envelope} alt="" />
                  </p>
                  <hr />
                  <p>
                    facebook.com/hanalevi <img src={facebook} alt="" />
                  </p>
                  <hr />
                  <p>
                    @hanalevi <img src={twitter} alt="" />
                  </p>

                  <div className="doctor_heading align-center justify-between certificate">
                    <h6>תעודות</h6>
                  </div>
                  <p>
                    בוגרת אוניברסיטת תל אביב <img className="graduation" src={graduationcap} alt="" />
                  </p>
                  <hr />
                  <p>
                    תעודה מקצועית בתרפיה <img className="graduationDark" src={graduationcap} alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ServiceList />

          <div className="review_rating_sec">
            <div className="container">
              <div className="doctors_area">
                <ServiceListHeader text="ביקורות וציונים" link="#" text2='לכל הביקורות' />
                <div className="service_box_area">
                  <div className="service_box rating_box">
                    <div className="box_con review_box">
                      <div className="box_heading d-flex align-center justify-between">
                        <span>11 Feb 2022</span>
                        <h4>גיל קורסי</h4>
                      </div>
                      <span className="star">
                        5.0 <StarRoundIcon className="starImg" />
                      </span>
                      <div className="price d-flex align-center justify-between">
                        <p>
                          בזכות חנה יש לנו תינוק בריא! היא ניהלה את כל ההריון
                          ועזרה לנו להתאושש לאחר מכן
                        </p>
                      </div>
                    </div>
                    <div className="box_round_img">
                      <img src={review1} alt="Online" />
                    </div>
                  </div>
                  <div className="service_box rating_box">
                    <div className="box_con review_box">
                      <div className="box_heading d-flex align-center justify-between">
                        <span>28 Jan 2022</span>
                        <h4>סיון ששון</h4>
                      </div>
                      <span className="star">
                        5.0 <StarRoundIcon className="starImg" />
                      </span>
                      <div className="price d-flex align-center justify-between">
                        <p>
                          בזכות העזרה של ד"ר חנה לוי הצלחתי להיכנס להריון, תודה
                          רבה חנה
                        </p>
                      </div>
                    </div>
                    <div className="box_round_img">
                      <img src={review2} alt="Online" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doctor;
