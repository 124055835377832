import React from "react";
import { Container, Button, Row, Col, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Banner from "./banner/Banner";

import "./DoctorsSearch.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const DoctorsSearch = () => {
  let navigate = useNavigate();

  const DoctorList = [
    {
      title: `ד"ר ירדן פרי | גינקולוגית מומחית `,
      image: "/images/web/doctorsSearch/doctor1.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר חנה לוי | גינקולוגית מומחית `,
      image: "/images/web/doctorsSearch/doctor2.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר הילה כהן | גינקולוגית מומחית `,
      image: "/images/web/doctorsSearch/doctor3.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר ברכה ספיר | מיילדת מומחית `,
      image: "/images/web/doctorsSearch/doctor4.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר דור גולד | מיילד מומחה `,
      image: "/images/web/doctorsSearch/doctor5.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר פינחס אנגלוביץ | רופא ילדים מומחה `,
      image: "/images/web/doctorsSearch/doctor6.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר אורן כהן | רופא ילדים מומחה `,
      image: "/images/web/doctorsSearch/doctor7.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר שושנה אלברט | מנתחת מומחית `,
      image: "/images/web/doctorsSearch/doctor8.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר מרים סגל | אורתופדית מומחית `,
      image: "/images/web/doctorsSearch/doctor9.jpg",
      button_tex: "קביעת פגישה",
    },
    {
      title: `ד"ר צוף יוסף | מנתחת מומחית `,
      image: "/images/web/doctorsSearch/doctor10.jpg",
      button_tex: "קביעת פגישה",
    },
  ];

  const filter_by_speciality = [
    {
      id: "1",
      title: "יולדות",
    },
    {
      id: "2",
      title: "גינקולוגיה",
    },
    {
      id: "3",
      title: "אמהות",
    },
    {
      id: "4",
      title: "תינוקות",
    },
    {
      id: "5",
      title: "רפואת ילדים",
    },
    {
      id: "6",
      title: "ניתוחים",
    },
  ];

  const filter_by_sub_expertise = [
    {
      id: "1",
      title: "אבחונים",
    },
    {
      id: "2",
      title: "ויטמנים",
    },
    {
      id: "3",
      title: "ייעוץ",
    },
    {
      id: "4",
      title: "אולטרסאונד",
    },
    {
      id: "5",
      title: "גינקלוג  מחלות עור",
    },
    {
      id: "6",
      title: "פריון",
    },
  ];

  const filter_by_treatment = [
    {
      id: "1",
      title: "גינקולוגיה",
    },
    {
      id: "2",
      title: "רדיולוגיה",
    },
    {
      id: "3",
      title: "נוירוכירורגיה",
    },
    {
      id: "4",
      title: "ניתוח פלסטי",
    },
    {
      id: "5",
      title: "קרדיולוגיה",
    },
    {
      id: "6",
      title: "אונוקולוגיה",
    },
  ];

  const goToAppointment = () => {
    navigate("/appointment");
  };
  return (
    <>
      <div className="doctorsSearchPages">
        <Banner />

        <div className="doctorListMain">
          <Container className="searchPagesContainer mt-4">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי טיפול | ניתוח
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filter_by_treatment.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי תת-מומחיות
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filter_by_sub_expertise.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי מומחיות
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filter_by_speciality.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>

          <Container className="doctorListContainer">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-12">
                {DoctorList.map((el, i) => {
                  return (
                    <div
                      className="doctorDetail d-flex align-items-center justify-content-between mt-3"
                      key={i}
                    >
                      <Button
                        className="doctorDetailBtn"
                        onClick={goToAppointment}
                      >
                        <Row>
                          <Col sm={1} className="doctorDetailArrowIcon">
                            <ArrowBackIosIcon />
                          </Col>
                          <Col sm={11} className="doctorDetailBtnText">
                            {el.button_tex}
                          </Col>
                        </Row>
                      </Button>
                      <h1 className="doctorDetailTitle">{el.title}</h1>
                      <img
                        src={el.image}
                        className="doctorDetailImg"
                        alt="doctor"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Container>
        </div>
        <div className="doctorSearchPagesMoreBtnDiv d-flex justify-content-center align-items-center mt-5">
          <Button className="doctorSearchPagesMoreBtn">עוד...</Button>
        </div>
      </div>
    </>
  );
};

export default DoctorsSearch;
