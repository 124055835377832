import './Call.css';
import Header from './Header'
import Photo from './Photo'
import Off from './Off'
import BtnList from './BtnList'

import doctorImg from '../../../assets/images/call/doctor.png'

const Call = () => {
	return (
		<div className='PageDad' >
			<div className='page' >
				<div className='callPage' >
					<Header name='ד"ר חנה לוי' />	
					<Photo img={doctorImg} />	
					<div className='btnsDiv' >
						<Off />	
						<BtnList />	
					</div>
				</div>
			</div>
		</div>

	)
}

export default Call