import React from "react";
import arrow from "../../../assets/images/double_arrow_down.png";
import "./HospitalList.css";
import { Link } from "react-router-dom";
import HospitalSqaureList from "./HospitalSqaure/HospitalSqaureList";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const HospitalList = () => {
  return (
    <div className="hospital_list_sec">
      <div className="container">
        <div className="hospital_list_area">
          <div className="hospital_list_heading d-flex align-center justify-between">
            <Link to={"/hospitals"} className="d-flex align-center">
                <KeyboardDoubleArrowLeftIcon className="doubleAroow" />
              לפתיחה
            </Link>
            <h3>בתי חולים</h3>
          </div>

          <div className="hospital_list_box_area">
            <HospitalSqaureList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalList;
