import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import leftArrow from "../../../../assets/images/icons/left-arrow.png";

const RecipesSquareList = () => {
    const RecipesListArr = [
        {
            image: "/images/allRecipes/all_recipes.jpg",
            h6_text_1: "ביצה, עגבניה ותרד",
            h6_text_2: "15 דקות",
            h6_text_3: "עשיר בברזל",
            arrow_img: { leftArrow },
            link: "/club",
        },
        {
            image: "/images/allRecipes/all_recipes.jpg",
            h6_text_1: "ביצה, עגבניה ותרד",
            h6_text_2: "15 דקות",
            h6_text_3: "עשיר בברזל",
            arrow_img: { leftArrow },
            link: "/club",
        },
        {
            image: "/images/allRecipes/all_recipes.jpg",
            h6_text_1: "ביצה, עגבניה ותרד",
            h6_text_2: "15 דקות",
            h6_text_3: "עשיר בברזל",
            arrow_img: { leftArrow },
            link: "/club",
        },
    ];

    const slides = RecipesListArr.map((el, index) => (
        <Link to={"" + el.link + ""} key={index}>
            <img
                src={el.image}
                alt="images"
                className="all_recipes_area_image"
            />
            <img className="right_arrow_img" src={el.arrow_img.leftArrow} alt="right-arrow" />
            <h6 className="all_recipes_area_image_des_1">{el.h6_text_1}</h6>
            <h6 className="all_recipes_area_image_des_2">{el.h6_text_2}</h6>
            <h6 className="all_recipes_area_image_des_3">{el.h6_text_3}</h6>
        </Link>
    ));

    return (
        <>
            <Swiper spaceBetween={30}>
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default RecipesSquareList;