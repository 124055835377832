import { combineReducers } from "redux";

import loader from "./loader";
import reducer from "./reducer";
import user from "./user";
import doctor from "./doctor";
import service from "./service";

const rootReducer = combineReducers({
  loader,
  reducer,
  user,
  doctor,
  service
});

export default rootReducer;
