import "./Banner.css";
import React from "react";

import banner from "../../../../../assets/images/postpage/banner_post_page1.png";

const Banner = () => {
  return (
    <div className="gynecologybanner">
      <img className="bannerBG" src={banner} />
        <div className="bannerText">
          <div className="bannerTextH">איך לבנות לוח זמנים לשינה של התינוק שלך</div>
        </div>
    </div>
  );
};

export default Banner;