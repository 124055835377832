import WebMenu from "../../repeats/menu/Menu";
import HeadNotifications from "../../notificationsPages/headNotifications/HeadNotifications";

const Menu = () => {
  const display = (className) => {
    const item = document.querySelector(className);
    if (item.style.display === "block") {
      item.style.display = "none";
    } else {
      item.style.display = "block";
    }
  };

  return (
    <>
      <img
        onClick={() => display(".menu")}
        className="navMenuI"
        src="/images/head/menu.png"
      />
      <div className="menu webMenu">
        <img
          onClick={() => display(".menu")}
          className="menuEsc"
          src="/images/head/esc.png"
        />
        <WebMenu display={display} />
      </div>
      <HeadNotifications />
    </>
  );
};

export default Menu;
