import './MyBody.css';
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';
import MyWeight from './MyWeight';
import HealthList from './HealthList';
import AidCardList from './AidCardList';

const MyBody = () => {
	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='הגוף שלי' />
				<div className='myBodyPage'>
					<MyWeight />
					<HealthList />
					<AidCardList />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default MyBody