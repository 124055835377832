import React, { Component } from "react";
import "./Footer.css";
import FooterSquare from "./FooterSquare";
import GrayIcon from "./../../repeats/footer/GrayIcon";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaEnvelopeOpenText } from 'react-icons/fa';

//This is for chatbot
import ChatBot from "../../chat/ChatBot";
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Footer = (props) => {
  const [openSideBar, setOpenSideBar] = useState(false);

  // const [sideBarPage, setSideBarPage] = useState();
  const siderBarComponent = [
    "/appointment",
    "/doctor",
    "/mybaby",
    "/marketplace",
    "/settings",
    "/contact",
  ];
  const location = useLocation();

  useEffect(() => {
    if (siderBarComponent.includes(location.pathname)) {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, []);

  const footerContentArr = [
    {
      link: "/web/page/contactus",
      // img: "/images/web/footer/email.jpg",
      img: <FaEnvelopeOpenText className='footerSquareImg' />,
      header: "שלחי לנו אימייל",
      text: "שלחי לנו אימייל לשאלות כלליות, כולל הזדמנויות שיווק ושותפות.",
      detail: "hello@wolfson.com",
    },
    {
      link: "#",
      // img: "/images/web/footer/phone.jpg",
      img: <PhoneEnabledIcon className='footerSquareImg' />,
      header: "התקשרי אלינו",
      text: "התקשרי אלינו ותוכלי לדבר עם אחד מחברי הצוות, אנחנו תמיד שמחים לעזור.",
      detail: "+ (972) 54-786-5060",
    },
    {
      link: "#",
      // img: "/images/web/footer/support.jpg",
      img: <SupportAgentIcon className='footerSquareImg' />,
      header: "תמיכה",
      text: "בדקי את התמיכה שלנו, שאלות נפוצות והעוזר הוירטואלי.",
      detail: "צרי קשר",
    },
  ];

  // const containerArr = [
  //   {
  //     img: "/images/footer/club1.png",
  //     link: "/web/page/club",
  //   },
  //   {
  //     img: "/images/footer/list1.png",
  //     link: "/mybaby",
  //   },
  //   {
  //     img: "/images/footer/book1.png",
  //     link: "/appointment",
  //   },
  //   {
  //     img: "/images/footer/shopping_cart1.png",
  //     link: "/marketplace",
  //   },
  // ];

  // const openSectionFromSideIcon = (page) => {
  //   setSideBarPage(page);
  // };
  console.log(openSideBar);
  return (
    <div className="webFooter">
      <div
        className={
          openSideBar
            ? "customizer border-left-blue-grey border-left-lighten-4 open"
            : "customizer border-left-blue-grey border-left-lighten-4 d-none d-md-block"
        }
      >
        <a className="customizer-close" href="#">
          <i className="ft-x font-medium-3"></i>
        </a>
        <div className="customizer-toggle box-shadow-3">
          {openSideBar == false ? (
            <Link
              to={"/settings"}
              onClick={() => setOpenSideBar(true)}
              style={{ margin: "8px 0 0 10px" }}
            >
              <KeyboardDoubleArrowLeftIcon
                aria-hidden="true"
                style={{ fill: "white", fontSize: '30px' }}
              />
            </Link>
          ) : (
            <Link
              style={{ margin: "8px 0 0 10px" }}
              to={"/web/page/home"}
              onClick={() => setOpenSideBar(false)}
            >
              <KeyboardDoubleArrowRightIcon
                aria-hidden="true"
                style={{ fill: "white", fontSize: '30px' }}
              />
            </Link>
          )}
          <Link
            id="customizer-toggle-setting"
            onClick={() => setOpenSideBar(true)}
            to={"/settings"}
            style={{ margin: '7px 14px 14px 14px' }}
          >
            <SettingsIcon
              aria-hidden="true"
              style={{ fill: "white" }}
            />
          </Link>

          <Link to={"/web/page/club"}>
            <img src="/images/footer/club1.png" className="grayIcon" />
          </Link>

          <Link to={"/mybaby"} onClick={() => setOpenSideBar(true)}>
            <img src="/images/footer/list1.png" className="grayIcon" />
          </Link>

          <Link to={"/appointment"} onClick={() => setOpenSideBar(true)}>
            <img src="/images/footer/book1.png" className="grayIcon" />
          </Link>

          <Link to={"/marketplace"} onClick={() => setOpenSideBar(true)}>
            <img src="/images/footer/shopping_cart1.png" className="grayIcon" />
          </Link>

          {/* {containerArr.map((el, i) => {
            return (
              <Link className="" key={i} to={el.link} onClick={() => setOpenSideBar(true)}>
                <GrayIcon img={el.img} />
              </Link>
            );
          })} */}

          <ChatBot viewForm={"web"} />
        </div>
        <div className="customizer-content p-2">{props.sidebarComponent}</div>
      </div>
      {footerContentArr.map((el, i) => {
        return (
          <FooterSquare
            link={el.link}
            img={el.img}
            header={el.header}
            text={el.text}
            detail={el.detail}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default Footer;
