import React from "react";
import { Container, Button, Row, Col, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Banner from "./banner/Banner";

import "./TreatmentsSearch.css";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TreatmentsSearch = () => {
  let navigate = useNavigate();

  const TreatmentList = [
    {
      title: "גינקולוגיה",
      button_tex: "רופאים",
    },
    {
      title: "מרכז רדיולוגיה",
      button_tex: "רופאים",
    },
    {
      title: "נוירוכירורגיה",
      button_tex: "רופאים",
    },
    {
      title: "ניתוחים פלסטים",
      button_tex: "רופאים",
    },
    {
      title: "קרדיולוגיה",
      button_tex: "רופאים",
    },
    {
      title: "אונוקולגיה",
      button_tex: "רופאים",
    },
    {
      title: "טיפול בהפרעות קצב",
      button_tex: "רופאים",
    },
    {
      title: "רפואת עיניים",
      button_tex: "רופאים",
    },
    {
      title: "גסטרואנטרולוגיה",
      button_tex: "רופאים",
    },
    {
      title: "ניתוחים כירורגים",
      button_tex: "רופאים",
    },
  ];

  const filter_by_speciality = [
    {
      id: "1",
      title: "יולדות",
    },
    {
      id: "2",
      title: "גניקולוגיה",
    },
    {
      id: "3",
      title: "אמהות",
    },
    {
      id: "4",
      title: "תינוקות",
    },
    {
      id: "5",
      title: "רפואת ילדים",
    },
    {
      id: "6",
      title: "ניתוחים",
    },
  ];

  const filter_by_sub_expertise = [
    {
      id: "1",
      title: "אבחונים",
    },
    {
      id: "2",
      title: "ויטמנים",
    },
    {
      id: "3",
      title: "ייעוץ",
    },
    {
      id: "4",
      title: "אולטרסאונד",
    },
    {
      id: "5",
      title: "גינקלוג  מחלות עור",
    },
    {
      id: "6",
      title: "פריון",
    },
  ];

  const filter_by_doctor = [
    {
      id: "1",
      title: `ד"ר חנה לוי, גינקולגית`,
    },
    {
      id: "2",
      title: `ד"ר חווה פרישברג, מילדת`,
    },
    {
      id: "3",
      title: `ד"ר מאיר ברנר, קרדיולוג`,
    },
    {
      id: "4",
      title: `ד"ר חיה אזולאי, גינקולוגית`,
    },
    {
      id: "5",
      title: `ד"ר אבי גינזבורג, גינקולוג`,
    },
    {
      id: "6",
      title: `ד"ר יעל כהן, גינקולוגית`,
    },
  ];

  const goToDoctor = () => {
    navigate("/doctors");
  };

  return (
    <>
      <div className="treatmentsSearchPages">
        <Banner />

        <div className="treatmentListMain">
          <Container className="searchPagesContainer mt-4">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי טיפול | ניתוח
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filter_by_doctor.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי תת-מומחיות
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filter_by_sub_expertise.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-sm-12 col-md-4 col-4">
                <Dropdown>
                  <Dropdown.Toggle
                    className="searchPagesContainerBtn"
                    variant="secondary"
                  >
                    סינון לפי רופאות
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {filter_by_speciality.map((el, i) => {
                      return (
                        <Dropdown.Item to={el.title} key={i}>
                          {el.title}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>

          <Container className="treatmentListContainer">
            {TreatmentList.map((el, i) => {
              return (
                <div
                  className="treatmentDetail d-flex align-items-center justify-content-between mt-3"
                  key={i}
                >
                  <Button className="treatmentDetailBtn" onClick={goToDoctor}>
                    <Row>
                      <Col sm={1} className="treatmentDetailArrowIcon">
                        <ArrowBackIosIcon />
                      </Col>
                      <Col sm={11} className="treatmentDetailBtnText">
                        {el.button_tex}
                      </Col>
                    </Row>
                  </Button>
                  <h1 className="treatmentDetailTitle">{el.title}</h1>
                </div>
              );
            })}
          </Container>
        </div>
        <div className="treatmentSearchPagesMoreBtnDiv d-flex justify-content-center align-items-center mt-5">
          <Button className="treatmentSearchPagesMoreBtn">עוד...</Button>
        </div>
      </div>
    </>
  );
};

export default TreatmentsSearch;
