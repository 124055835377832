import './Fitness.css';
import FitnessSquare from './FitnessSquare'
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';

const Fitness = () => {

	const containerArr = [
		{
			img: '/images/fitness/breath.jpg',
			text: 'לנשום נכון'
		},
		{
			img: '/images/fitness/yoga.jpg',
			text: 'יוגה'
		},
		{
			img: '/images/fitness/stretching.jpg',
			text: 'מתיחות'
		},
		{
			img: '/images/fitness/workout.jpg',
			text: 'אימון'
		},
	]

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='אורח חיים בריא' />
				<main className='fitnessSquareContainer' >
					{
						containerArr.map((el, i) => {
							return (
								<FitnessSquare
									img={el.img}
									text={el.text}
									key={i}
								/> 
							)
						})
					}
				</main>
			</div>
			<Footer />
		</div>
	)
}

export default Fitness