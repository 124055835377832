import React, { useState } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import { validateAll } from "indicative/validator";

import "./ContactUs.css";

const ContactUs = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    city: "",
    message: "",
    error: [],
  });

  const { name, email, company, city, message, error } = state;

  const changeValue = (e) => {
    let value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const submitContactUs = (e) => {
    e.preventDefault();

    const rules = {
      name: "required",
      email: "required|email",
      company: "required",
      city: "required",
      message: "required",
    };

    const message = {
      "name.required": "Name is Required.",
      "email.required": "Email is Required.",
      "email.email": "Please Enter Valid Email.",
      "company.required": "Company is Required.",
      "city.required": "City is Required.",
      "message.required": "Message is Required.",
    };

    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: [],
        });
      })
      .catch((errors) => {
        const formaerrror = {};
        if (errors.length) {
          errors.forEach((element) => {
            formaerrror[element.field] = element.message;
          });
        }

        setState({
          ...state,
          error: formaerrror,
        });
      });
  };

  return (
    <>
      <div className="contactUsPages rtl">
        <h1 className="contactUsPagesTitle  d-flex justify-content-center">
          שליחת אימייל
        </h1>
        <Container className="contactUsPagesContainer mt-5">
          <Form className="contactUsFrom">
            <Row className="contactUsFromRow">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>שם מלא*</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    id="name"
                    name="name"
                    className={
                      state.error && state.error.name
                        ? "contactUsFromName invalid-error"
                        : "contactUsFromName"
                    }
                    placeholder="שם מלא"
                    onChange={(e) => changeValue(e)}
                  />
                  {state.error && state.error.name && (
                    <p className="error">
                      {state.error
                        ? state.error.name
                          ? state.error.name
                          : ""
                        : ""}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>אימייל *</Form.Label>
                  <Form.Control
                    size="lg"
                    type="email"
                    id="email"
                    name="email"
                    className={
                      state.error && state.error.email
                        ? "contactUsFromEmail invalid-error"
                        : "contactUsFromEmail"
                    }
                    placeholder="אימייל"
                    onChange={(e) => changeValue(e)}
                  />
                  {state.error && state.error.email && (
                    <p className="error">
                      {state.error
                        ? state.error.email
                          ? state.error.email
                          : ""
                        : ""}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="contactUsFromRow">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>שם החברה*</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    id="company"
                    name="company"
                    className={
                      state.error && state.error.company
                        ? "contactUsFromCompany invalid-error"
                        : "contactUsFromCompany"
                    }
                    placeholder="שם החברה"
                    onChange={(e) => changeValue(e)}
                  />
                  {state.error && state.error.company && (
                    <p className="error">
                      {state.error
                        ? state.error.company
                          ? state.error.company
                          : ""
                        : ""}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>עיר</Form.Label>
                  <Form.Select
                    size="lg"
                    id="city"
                    name="city"
                    className={
                      state.error && state.error.city
                        ? "contactUsFromCity invalid-error"
                        : "contactUsFromCity"
                    }
                    onChange={(e) => changeValue(e)}
                  >
                    <option selected disabled>
                      בחרי עיר
                    </option>
                    <option value="1">עכו</option>
                    <option value="2">באר שבע</option>
                    <option value="3">דימונה</option>
                    <option value="4">כרמיאל</option>
                    <option value="5">לוד</option>
                    <option value="6">נהריה</option>
                    <option value="7">רהט</option>
                    <option value="8">סכנין</option>
                    <option value="9">תל אביב</option>
                    <option value="10">יבנה</option>
                  </Form.Select>
                  {state.error && state.error.city && (
                    <p className="error">
                      {state.error
                        ? state.error.city
                          ? state.error.city
                          : ""
                        : ""}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="contactUsFromRow">
              <Col>
                <Form.Group>
                  <Form.Label>ההודעה שלך*</Form.Label>
                  <Form.Control
                    type="textarea"
                    id="message"
                    name="message"
                    className={
                      state.error && state.error.message
                        ? "contactUsFromMessage invalid-error"
                        : "contactUsFromMessage"
                    }
                    placeholder="כתבי את ההודעה שלך…"
                    as="textarea"
                    rows={4}
                    onChange={(e) => changeValue(e)}
                  />
                  {state.error && state.error.message && (
                    <p className="error">
                      {state.error
                        ? state.error.message
                          ? state.error.message
                          : ""
                        : ""}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="contactUsFromBtn mt-5"
              onClick={(e) => submitContactUs(e)}
            >
              שליחה
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
