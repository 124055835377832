import React from "react";
import { Container } from "react-bootstrap";
import Banner from "./banner/Banner";
import "./AboutDepartment.css";
import our_product from "../../../assets/videos/ourDepartment/our_department.mp4";

const AboutDepartment = () => {
  return (
    <div className="webAboutDepartmentPage">
      <Container>
        <Banner />
        <Container>
          <div className="aboutText mt-4 pt-1">
            <p>
              כשתגיעו למחלקה למיילדות וגינקולוגיה במרפאת וולפסון, תמצאו תמיכה
              במסע החיים שלכם מגיל ההתבגרות ועד גיל המעבר והלאה. הגישה של הצוות
              שלנו כוללת אותך כשותף בטיפול, ריפוי, תקווה ופתרונות לבעיות
              מורכבות.
            </p>
            <p>
              במרפאה יש את אחת ממחלקות המיילדות והגינקולוגיות הגדולות והמכובדות
              בעולם, עם יותר מ-55 מומחים המחויבים להעניק טיפול רפואי וכירורגי
              מקיף, רב תחומי.
            </p>
          </div>
          <div className="videoDev d-flex justify-content-center mt-5">
            <video className="productVideo" controls>
              <source src={our_product} type="video/mp4" />
            </video>
          </div>
          <p className="videoText">
            <p>סרטון קצר על מחלקת גינקלוגיה</p>
          </p>
        </Container>
      </Container>
    </div>
  );
};

export default AboutDepartment;
