import Btn from './Btn'

const BtnList = () => {

	const btnListArr1 = [
		{
			img: '/images/call/microphone.png',
			text: 'מיקרופון',
		},
		{
			img: '/images/call/speaker.png',
			text: 'רמקול',
		},
		{
			img: '/images/call/video.png',
			text: 'וידאו',
		},
	]
	const btnListArr2 = [
		{
			img: '/images/call/message.png',
			text: 'הודעות',
		},
		{
			img: '/images/call/file.png',
			text: 'קבצים',
		},
		{
			img: '/images/call/voicemail.png',
			text: 'דואר קולי',
		},		
	]

	return (
		<div className='btnList' >
			<div className='btnListRow' >
				{
					btnListArr1.map((el, i) => {
						return (
							<Btn 
								img={el.img}
								text={el.text}
								key={i}
							/> 
						)
					})
				}
			</div>
			<div className='btnListRow' >
				{
					btnListArr2.map((el, i) => {
						return (
							<Btn 
								img={el.img}
								text={el.text}
								key={i}
							/> 
						)
					})
				}
			</div>
		</div>
	)
}

export default BtnList