import { Link } from "react-router-dom";

const Blog = ({
  link,
  blogClass,
  blogContentClass,
  img,
  header1,
  header2,
  text,
}) => {
  return (
    <Link to={link}>
      <div className={blogClass}>
        <div className={blogContentClass}>
          <div className="blogContentH">
            {header1} <br />
            {header2}
          </div>
          <div className="blogContentP">{text}</div>
        </div>
        <div className="blogImgDiv">
          <img className="blogImg" src={img} />
        </div>
      </div>
    </Link>
  );
};

export default Blog;
