import "./Nav.css";
import Header from "./Header";
import Menu from "./Menu";
import BtnsList from "./BtnsList";
import Search from "./Search";
import MenuSearch from "../searchPages/menuSearch/MenuSearch";
import IconList from "./IconList";
import StartBtn from "./StartBtn";
import { useSelector } from "react-redux";

const Nav = () => {
	const user = useSelector((state) => state.user);

  return (
    <div className="webNav">
      <Header />
      <Menu />
      <BtnsList />
      <Search />
      <MenuSearch />
      <IconList />
      {!user.id ? (
        <>
          <StartBtn
            link="/login"
            text="התחברי"
            className="StartBtn StartBtn1"
          />
          <StartBtn
            link="/signup"
            text="הרשמי"
            className="StartBtn StartBtn2"
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Nav;
