import { Link } from 'react-router-dom';
import steps from "../../../assets/images/myBody/steps.jpg";

const HealthSquare = ({link, header, par, percentImg, percent}) => {
	return (
		<Link to={link}>
			<div className='healthSquare'>
				{/*<div className='healthSquareBGDiv'>
					<img className='healthSquareBG' src={bg} />
				</div>*/}
				<div className='healthSquareContent'>
					<div className='healthSquareText'>
						<div className='healthSquareTextH'>{header}</div>
						<div className='healthSquareTextP'>{par}</div>
					</div>
					<div className='healthSquareNumber'>
						<img className='healthSquareNumberImg' src={percentImg} />
						<div className='healthSquareNumberH' >{percent}</div>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default HealthSquare