import './Video.css';
import SelfScreen from './SelfScreen'
import BackBtn from './BackBtn'
import BtnsBoard from './BtnsBoard'
import ShareFiles from './ShareFiles'

const Video = () => {
	return (
		<div className='PageDad' >
			<div className='page' >
				<div className='videoPage' >
					<img className='videoBG' src='/images/video/videoBG.jpg' alt='video' />
					<SelfScreen img='images/video/self.jpg' />
					<BackBtn />
					<BtnsBoard />
					<ShareFiles />
				</div>
			</div>
		</div>

	)
}

export default Video