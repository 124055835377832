import "./Banner.css";
import React from "react";

import banner from "../../../../../assets/images/postpage/banner_post_page2.png";

const Banner = () => {
  return (
    <div className="gynecologybanner">
      <img className="bannerBG" src={banner} />
        <div className="bannerText">
          <div className="bannerTextH">.קוליק ראשון. איך לעזור לתינוק</div>
        </div>
    </div>
  );
};

export default Banner;