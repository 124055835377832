import React, { useEffect, useRef } from "react";
import DayPicker from "../../regularPages/repeatsComponents/daypicker/DayPicker";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import "./Nutricion.css";

import DoNotChart from './DoNotChart'

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import RecipesSquareList from "./RecipesSquare/RecipesSquareList";

const Nutricion = () => {
  const nutrients_list = [
    {
      "id": 1,
      "title": "חלבון",
      "percentage": 48
    },
    {
      "id": 1,
      "title": "פחמימות",
      "percentage": 52
    },
    {
      "id": 1,
      "title": "סידן",
      "percentage": 96
    },
    {
      "id": 1,
      "title": "אומגה 3",
      "percentage": 47
    },
    {
      "id": 1,
      "title": "מגנזיום",
      "percentage": 30
    }
  ];

  const percentage = 72;

  return (
    <div className="PageDad">
      <div className="nutricion_pages rtl">
        <Head text="תזונה נכונה" />
        <div className="nutricion_pages_area">
          <div className="nutricion_pages_card">
            <div className="nutricion_pages_card_text">
              <DayPicker />
            </div>
            <div className="my_chart">
              <CircularProgressbarWithChildren value={percentage} strokeWidth={3}
                styles={buildStyles({
                  textColor: "var(--primary)",
                  pathColor: "var(--primary)",
                  trailColor: "var(--secondry)",
                })}
              >
                <p className="progress_text">היום <br />ברזל</p>
                <h2 className="progress_percentage">{percentage}%</h2>
                <p className="progress_text">21.6/30 מ"ג</p>
              </CircularProgressbarWithChildren>
            </div>
            <div className="chart_list_title">
              <h2>התזונה להיום</h2>
            </div>
            <div className="nutricion_chart_list">
              {
                nutrients_list.map((el, i) => {
                  return (<DoNotChart data={el} key={i} />)
                })
              }
            </div>

            <div className="chart_cal_title">
              <h2>1620 / 2000 סידן</h2>
            </div>

          </div>

        </div>

        <section className="all_recipes_area">
          <h1 className="all_recipes_h1">מתכונים</h1>
          {/* Recipes Swiper */}
          <RecipesSquareList />
          {/* Recipes Swiper */}
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Nutricion;
