import React from "react";
import { Link } from "react-router-dom";

import StarRoundedIcon from '@mui/icons-material/StarRounded';

const HospitalSqaure = ({
  image,
  h4_text,
  p_text,
  span_text,
  star_image,
  link,
}) => {
  return (
    <>
      <Link className='hospital_list_box_link' to={"" + link + ""}>
        <div className="hospital_list_box">
          <div className="box_con">
            <div className="box_heading">
              <h4>{h4_text}</h4>
              <p>{p_text}</p>
              <div className="box_details">
                {/* <img src={star_image.star} alt="star" />{" "} */}
                <StarRoundedIcon/>
                <span>{span_text}</span>
              </div>
            </div>
          </div>
          <div className="box_img">
            <img src={image} alt="Online" />
          </div>
        </div>
      </Link>
    </>
  );
};

export default HospitalSqaure;
