import "./ProductsList.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ProductSquare = ({ img, header, price, link, image_key }) => {
  return (
    <Link className="productSquareLink" to={link}>
      <div className="productSquare">
        <div className="productSquareImg">
          <img className="productSquareImgBG" src={img} />
          <img
            className="productSquareImgHeart"
            src="/images/web/productList/love.png"
          />
          {image_key === 1 ? (
            <>
              <div className="time_dt d-flex justify-content-center">
                <p className="off15">
                  <strong>35% הנחה</strong>
                  <span>03:25:41</span>
                </p>
              </div>
            </>
          ) : image_key === 2 ? (
            <>
              {/* <div className="time_dt d-flex justify-content-center dark"> */}
                {/* <p> */}
                  {/* <strong>-10$</strong> */}
                {/* </p> */}
					      <div className='productSquareImgOff'>-10$</div>
              {/* </div> */}
            </>
          ) : image_key === 0 ? (
            <></>
          ) : (
            ""
          )}
        </div>
        <div className="productSquareText">
          <div className="productSquareTextH">{header}</div>
          <div className="productSquareTextPrice">{price}</div>
        </div>
      </div>
    </Link>
  );
};

export default ProductSquare;
