import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SearchSqaure = ({ img, text, itemLink, btnText }) => {
  return (
    <>
      <Link to={itemLink} className="searchAllItem">
        <img className="searchAllItemImg" src={img} />
        <div className="searchAllItemText">{text}</div>
        <Button className="searchAllItemBtn">
          <Row>
            <Col sm={1} className="SearchAllArrowIcon">
              <ArrowBackIosIcon />
            </Col>
            <Col sm={11} className="searchAllItemBtnText">{btnText}</Col>
          </Row>
        </Button>
      </Link>
    </>
  );
};

export default SearchSqaure;
