import React, { useState, useEffect } from "react";
import DetailSquare from "./DetailSquare";
import { UserAction } from "../../../actions/user";
import { useSelector } from "react-redux";
import { validateAll } from "indicative/validator";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import Helper from "../../../helpers/api";
import { Toast } from "bootstrap";

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';

import profile from '../../../assets/images/account/profile.png'


const DetailsList = () => {
  const InputFile = styled("input")({
    display: "none",
  });

  const userAction = UserAction();

  const [trueFalseState, setTrueFalseState] = useState(false);

  const [inputType, setInputType] = useState(null);

  const user = useSelector((state) => state.user);

  const [state, setState] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    email: user.email,
    error: [],
  });

  useEffect(() => {
    setState({
      ...state,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      error: [],
    });
  }, [user]);

  const onChangeValue = (e, type) => {
    let value = e.target.value;
    setState({
      ...state,
      [type]: value,
    });
  };

  const editContactDetail = (e, type) => {
    e.preventDefault();

    setInputType(type);
    setTrueFalseState(true);

    setState({
      ...state,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      error: [],
    });
  };

  const cancelBtn = (e) => {
    e.preventDefault();

    setTrueFalseState(false);

    setState({
      ...state,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      error: [],
    });
  };

  const updateContactDetail = (e) => {
    e.preventDefault();

    const rules = {
      firstName: "required",
      lastName: "required",
      email: "required|email",
      phone: "required|number",
    };
    const message = {
      "firstName.required": "First Name is Required.",
      "lastName.required": "Last Name is Required.",
      "email.string": "Email Must Be String.",
      "email.required": "Email is Required.",
      "email.email": "Please Enter Valid Email.",
      "phone.required": "Phone Number is Required.",
      "phone.number": "Phone Number must be Number not String.",
    };

    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: [],
        });

        let data = {};
        data.firstName = state.firstName;
        data.lastName = state.lastName;
        data.phone = state.phone;
        userAction.updateUser(data);
        setTrueFalseState(false);
      })
      .catch((errors) => {
        const formattedErrors = {};
        if (errors) {
          errors.forEach(
            (error) => (formattedErrors[error.field] = error.message)
          );
        }
        setState({
          ...state,
          error: formattedErrors,
        });
      });
  };

  const editImageClick = (e, type) => {
    e.preventDefault();

    setInputType(type);
    setTrueFalseState(true);
  };

  const changeFile = (e) => {
    const file = e.target.files[0];

    userAction
      .uploadImage(file)
      .then((response) => {
        if (response) {
          //This is to update user image
          let data = {};
          data.profileImage = response.url;
          userAction.updateUser(data);
        }
      })
      .catch((error) => {
        Toast.error(error.message);
      });
    setTrueFalseState(false);
  };

  const containerArr = [
    {
      img: <PersonIcon className="detailImg" />,
      info: state.firstName,
      type: "שם פרטי",
      // type: "firstName",
      error: state.error && state.error.firstName ? state.error.firstName : "",
    },
    {
      img: <PersonIcon className="detailImg" />,
      info: state.lastName,
      type: "שם משפחה",
      // type: "lastName",
      error: state.error && state.error.lastName ? state.error.lastName : "",
    },
    {
      img: <PhoneEnabledIcon className="detailImg" />,
      info: state.phone,
      type: "טלפון",
      // type: "phone",
      error: state.error && state.error.phone ? state.error.phone : "",
    },
    {
      img: <MailIcon className="detailImg" />,
      info: state.email,
      type: "מייל",
      // type: "email",
      error: state.error && state.error.email ? state.error.email : "",
    },
  ];

  return (
    <>
      <main className="detailsList">
        <section className="detailSquare">
          <div className="detailImgDiv">
            <div className="roundBox">
              <div className="innerRoundBox">
                <img
                  className="detailImg"
                  src={profile}
                  // src={Helper.apiBaseUrl + "uploads" + user.profileImage}
                />
              </div>
            </div>
          </div>
          <div className="detailTextDiv">
            <label id="detailInfo" className="detailInfo">
              תמונה
            </label>
            {trueFalseState == true && inputType == "file" ? (
              <>
                <div className="uploadBtnArea">
                  <Button
                    className="cancleBtn"
                    variant="contained"
                    component="span"
                    onClick={(e) => cancelBtn(e)}
                  >
                    Cancel
                  </Button>
                  <label htmlFor="contained-button-file">
                    <InputFile
                      accept=".jpg, .jpeg, .png"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => changeFile(e)}
                    />
                    <Button
                      className="uploadBtn"
                      variant="contained"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </>
            ) : (
              <button
                id="detailEdit"
                className="detailEdit"
                onClick={(e) => editImageClick(e, "file")}
              >
                ערוך
              </button>
            )}
          </div>
        </section>
        {containerArr.map((el, i) => {
          return (
            <DetailSquare
              img={el.img}
              info={el.info}
              type={el.type}
              error={el.error}
              key={i}
              editContactDetail={editContactDetail}
              trueFalseState={trueFalseState}
              onChangeValue={onChangeValue}
              state={state}
              cancelBtn={cancelBtn}
              inputType={inputType}
              updateContactDetail={updateContactDetail}
            />
          );
        })}
      </main>
    </>
  );
};

export default DetailsList;
