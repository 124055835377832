import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./../components/home/Home";
import First from "./../components/opening/First";
import Login from "./../components/opening/Login";
import Signup from "./../components/opening/Signup";
import Start from "./../components/opening/start/Start";

export default function LoginRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/first" element={<First />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/start" element={<Start />} />
      </Routes>
    </>
  );
}
