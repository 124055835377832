import { useDispatch } from "react-redux";
import AxiosApi from "../helpers/axios.api";

export function HospitalAction() {
  const dispatch = useDispatch();

  const getHospitalList = async () => {
    dispatch({ type: "SET_LOADER" });
    return await AxiosApi.withoutToken("vendor", "GET", []);
  };

  const getHospitalByID = async (id) => {
    dispatch({ type: "SET_LOADER" });
    return await AxiosApi.withoutToken(
      "vendor/" + id, 
      "GET",
      []
    );
  };

  return {
    getHospitalList,
    getHospitalByID,
  };
}
