import { Link } from "react-router-dom";

const HealthSquare = ({ img, textImg, header, text, btn, link }) => {
  return (
    <div className="openSquare analyzesSquare">
      <div className="imgAndText">
        <img className="openSquareImg" src={img} />
        <div className="openSquareText">
          <div className="openSquareTextH">{header}</div>
          <div className="openSquareTextP">{text}</div>
        </div>
      </div>
      <Link className="openSquareBtn" to={link}>
        {/* <img className="openSquareBtn" src={btn} /> */}
        הוספה
      </Link>
    </div>
  );
};

export default HealthSquare;
