import React, { useRef, useState } from "react";

import "./Hospitals.css";

import OpenRound from "./openRound/OpenRound";

import Filter from "./filter/Filter";

import filter_icon from "../../../assets/images/Filter.png";
import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import Search from "@mui/icons-material/Search";

import { Link } from "react-router-dom";

const Hospitals = () => {
  const [showfilter, setShowfilter] = useState(false);

  const [showdrp, setShowdrp] = useState(false);

  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const hospitalsArr = [
    {
      img: "/images/hospitals/hospital1.jpg",
      header: "המרכז לטיפול בהריון",
      link: "/web/page/hospitals/0",
    },
    {
      img: "/images/hospitals/hospital2.jpg",
      header: "מרכז רפואי סורוקה",
      link: "/hospital/1",
    },
    {
      img: "/images/hospitals/hospital3.jpg",
      header: "בית חולים וולפסון",
      link: "/hospital/2",
    },
    {
      img: "/images/hospitals/hospital4.jpg",
      header: "כללית שירותי בריאות",
      link: "/hospital/3",
    },
    {
      img: "/images/hospitals/hospital5.jpg",
      header: "בית חולים אסותא",
      link: "/hospital/4",
    },
    {
      img: "/images/hospitals/hospital6.jpg",
      header: "המרכז הרפואי סורסקי",
      link: "/hospital/5",
    },
  ];

  return (
    <>
      <div className="hospitalsPages">
        <div className="webHospitalsPage">
          <section className="search_sec" ref={messagesEndRef}>
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <img src={filterIcon} alt="filter" />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  <Search />
                </button>
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חפשי לפי שם"
                />
              </div>
            </div>
          </section>

          <div className="container filterContainer">
            <Filter
              showfilter={showfilter}
              setShowfilter={setShowfilter}
              scrollToTop={scrollToTop}
              scrollToBottom={scrollToBottom}
              showdrp={showdrp}
              setShowdrp={setShowdrp}
              showshortdrp={showshortdrp}
              setShowshortdrp={setShowshortdrp}
              messagesEndRef1={messagesEndRef1}
            />
          </div>

          <div className="hospitalsList">
            <div className="staffSquaresDiv">
              {hospitalsArr.map((el, i) => {
                return (
                  <OpenRound
                    key={i}
                    img={el.img}
                    header={el.header}
                    link={el.link}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hospitals;
