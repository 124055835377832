import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../helpers/api";
import Toast from "../helpers/toast";
import AxiosApi from "../helpers/axios.api";

export function DepartmentAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDepartments = async () => {
    dispatch({ type: "SET_LOADER" });
    return await AxiosApi.withoutToken(
      "vendor/departments/" + process.env.REACT_APP_VENDOR_ID,
      "GET",
      []
    );
  };
  return {
    getDepartments,
  };
}
