import React from "react";
import "./PostPage1.css";
import Footer from "../../../repeats/footer/Footer";
import Head from "../../../repeats/head/Head";

import OpenRound from "./openRound/OpenRound";
import imgDoctor from "../../../../assets/images/team1.png";

import Banner from "./banner/Banner";

const PostPage1 = () => {
  const Header = {
    header: `ד"ר חנה לוי`,
    text: "גינקולוגית",
    link: "/fordoctors",
  };
  return (
    <div className="PageDad">
      <div className="page rtl">
        <Head text="מידע בשביל תינוקות" />
        <div className="postPage_1">
          <Banner />
          {/* <div className="postImgDiv">
            <img className="postImgPhoto" src={baby} alt="baby" />
            <div className="postImgText">
              <h1 className="postImgH">
                איך לבנות לוח זמנים לשינה של התינוק שלך
              </h1>
            </div>
          </div> */}

          <div className="postPage_doctor">
            <OpenRound
              img={imgDoctor}
              header={Header.header}
              text={Header.text}
              link={Header.link}
            />
          </div>
          <div className="doctor_tips">
            <h6 className="tips_title">
              5 טיפים שיעזרו לאיכות השינה של התינוק שלך
            </h6>

            <p>
              <span className="tip_number">1. </span>
              כבי את המסכים לפחות שעתיים לפני השינה - זה כולל משחקים בטלפון
              הנייד, הודעות טקסט, גלישה באינטרנט... הרבה. למה? - האור הכחול
              מהמסכים הוא האשם הגרוע ביותר להרס שינה רגועה.
            </p>
            <p>
              <span className="tip_number">2. </span>
              שמרי על המיטה שלך כחלל שינה, לא כחלל עבודה. – האוסטאופת שלך ייתן
              לך 'מבט' בכל מקרה אם יגלה שעשית את כל המשימות, הדוחות והניהול שלך
              במחשב הנייד בזמן שישבת על המיטה שלך... לא טוב לעמוד השדרה שלך, לא
              טוב לשרירים הכואבים שלך, לא טוב לעיניים שלך, לא טוב לריכוז שלך...
              ולא טוב להירדם ברגע שאת חוזרת למיטה כדי לנסות לעצום עין. זה כולל
              צפייה בטלוויזיה במיטה.
            </p>
            <p>
              <span className="tip_number">3. </span>
              תשמרי על השגרה! - הגוף שלנו מתענג על השגרה, אנחנו יצור של מחזוריות
              - מחזורים הורמונליים, מחזורי לחץ דם, וכן, מחזורי שינה-ערות. קיום
              שגרה קבועה לפני השינה עוזר ליישר את המצב הפיזיולוגי שלך עם הרצון
              שלך לישון. זה יכול להיות פשוט כמו משקה חם מרגיע שעה לפני השינה;
              שגרת תנועה עדינה אם אתה נהנה מהיוגה או סוג אחר של תרגול; קריאה (לא
              על מסך!) למשך זמן מוגדר - הרשימה באמת אינסופית של מה שתוכלו לשלב
              בשגרת הערב (הקצרה או הארוכה) שלכם.
            </p>
            <p>
              <span className="tip_number">4. </span>
              תמכו במוח החושב שלכם לכבות - כוחן של רשימות. חיים עמוסים מיושרים
              עם מוחות עסוקים, ולמחשבות על דברים לעשות ולא לשכוח יש הרגל להילחם
              על תשומת הלב ולשינה מטרידה. נסה לשמור מחברת ליד המיטה שלך, ואולי
              כחלק משגרת ערב, נסה לכתוב רשימה של דברים שכדאי להחריג מדעתך,
              בנוחות שאחרי שכתבת אותם ברשימה שלך תהיה מסוגל לחזור אליהם בזמן
              ריאלי יותר. גם אם אתה מתעורר במהלך הלילה וחושב על עוד דבר דחוף,
              כתיבה זו יכולה לעזור לך לחזור לישון. (מדיטציה הוכחה גם כמשפרת את
              איכות השינה, מורידה מתח ומשפרת קוגניציה לאורך זמן - היא יכולה
              לעזור להפחית את ה"פטפוט הנפשי" הזה)
            </p>
            <p>
              <span className="tip_number">5. </span>
              האם מה שאתה אוכל עוזר או מפריע לך? - ככלל, ללא קשר לדרך האכילה
              שבחרת, מזונות מסוימים לא יעזרו בחיי השינה שלך. קפאין מפריע למחזור
              השינה הרגיל שלך, כלומר גם אם אתה חושב שאתה ישן שעות רגילות אחרי
              הקפה שלאחר הארוחה, לא תקבל את היתרונות של שנת לילה מלאה. קפאין
              אפילו שש שעות לפני השינה יכול לגרום לך לאבד עד שעה מאיכות השינה גם
              אם אתה ישן. הקפידו על עוצר קפאין, אני ממליץ להפסיק לשתות קפאין
              לפחות שמונה שעות לפני השינה. דברים נוספים שכדאי לחשוב עליהם הם
              מס"ג, כמו גם קבלת מספיק מה'טובים' כמו אשלגן, מגנזיום וכו'.
            </p>

            <p className="doctor_name">ד"ר חנה לוי, וולפסון</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PostPage1;
