import React from "react";
import "./ProductsDetails.css";

import sidebar from "../../../assets/images/22.png";
import our_product from "../../../assets/videos/ourDepartment/our_department.mp4";
import ProductsList from "./productsDetailsList/ProductsList";
import StarRate from "./StarRate";

import ProductsSwiper from "./productsSwiper/ProductsSwiper";

const ProductDetails = () => {
  return (
    <div className="product_details">
      <div className="container">
        <div className="main_content">
          {/* Products Swiper */}
          <ProductsSwiper />
          {/* Products Swiper */}
          <div className="product_description pt-1">
            <div className="py-4 description_title">על המוצר</div>
            <p className="description_text pb-2">
              מערכת נסיעות גמישה לתינוקות - המערכת היא שילוב של עגלה ומושב לרכב עם מקסימום בטיחות מושב בטיחות לתינוק הפונה לאחור ובסיס אזור בטוח הכולל מוט נגד ריבאונד מושקע באביזרים ידידותיים להורים ולילדים. <br/>
              רב תכליתי וקל משקל: שילוב מושב ועגלה לרכב זה כולל מסגרת מודולרית עם 6 מצבים. המצב ההפיך מאפשר לתינוקך לפנות פנימה או החוצה, בעוד שמכסות העגלה מתחמקים ממסגרת מושב תינוק למושב עגלה או מצב עגלה בקלות. <br/>
              העברת מושב בטיחות לתינוקות קלה: שילוב מושב ועגלה זה תוכנן עם בסיס להישאר ברכב המאפשר חיבור מהיר ובטוח של מושב בטיחות לתינוק מעגלה לרכב ולהיפך. 
            </p>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-lg-6 col-sm-12 rating_div">
              <div className="rating">
                <StarRate />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 pt-3">
              <div className="product_video_div">
                <video className="product_video col-12" controls>
                  <source src={our_product} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="product_image">
                <div className="product_header">הולך טוב עם</div>
                <p className="product_iamge_text">
                  מוצרים נוספים על בסיס ההעדפות שלך
                </p>
                <ProductsList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
