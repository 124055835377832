import React from "react";

import ClubSquare from "./ClubSquare";

const ClubSquareList = () => {
  const ClubListArr = [
    {
      club_image: "/images/club/club2.png",
      title: "קורס הכנה ללידה - מתנת לידה מוולפסון",
      link: "/service/1",
      club_img_key: 1,
    },
    {
      club_image: "/images/club/club1.png",
      title: "פגישה אישית עם המיילדת",
      link: "/service/2",
      club_img_key: 2,
    },
    {
      club_image: "/images/club/club4.png",
      title: "טיפול מתרפיסטית אלטרנטיבית (מסז', שיאצו)",
      link: "/service/3",
      club_img_key: 3,
    },
    {
      club_image: "/images/club/club3.png",
      title: "סדנת החיאה לתינוקות",
      link: "/service/4",
      club_img_key: 4,
    },
  ];

  return (
    <div className="list_box_area d-flex justify-content-between row gx-4">
      {ClubListArr.map((el, i) => {
        return (
          <ClubSquare
            key={i}
            club_image={el.club_image}
            title={el.title}
            link={el.link}
            club_img_key={el.club_img_key}
          />
        );
      })}
    </div>
  );
};

export default ClubSquareList;
