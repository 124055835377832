


import axios from 'axios'
axios.defaults.withCredentials = true

require('dotenv').config()
let apiBaseUrl = process.env.REACT_APP_API_URL

export default class Api {
  static apiBaseUrl = apiBaseUrl
  static t = () => {}
  static lang = 'en'

  static upload(
    action,
    data,
    beforeSendCallback,
    successCallback,
    errorCallback,
    finishCallback,
    withCredentials = true,
    userHeader,
  ) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .post(this.apiBaseUrl + action, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept-Language': this.lang,
          'Type': userHeader ? userHeader : '',
        },
        withCredentials
      })
      .then(response => this.success(response.data, successCallback, errorCallback))
      .catch(error => this.error(error, errorCallback))
      .then(() => this.finish(finishCallback))
  }

  static post(
    action,
    data,
    beforeSendCallback,
    successCallback,
    errorCallback,
    finishCallback,
    withCredentials = true
  ) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .post(this.apiBaseUrl + action, data, {
        withCredentials,
        headers: { 'Accept-Language': this.lang }
      })
      .then(response => {
        this.success(response.data, successCallback, errorCallback) 
      })
      .catch(error => this.error(error, errorCallback))
      .then(() => {
        this.finish(finishCallback)
      } )
  }

  static put(action, data, beforeSendCallback, successCallback, errorCallback, finishCallback, withCredentials = true) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .put(this.apiBaseUrl + action, data, {
        withCredentials,
        headers: { 'Accept-Language': this.lang }
      })
      .then(response => this.success(response.data, successCallback, errorCallback))
      .catch(error => this.error(error, errorCallback))
      .then(() => this.finish(finishCallback))
  }

  static patch(action, data, beforeSendCallback, successCallback, errorCallback, finishCallback, withCredentials = true) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .patch(this.apiBaseUrl + action, data, {
        withCredentials,
        headers: { 'Accept-Language': this.lang }
      })
      .then(response => this.success(response.data, successCallback, errorCallback))
      .catch(error => this.error(error, errorCallback))
      .then(() => this.finish(finishCallback))
  }

  static get(action, beforeSendCallback, successCallback, errorCallback, finishCallback, withCredentials = true) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .get(this.apiBaseUrl + action, {
        withCredentials,
        headers: { 'Accept-Language': this.lang }
      })
      .then(response => this.success(response.data, successCallback, errorCallback))
      .catch(error => this.error(error, errorCallback))
      .then(() => this.finish(finishCallback))
  }

  static delete(action, beforeSendCallback, successCallback, errorCallback, finishCallback, withCredentials = true) {
    if (typeof beforeSendCallback === 'function') {
      beforeSendCallback()
    }

    axios
      .delete(this.apiBaseUrl + action, {
        withCredentials,
        headers: { 'Accept-Language': this.lang }
      })
      .then(response => this.success(response.data, successCallback, errorCallback))
      .catch(error => this.error(error, errorCallback))
      .then(() => this.finish(finishCallback))
  }

  static success(data, successCallback, errorCallback) {
    if (data) {
      if (typeof successCallback === 'function') {
        successCallback(data)
      }
    } else {
      if (typeof errorCallback === 'function') {
        data.message = data.message ? data.message : this.t('Unknown error. Please contact support.')

        errorCallback(data)
      }
    }
  }

  static error(error, errorCallback) {
    if (typeof errorCallback === 'function') {
      errorCallback(error)
    }
  }

  static finish(finishCallback) {
    if (typeof finishCallback === 'function') {
      finishCallback()
    }
  }
}
