import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../components/home/Home";

import Page from "./../components/web/Page";

import MarketPlacePage from "./../components/marketPages/marketplace/MarketPlace";
import ClubPage from "./../components/marketPages/club/Club";
import VandorDetails from "./../components/marketPages/vandorDetails/VandorDetails";
import Products from "./../components/marketPages/products/Products";
import ProductDetails from "./../components/marketPages/productDetails/ProductDetails";
import ServiceDetails from "./../components/marketPages/serviceDetails/ServiceDetails";

import ChatBot from "./../components/chat/ChatBot";

import Pregnancy from "./../components/pregnancy/Pregnancy";
import MyBody from "./../components/pregnancy/mybody/MyBody";
import MyBaby from "./../components/pregnancy/mybaby/MyBaby";
import AddPhoto from "./../components/pregnancy/mybaby/add/AddPhoto";
import Fitness from "./../components/pregnancy/fitness/Fitness";
import Nutricion from "./../components/pregnancy/nutricion/Nutricion";
import Vitamins from "./../components/pregnancy/vitamins/Vitamins";

import HospitalPage from "./../components/hospitalPages/hospital/Hospital";
import Hospitals from "./../components/hospitalPages/hospitals/Hospitals";
import Gynecology from "./../components/hospitalPages/gynecology/Gynecology";
import Departments from "./../components/hospitalPages/departments/Departments";
import OurDepartment from "./../components/hospitalPages/ourDepartment/OurDepartment";
import DepartmentDetails from "./../components/hospitalPages/departmentDetails/DepartmentDetails";

import HospitalStay from "./../components/hospitalStay/HospitalStay";
import BirthPlan from "./../components/hospitalStay/birthplan/BirthPlan";
import Analyzes from "./../components/hospitalStay/analyzes/Analyzes";
import Procedures from "./../components/hospitalStay/procedures/Procedures";
import Report from "./../components/hospitalStay/report/Report";
import Patient from "./../components/hospitalStay/patient/Patient";
import Staff from "./../components/hospitalStay/patient/staff/Staff";
import ForDoctors from "./../components/hospitalStay/patient/staff/forDoctors/ForDoctors";
import ForNurse from "./../components/hospitalStay/patient/staff/forNurse/ForNurse";

import MyAppointment from "./../components/appointmentPages/myappointment/MyAppointment";
import AppointmentPage from "./../components/appointmentPages/appointment/Appointment";
import DoctorsPage from "./../components/appointmentPages/doctors/Doctors";
import Confirm from "./../components/appointmentPages/confirm/Confirm";

import DoctorPage from "./../components/doctorPages/doctor/Doctor";
import Review from "./../components/doctorPages/review/Review";
import Call from "./../components/doctorPages/call/Call";
import Video from "./../components/doctorPages/video/Video";

import Blog from "./../components/blogPages/blog/Blog";
import BlogPostList from "./../components/blogPages/blogPostList/BlogPostList";
import PostPage1 from "./../components/blogPages/post/PostPage1/PostPage1";
import PostPage2 from "./../components/blogPages/post/PostPage2/PostPage2";

import Bills from "./../components/pay/bills/Bills";
import AddCard from "./../components/pay/addCard/AddCard";
import PayNow from "./../components/pay/payNow/PayNow";
import PaymentSuccess from "./../components/pay/paymentSuccess/PaymentSuccess";

import Subscribe from "./../components/settingsPages/subscribe/Subscribe";
import Favorites from "./../components/settingsPages/favorites/Favorites";
import TellUs from "./../components/settingsPages/tellUs/TellUs";

// for working on user private page
import Account from "./../components/profile/account/Account";
import Contact from "./../components/profile/contact/Contact";
import Health from "./../components/profile/health/Health";
import Settings from "./../components/settingsPages/settings/Settings";
import SettingsNotifications from "./../components/settingsPages/settingsNotifications/SettingsNotifications";

import Notifications from "./../components/notificationsPages/notifications/Notifications";

import About from "./../components/about/About";

import MultipleLayout from "./../components/repeats/multipleLayout/multipleLayout";
import { useNavigate } from "react-router-dom";
import { isBrowser, isMobile } from 'react-device-detect';
import { useEffect } from "react";

export default function GeneralRoutes() {
  const navigate = useNavigate();
  useEffect(() => {

    navigate(isBrowser ? "/web/page/home" : isMobile ? "/" : "", { replace: true });
  }, [isBrowser, isMobile])

  return (
    <>
      <Routes>
        <Route path="/web">
          <Route path="page/*" element={<Page />} />
        </Route>

        {/* <Route path="/" element={<Home />} /> */}

        <Route
          path="/marketplace"
          element={<MultipleLayout componentName={<MarketPlacePage />} />}
        />
        <Route path="/club" element={<ClubPage />} />
        <Route path="/vandordetails" element={<VandorDetails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/productdetails" element={<ProductDetails />} />
        <Route path="/service/*" element={<ServiceDetails />} />

        <Route path="/chat" element={<ChatBot />} />

        <Route path="/pregnancy" element={<Pregnancy />} />
        <Route path="/mybody" element={<MyBody />} />
        <Route
          path="/mybaby"
          element={<MultipleLayout componentName={<MyBaby />} />}
        />
        <Route path="/mybaby/add" element={<AddPhoto />} />
        <Route path="/fitness" element={<Fitness />} />
        <Route path="/nutricion" element={<Nutricion />} />
        <Route path="/vitamins" element={<Vitamins />} />

        <Route path="/hospital/:id" element={<HospitalPage />} />
        <Route path="/gynecology" element={<Gynecology />} />
        <Route path="/departments/gynecology" element={<Gynecology />} />
        <Route path="/department/*" element={<DepartmentDetails />} />
        <Route path="/hospitals" element={<Hospitals />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/ourdepartment" element={<OurDepartment />} />

        <Route path="/hospitalstay" element={<HospitalStay />} />
        <Route path="/birthplan" element={<BirthPlan />} />
        <Route path="/analyzes" element={<Analyzes />} />
        <Route path="/procedures" element={<Procedures />} />
        <Route path="/report" element={<Report />} />
        <Route path="/patient" element={<Patient />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/fordoctors" element={<ForDoctors />} />
        <Route path="/fornurse" element={<ForNurse />} />

        <Route path="/myappointment" element={<MyAppointment />} />
        <Route
          path="/appointment/*"
          element={<MultipleLayout componentName={<AppointmentPage />} />}
        />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/doctors" element={<DoctorsPage />} />

        <Route
          path="/doctor/*"
          element={<MultipleLayout componentName={<DoctorPage />} />}
        />
        <Route path="/call" element={<Call />} />
        <Route path="/video" element={<Video />} />
        <Route path="/review" element={<Review />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blogpostlist" element={<BlogPostList />} />
        <Route path="/postpage1" element={<PostPage1 />} />
        <Route path="/postpage2" element={<PostPage2 />} />

        <Route path="/bills" element={<Bills />} />
        <Route path="/addcard" element={<AddCard />} />
        <Route path="/paynow" element={<PayNow />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />

        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/tellus" element={<TellUs />} />

        <Route
          path="/settings"
          element={<MultipleLayout componentName={<Settings />} />}
        />
        <Route
          path="/settingsnotifications"
          element={<SettingsNotifications />}
        />

        <Route path="/notifications" element={<Notifications />} />

        <Route path="/about" element={<About />} />

        {/* for working on user private page */}
        <Route path="/account" element={<Account />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/health" element={<Health />} />

      </Routes>
    </>
  );
}
