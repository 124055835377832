import './Add.css';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import {connect} from 'react-redux';
import {addAction, changeDateAction} from '../../../../redux/actions';
import Head from '../../../repeats/head/Head';
// import Dates from '../../../regularPages/repeatsComponents/datePicker/datePicker';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const AddPhoto = ({add, changeDate}) => {

	// useEffect(() => {
		// setPageInfo({header: 'הוספת תמונה', className: 'addPage'})
		// const datePicker = document.querySelector('.mbsc-ios.mbsc-datepicker-inline')
		// datePicker.style.border = 0
	// },[]);

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='הוספת פריט' />
				<div className='addPage'>
					<div className='textInputsDiv' >
						<input className='addHeader' type='text' placeholder='כותרת' />
						<input className='addDescription' type='text' placeholder='...תיאור' />
					</div>
					<div className='addDatePicker' >
						<h2 className='startH2 addDatePickerH' >בחרי תאריך</h2>
						<input onChange={changeDate} type='date' className='addDateInput' />
						{/*<Dates />*/}
					</div>
					<h1 className='addFilesHeader' >העלי את הקבצים שלך</h1>
					<div className='photoInput gallery' >
						<label htmlFor="image_uploads">בחרי תמונה מהגלריה שלך</label>
						<input type='file' className='imgInput galleryInput' id="image_uploads" />
						{/* <i className="fa fa-regular fa-image fas-10x"></i> */}
						<InsertPhotoIcon />
					</div>
					<div className='photoInput camera' >
						<label htmlFor="">השתמשי במצלמה</label>
						<input type='file' className='imgInput' id="image_uploads" />
						{/* <img className="camera2" src='/images/myBaby/add/camera3.png' /> */}
						<CameraAltIcon className="camera2" />
					</div>
					<div className='addSubmitDiv' >
						<Link to='/mybaby' className=''>
							<button onClick={add} className='addSubmit' >לאישור</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: () => dispatch(addAction()),
    changeDate: (e) => dispatch(changeDateAction(e.target.value)),
  }
}

export default connect(null, mapDispatchToProps)(AddPhoto) 
