const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGOUT":
      return {
        ...state,
        id: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        profileImage: "",
        emergencyNumbers: [],
        beginningOfPregnancy: "",
        vendorId: "",
        confirmed: false,
        validateCode: "",
        codeWillExpire: "",
      };
    case "USER_LOGIN":
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        email: action.payload.email,
        profileImage: action.payload.profileImage,
        emergencyNumbers: action.payload.emergencyNumbers,
        beginningOfPregnancy: action.payload.beginningOfPregnancy,
        vendorId: action.payload.vendorId,
        confirmed: action.payload.confirmed,
        validateCode: action.payload.validateCode,
        codeWillExpire: action.payload.codeWillExpire,
      };

    case "SET_USER":
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        email: action.payload.email,
        profileImage: action.payload.profileImage,
        emergencyNumbers: action.payload.emergencyNumbers,
        beginningOfPregnancy: action.payload.beginningOfPregnancy,
        vendorId: action.payload.vendorId,
        confirmed: action.payload.confirmed,
        validateCode: action.payload.validateCode,
        codeWillExpire: action.payload.codeWillExpire,
      };
    // case "UPDATE_PROFILE_IMAGE": 
    //   return {
    //     ...state,
    //     profileImage: action.payload.profileImage,
    //   }
    default:
      return state;
  }
};

export default user;
