import React from "react";
import { Link } from "react-router-dom";
import three_dots from "../../../assets/images/three_dots.svg";
import hospital1 from "../../../assets/images/hospital/hospital1.jpg";
import Helper from "../../../helpers/api";

const Banner = (props) => {
  return (
    <>
      <div className="hospital_banner">
        <img
          // src={Helper.apiBaseUrl + "uploads" + props.hospital.posterImage}
          src={hospital1}
          alt="hospital"
          className="hospital_banner_img"
        />
        <Link to={"#"} className="hospital_banner_three_dots">
          <img
            src={three_dots}
            className="hospital_banner_three_dots_img"
            alt="three_dots"
          />
        </Link>
        <div className="hospital_banner_content">
          <h1 className="w-100">
            המרכז לטיפול בהריון
            {/* {props.hospital.title} */}
          </h1>
          <h6>
            חולון, הלוחמים 62
            {/* {props.hospital.address} */}
          </h6>
        </div>
      </div>
    </>
  );
};

export default Banner;
