const initialState = {
  loader : false
}

const loader = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_LOADER':
          return {
              ...state,
              loader: true
              
          }
      case 'UNSET_LOADER':
          return {
              ...state,
              loader: false
              
          }
      default:
          return state;
  }
};

export default loader;
