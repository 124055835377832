import React from "react";

import { Link } from "react-router-dom";

const ProductsSquare = ({
  product_img,
  heart_img,
  title,
  detail,
  price,
  link,
  product_img_key,
}) => {
  return (
    <>
      <div className="list_box col-6 pb-5">
        <Link to={"" + link + ""}>
          <div className="box_img">
            <img src={product_img} alt="service" />
            {product_img_key === 1 ? (
              <>
                <div className="time_dt d-flex justify-content-center">
                  <p className="off15">
                    <strong>35% הנחה</strong>
                    <span>03:25:41</span>
                  </p>
                </div>
              </>
            ) : product_img_key === 2 ? (
              <>
                <div className="time_dt d-flex justify-content-center dark">
                  <p>
                    <strong>10$-</strong>
                  </p>
                </div>
              </>
            ) : product_img_key === 3 ? (
              <>
                <div className="time_dt d-flex justify-content-center">
                  <p className="off15">
                    <strong>35% הנחה</strong>
                    <span>03:25:41</span>
                  </p>
                </div>
              </>
            ) : product_img_key === 4 ? (
              <></>
            ) : (
              ""
            )}
          </div>
        </Link>
        <div className="box_detail d-flex justify-content-between">
          <div className="product_title">
            <p>{title}</p>
            <h6>{detail}</h6>
          </div>
          <span>{price}</span>
        </div>
        <Link to={"#"} className="wish_list_icon">
          <img src={heart_img.heart} alt="like" />
        </Link>
      </div>
    </>
  );
};

export default ProductsSquare;
