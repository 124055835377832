import React, { useRef, useState, useEffect } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import "./Doctors.css";
import OpenRound from "../../regularPages/repeatsComponents/opensquare/OpenRound";
import Filter from "./filter/Filter";
import { useSelector } from "react-redux";
import { UserAction } from "../../../actions/user";

import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import SearchIcon from "@mui/icons-material/Search";

const Doctors = () => {
  const doctor = useSelector((state) => state.doctor);
  
  const userAction = UserAction();

  const [showfilter, setShowfilter] = useState(false);

  const [showdrp, setShowdrp] = useState(false);

  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    userAction.getDoctorsList();
  }, []);

  const doctorsArr = [
    {
      img: "/images/doctors/doctor1.jpg",
      header: 'ד"ר דניאל כהן',
      text: "גינקולוג",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor2.jpg",
      header: 'ד"ר יעל כהן',
      text: "גינקולוגית",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor3.jpg",
      header: 'ד"ר איתי ערד',
      text: "רופא מילד",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor4.jpg",
      header: 'ד"ר חיה אזולאי',
      text: "גינקולוגית",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor5.jpg",
      header: 'ד"ר אבי גינזבורג',
      text: "גינקולוג",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor6.jpg",
      header: 'ד"ר הלן מאיר',
      text: "רופאה מילדת",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor7.jpg",
      header: 'ד"ר מאיר ברנר',
      text: "קרדיולוג",
      link: "/fordoctors",
    },
    {
      img: "/images/doctors/doctor8.jpg",
      header: 'ד"ר סיון דוידוף',
      text: "רופאה מילדת",
      link: "/fordoctors",
    },
  ];

  return (
    <>
      <div className="PageDad">
        <div className="page">
          <Head text="רופאים" />
          <div className="doctorsPage">
            <div className="SearchDiv" ref={messagesEndRef}>
              <div className="Search">
                <SearchIcon className="SearchImg" />
                {/* <img className="SearchImg" src={filterIcon} /> */}
                <input
                  placeholder="חפשי לפי שם"
                  type="search"
                  className="SearchInput"
                />
              </div>
              <div 
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <img src={filterIcon} />
              </div>
            </div>

            <Filter
              showfilter={showfilter}
              setShowfilter={setShowfilter}
              scrollToTop={scrollToTop}
              scrollToBottom={scrollToBottom}
              showdrp={showdrp}
              setShowdrp={setShowdrp}
              showshortdrp={showshortdrp}
              setShowshortdrp={setShowshortdrp}
              messagesEndRef1={messagesEndRef1}
            />

            <div className="staffSquaresDiv">
              {/* {doctor.data.map((el, i) => { */}
              {doctorsArr.map((el, i) => {
                return (
                  <OpenRound
                    key={i}
                    profileImage={el.img}
                    firstName={el.header}
                    position={el.text}
                    rating={el.rating}
                    review={el.review}
                    // profileImage={el.profileImage}
                    // firstName={el.firstName}
                    // lastName={el.lastName}
                    // position={el.position}
                    // rating={el.rating}
                    // review={el.review}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Doctors;
