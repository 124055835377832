import React from "react";
import { Link } from "react-router-dom";
import three_dots from "../../../assets/images/three_dots.svg";

const ServiceBanner = () => {
  return (
    <div className="banner">
      <div className="banner_content">
        <h1 className="w-100">המרכז לטיפול בהריון</h1>
      </div>
    </div>
  );
};

export default ServiceBanner;
