import './Report.css';
import Search from '../../regularPages/repeatsComponents/search/Search'
import DayPicker from '../../regularPages/repeatsComponents/daypicker/DayPicker'
import ReportSquare from './ReportSquare'
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';

import StarRoundIcon from '@mui/icons-material/Star';

const Report = () => {

	const reportSquareArr = [
		{
			header: 'בדיקת רופאה',
			text: 'בדיקת היסטוריה רפואית',
			hour: '08:00',
		},
		{
			header: 'מתן תרופות וויטמנים',
			text: 'ויטמין D, אומגה',
			hour: '08:30',
		},
		{
			header: 'עירוי',
			text: 'גלוקוז',
			hour: '11:00',
		},
		{
			header: 'תוצאות בדיקה',
			text: 'ספירת דם, ברזל',
			hour: '16:00',
		},
		{
			header: 'סיבוב ערב',
			text: 'בדיקה',
			hour: '18:00',
		},
		{
			header: 'זריקות',
			text: 'במידת הצורך',
			hour: '20:30',
		},
	]

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='דיווח יומי' />
				<div className='procedursPage reoprtPage'>
					<Search icon={<StarRoundIcon className="searchStar" />} />
					<DayPicker />
					<div className='procedursSquaresDiv'>
						{
							reportSquareArr.map((el, i) => {
								return (
									<ReportSquare
										header={el.header}
										text={el.text}
										hour={el.hour}
										i={i}
										key={i} 
									/> 
								)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>

	)
}

export default Report