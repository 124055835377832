import { Link } from "react-router-dom";
import MenuSearchItem from "./MenuSearchItem";

const MenuSearchSection = ({ header, SearchLink, section }) => {
  return (
    <div className="menuSearchSection">
      <Link to={SearchLink} className="menuSearchSectionLink">
        {header}
      </Link>
      <div className="MenuSearchItemsDiv">
        {section.map((el, i) => {
          return (
            <MenuSearchItem
              itemLink={el.itemLink}
              img={el.img}
              text={el.text}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MenuSearchSection;
