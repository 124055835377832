import React from "react";
import { Link } from "react-router-dom";
import star from "../../../../assets/images/star.svg";
import Helper from "../../../../helpers/api";

const HospitalSqaure = (props) => {
  console.log(props);
  return (
    <>
      <Link to={`/hospital/${props.id ? props.id : ""}`}>
        <input type={"hidden"} value={props.id ? props.id : ""} />
        <div className="hospital_list_box row-reverse">
          <div className="box_con">
            <div className="box_heading align-center justify-between row-reverse">
              <h4>{props.title}</h4>
              <p>Beer Sheva, Trinity Rd, 208</p>
              <div className="box_details">
                <img src={star} alt="star" /> <span>4.9 (1474 ביקורות)</span>
              </div>
            </div>
          </div>
          <div className="box_img">
            <img
              src={
                true
                  ? props.image
                  // Helper.apiBaseUrl + "uploads" + props.image
                  : "/images/noImage/noImage.png"
              }
              alt="Online"
            />
          </div>
        </div>
      </Link>
    </>
  );
};

export default HospitalSqaure;
