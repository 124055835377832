import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../helpers/api";
import Toast from "../helpers/toast";
import AxiosApi from "../helpers/axios.api";
import { isBrowser, isMobile } from "react-device-detect";

export function UserAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signIn = (data) => {
    Api.post(
      "user/login",
      data,
      () => {
        dispatch({ type: "SET_LOADER" });
      },
      (response) => {
        if (response.success) {
          Toast.success("Success login");
          dispatch({ type: "USER_LOGIN", payload: response.patient });
          navigate(isBrowser ? "/web/page/account" : isMobile ? "/account" : "", { replace: true });
        } else {
          Toast.error(response.message);
        }
      },
      (error) => {
        if (!error.response.data.success) {
          Toast.error(error.response.data.message);
        } else {
          Toast.error(error.message);
        }
      },
      () => {
        dispatch({ type: "UNSET_LOADER" });
      }
    );
  };

  const signUp = (data) => {
    Api.post(
      "user/registration",
      data,
      () => {
        dispatch({ type: "SET_LOADER" });
      },
      (response) => {
        if (
          response &&
          response.message === "The user with this email is already registered"
        ) {
          Toast.error(response.message);
        } else {
          Toast.success(response.message);
          navigate("/login", { replace: true });
        }
      },
      (error) => {
        Toast.error(error.message);
      },
      () => {
        dispatch({ type: "UNSET_LOADER" });
      }
    );
  };

  const getUser = () => {
    Api.get(
      "user/me",
      () => {
        dispatch({ type: "SET_LOADER" });
      },
      (response) => {
        dispatch({ type: "SET_USER", payload: response.user });
      },
      null,
      () => {
        dispatch({ type: "UNSET_LOADER" });
      }
    );
  };

  const updateUser = (data) => {
    Api.patch(
      "user/me",
      data,
      () => {
        dispatch({ type: "SET_LOADER" });
      },
      (response) => {
        if (response && response.status === false) {
          Toast.error(response.message);
        } else {
          dispatch({ type: "SET_USER", payload: response.user });
          Toast.success("User profile updated.");
        }
      },
      (error) => {
        if (!error.response.data.success) {
          Toast.error(error.response.data.message);
        } else {
          Toast.error(error.message);
        }
      },
      () => {
        dispatch({ type: "UNSET_LOADER" });
      }
    );
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const header = {};
    header.type = "user";
    return await AxiosApi.withoutToken(
      "media/upload",
      "POST",
      formData,
      header
    );
  };

  const getPregnancy = async () => {
    dispatch({ type: "SET_LOADER" });
    return await AxiosApi.withoutToken("user/pregnancy", "GET", []);
  };

  const getDoctorsList = () => {
    Api.get(
      "user",
      () => {
        dispatch({ type: "SET_LOADER" });
      },
      (response) => {
        let data = {};
        data.data = response;
        dispatch({ type: "DOCTOR_LIST", payload: data });
      },
      null,
      () => {
        dispatch({ type: "UNSET_LOADER" });
      }
    );
  };

  const logout = () => {
    dispatch({ type: "USER_LOGOUT" });
  };

  return {
    signIn,
    signUp,
    getUser,
    updateUser,
    uploadImage,
    getPregnancy,
    getDoctorsList,
    logout,
  };
}
