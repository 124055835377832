import React, { Component } from "react";
import Icon from './Icon'

import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

const IconList = () => {

	const IconListArr = [
		{
			link: '/web/page/account',
			icon: <PersonIcon className='navIcon' />
		},
		{
			link: '/web/page/notifications',
			icon: <NotificationsIcon className='navIcon' />
		},
		{
			link: '/web/page/like',
			icon: <FavoriteIcon className='navIcon' />
		},
		{
			link: '/web/page/marketplace',
			icon: <LocalGroceryStoreIcon className='navIcon' />
		},
	]

	return (
		<div className='navBtnsList'>
			{
				IconListArr.map((el, i) => {
					return (
						<Icon
							link={el.link}
							icon={el.icon}
							key={i}
						/>
					)
				})
			}
		</div>
	)
}

export default IconList