const MyBabyDate = ({ num, day, month }) => {
  const className = "MyBabyDate MyBabyDate" + num;

  return (
    <div className={className}>
      <div className="MyBabyDateDay">{day}</div>
      <div className="MyBabyDateMonth">{month}</div>
      <div className="MyBabyDateLine">|</div>
    </div>
  );
};

export default MyBabyDate;
