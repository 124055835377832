import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer';
import { ToastContainer } from 'react-toastify';
// import thunk from 'redux-thunk';

import 'bootstrap/dist/css/bootstrap.min.css';

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer)
const persistor = persistStore(store)

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
