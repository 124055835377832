import "./BlogPostList.css";
import Banner from "./banner/Banner";
import TabBtn from "../../regularPages/repeatsComponents/tabsMenu/TabBtn";
import PostList from "./postList/PostList";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

const BlogPostList = () => {
  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "הנקה",
    },
    {
      text: "שינה",
    },
    {
      text: "אבחונים",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="מידע בשביל התינוק" />
        <div className="blogPostList">
          <Banner />
          <div className="blogPostListMain">
            <ul id="tabs-nav" className="tabsMenu list-unstyled d-flex">
              {tabsBtnsArr.map((el, i) => {
                return <TabBtn active={active} text={el.text} key={i} i={i} />;
              })}
            </ul>
            <PostList />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPostList;
