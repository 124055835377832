import ProductSquare from "./ProductSquare";

const ProductsList = () => {
  const productsListArr = [
    {
      img: "/images/web/productList/preparation.jpg",
      off: "45%",
      header: "קורס הכנה ללידה",
      price: "115$",
      link: "/web/page/productdetails",
      image_key: 2,
    },
    {
      img: "/images/web/productList/yoga85.jpg",
      off: "",
      header: "קורס יוגה לנשים בהריון",
      price: "85$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/consultation.jpg",
      off: "",
      header: "יעוץ לנשים בהריון",
      price: "40$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/nutrition.jpg",
      off: "",
      header: "תכנית תזונה לנשים בהריון",
      price: "145$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/carriage.jpg",
      off: "",
      header: "עגלת תינוק",
      price: "315$",
      link: "/web/page/productdetails",
      image_key: 1,
    },
    {
      img: "/images/web/productList/yoga90.jpg",
      off: "",
      header: "קורס יוגה לנשים בהריון",
      price: "90$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/management.jpg",
      off: "",
      header: "ניהול הריון",
      price: "600$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/pierre.jpg",
      off: "",
      header: "פייר קארדן",
      price: "600$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/fox.jpg",
      off: "",
      header: "מוצרים לבית",
      price: "15$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/suit.jpg",
      off: "",
      header: "חליפות",
      price: "600$",
      link: "/web/page/productdetails",
      image_key: 0,
    },
    {
      img: "/images/web/productList/ring.jpg",
      off: "",
      header: "גלגל ים",
      price: "15$",
      link: "/web/page/productdetails",
      image_key: 0, 
    },
    {
      img: "/images/web/productList/suit.jpg",
      off: "",
      header: "חליפות",
      price: "600$",
      link: "/web/page/productdetails",
      image_key: 0, 
    },
  ];

  return (
    <div className="productsList">
      <div className="productDetailSquareContainer">
        {productsListArr.map((el, i) => {
          return (
            <ProductSquare key={i}
              img={el.img}
              // off={el.off}
              header={el.header}
              price={el.price}
              link={el.link}
              image_key={el.image_key}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductsList