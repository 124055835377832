import React, { useState } from "react";
import Head from "../../repeats/head/Head";
import DoctorList from "../../regularPages/doctorList/DoctorList";
import ThisWeek from "./ThisWeek";
import MeetBtn from "./MeetBtn";

import "./../../../assets/css/screen.css";
import "./Appointment.css";

import ShapIcon from "../../../assets/images/Shape.png";
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckCircleIcon from "../../../assets/images/Checkcircle.png";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function Appointment() {
  const [startDate, setStartDate] = useState(new Date());

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const [timeCalendarIsOpen, setTimeCalendarIsOpen] = useState(false);

  return (
    <>
      <div className="PageDad">
        <div className="appointment_pages">
          <MobileView className="mv">
            <Head text="קביעת פגישה" isBackArrow={true} />
          </MobileView>
          <div className="searchbox_container">
            <div className="searchbox_heading">
              <KeyboardArrowDownIcon
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              />
              <h2>בחרי רופא</h2>
            </div>

            <div className="searchbox">
              <input
                id="search"
                type="search"
                placeholder="חפשי לפי שם או התמחות"
              />
              <SearchIcon className='SearchImg' />
            </div>
          </div>

          <DoctorList />

          <div className="dateselector_container">
            <div className="dateselector_heading">
              <KeyboardArrowDownIcon
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              />
              <h2>בחרי תאריך</h2>
            </div>
            <div
              className="dateselector"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                id="dateselector"
                placeholderText=" Open calendar"
                open={calendarIsOpen}
                onClickOutside={() => setCalendarIsOpen(!calendarIsOpen)}
              />

              {/* <img src={ShapIcon} alt="shap" /> */}
              <DateRangeIcon />
            </div>
          </div>

          <ThisWeek />

          <div className="timeselector_container">
            <div className="timeselector_heading">
              <KeyboardArrowDownIcon
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              />
              <h2>בחרי שעה</h2>
            </div>
            <div
              className="timeselector"
              onClick={() => setTimeCalendarIsOpen(!timeCalendarIsOpen)}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                open={timeCalendarIsOpen}
                onClickOutside={() =>
                  setTimeCalendarIsOpen(!timeCalendarIsOpen)
                }
              />
              <QueryBuilderIcon />
              {/* <img src={CheckCircleIcon} alt="circle" /> */}
            </div>
          </div>

          <div className="timeselectoroption_container">
            <MeetBtn text="פגישה" />
            <MeetBtn text="שיחת וידאו" />
            <MeetBtn text="שיחת טלפון" />
          </div>
          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link to="/confirm" className="go_btn">
                  שלחי
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appointment;
