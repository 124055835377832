import Star from './Star'

const FiveStar = () => {

	let starsArr = [
		{
			checked: 'checked',
			privetClassName: 'review_star0'
		},
		{
			checked: 'checked',
			privetClassName: 'review_star1'
		},
		{
			checked: 'checked',
			privetClassName: 'review_star2'
		},
		{
			checked: 'checked',
			privetClassName: 'review_star3'
		},
		{
			checked: 'noChecekd',
			privetClassName: 'review_star4'
		},
	]

	const rating = (e) => {
		const classList = e.target.classList
		const privetClassName = classList[classList.length-5]
		const rate = privetClassName[privetClassName.length-1]
		const allStars = document.querySelectorAll('.review_star')

		for (let i = 0; i < allStars.length; i++) {
		  if (i > rate) {
		  	allStars[i].classList.remove('checked')
		  	allStars[i].classList.add('noChecked')
		  } else {
		  	allStars[i].classList.remove('noChecked')
		  	allStars[i].classList.add('checked')
		  }
		}

	}

	return (
		<>
			{
				starsArr.map((el, i) => {
					return (
						<Star 
							checked={el.checked}
							privetClassName={el.privetClassName} 
							rating={rating}
							key={i}
						/> 
					)
				})
			}
		</>
	)
}

export default FiveStar