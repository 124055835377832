import { Link } from "react-router-dom"

const BackBtn = () => {
	return (
		<Link to='/' className='backBtnDiv'>
			<img className='backBtnImg' src='/images/video/back_button.png' alt='back' />
		</Link>
	)
}

export default BackBtn