import React from "react";

import "./Banner.css";

import gift from "../../../../assets/images/vandorDetails/gift.jfif";
import { Button } from "@mui/material";

const Banner = () => {
  return (
    <div className="vandor_details_banner_div">
      <div className="vandor_details_banner_img">
        <div className="vandor_details_banner_content">
          <img src={gift} className="banner_content_gift" alt="gift" />
          <div className="banner_content_div">
            <span className="banner_content_span_1">פוט לוקר</span>
            <span className="banner_content_span_2">חנות נעליים</span>
          </div>
          <Button className="banner_content_btn">עקוב</Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
