import React, { useState, useEffect } from "react";
import HospitalSqaure from "./HospitalSqaure";
import { useDispatch } from "react-redux";
import { HospitalAction } from "../../../../actions/hospital";
import Toast from "../../../../helpers/toast";
import star from "../../../../assets/images/star.svg";

import hospital from "../../../../assets/images/hospitals/hospital1.jpg"

const HospitalSqaureList = () => {
  const dispatch = useDispatch();

  // const hospitalAction = HospitalAction();

  // const [hospitals, setHospitals] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     //This is to get service list
  //     await hospitalAction
  //       .getHospitalList()
  //       .then((response) => {
  //         dispatch({ type: "UNSET_LOADER" });

  //         if (response) {
  //           if (response.success == true) {
  //             setHospitals(response.vendors);
  //           } else {
  //             Toast.error(response.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         Toast.error(error.message);
  //       });
  //   })();
  // }, []);

  const HospitalListArr = [
    {
      image: hospital,
      h4_text: "המרכז לטיפול בהריון",
      p_text: "חולון, הלוחמים 62",
      span_text: "4.9 (1474 ביקורות)",
      // star_image: { star },
      link: "/hospital",
    },
    {
      image: "/images/hospitals/hospital2.jpg",
      h4_text: "בית חולים וולפסון",
      p_text: "חולון הלוחמים 62",
      span_text: "4.6 (1192 ביקורות)",
      // star_image: { star },
      link: "/hospital",
    },
    {
      image: "/images/hospitals/hospital3.jpg",
      h4_text: "בית חולים לנשים",
      p_text: "באר שבע, שדרות רגר 40",
      span_text: "4.7 (1075 ביקורות)",
      // star_image: { star },
      link: "/hospital",
    },
  ];

  return (
    <div className="hospital_list_box_area">
      {HospitalListArr.length > 0
        ? HospitalListArr.slice(0, 2).map((el, i) => {
            return (
              <HospitalSqaure
                // id={el.id}
                // image={el.posterImage}
                // title={el.title}
                image={el.image}
                title={el.h4_text}
                key={i}
              />
            );
          })
        : ""}
    </div>
  );
};

export default HospitalSqaureList;
