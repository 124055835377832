import React from "react";
import './DoctorReview.css'

import FiveStar from "./FiveStar";

const DoctorReview = (props) => {

  const data = props.data;

  return (
    <>
      <div className="doctor_img">
        <div className='doctorSquare'>
          <div className='imgAndText'>
            <img className='doctorSquareImg' src={data.image} alt='aa' />
            <div className='doctorSquareText' >
              <h1 className='doctorSquareTextH' >{data.header}</h1>
              <div className='doctorSquareTextP' >{data.text}</div>
            </div>
          </div>
          <button className="doctor-btn">כל הביקורות</button>
        </div>
      </div>
      <hr className="new1" />
      <div className="review_section">
        <h2>
          {data.r_header}
        </h2>
        <p className="review_section_text">{data.r_text}</p>
        <FiveStar />
        <div className="text_div">
          <textarea className="review_section_input" placeholder="...כתבי משהו על הייעוץ שקיבלת " name="review_section_input" rows="4" cols="50"></textarea>
        </div>
      </div>
    </>
  );
};

export default DoctorReview;
