import PregnancySquare from '../regularPages/repeatsComponents/pregnancySquare/PregnancySquare'
import Head from '../repeats/head/Head';
import Footer from '../repeats/footer/Footer';

const HospitalStay = () => {

	const hospitalStayArr = [
		{
			img: '/images/hospitalstay/birth.jpg',
			text: 'תוכנית לידה',
			link: '/birthplan',
		},
		{
			img: '/images/hospitalstay/myanalyzes.jpg',
			text: 'האבחונים שלי',
			link: '/analyzes',
		},
		{
			img: '/images/hospitalstay/myprocedures.jpg',
			text: 'הפרוצדורות שלי',
			link: '/procedures',
		},
		{
			img: '/images/hospitalstay/patient.jpg',
			text: 'מידע על המטופל',
			link: '/patient',
		},
		{
			img: '/images/hospitalstay/report.jpg',
			text: 'דיווח יומי',
			link: '/report',
		},
	]

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='אשפוז' />
				<main className='pregnancyPage' >
					{
						hospitalStayArr.map((el, i) => {
							return (
								<PregnancySquare img={el.img} text={el.text} link={el.link} key={i} />
							)
						})
					}
				</main>
			</div>
			<Footer />
		</div>
	)
}

export default HospitalStay