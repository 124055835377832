import { Link } from "react-router-dom";

const MenuSearchItem = ({ img, text, itemLink }) => {
  return (
    <Link to={itemLink} className="menuSearchItem">
      <img className="menuSearchItemImg" src={img} />
      <div className="menuSearchItemText">{text}</div>
    </Link>
  );
};

export default MenuSearchItem;
