import React from "react";
import { useSelector } from "react-redux";
import GeneralRoutes from "./GeneralRoutes";
import LoginRoutes from "./LoginRoutes";
import UserPrivateRoutes from "./UserPrivateRoutes";
import Toast from "../helpers/toast";
import { useNavigate } from "react-router-dom";
import { isBrowser, isMobile } from 'react-device-detect';

export default function MainRoutes() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  // navigate(isBrowser ? "/web/page/home" : isMobile ? "/" : "", { replace: false });

  return (
    <>
      <div>
        {user.id ? <UserPrivateRoutes /> : <LoginRoutes />}
        <GeneralRoutes />
      </div>
      {user.id &&
        (window.location.pathname == "/account" ||
          window.location.pathname == "/contact" ||
          window.location.pathname == "/health" ||
          window.location.pathname == "/settings" ||
          window.location.pathname == "/settingsnotifications" ||
          window.location.pathname == "/web/page/account")
        ? navigate("/login", Toast.error("Please Login First"))
        : ""}
    </>
  );
}
