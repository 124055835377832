import React from "react";

import Footer from "../../repeats/footer/Footer";
import Head from "../../repeats/head/Head";

import "./MyAppointment.css";

import img_1 from "../../../assets/images/appointments_4.jpg";
import img_2 from "../../../assets/images/appointments_5.jpg";
import img_3 from "../../../assets/images/appointments_6.jpg";
import img_4 from "../../../assets/images/appointments_7.jpg";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import MyAppointmentSquareList from "./MyAppointmentSquare/MyAppointmentSquareList";

const MyAppointment = () => {
  const [alignment, setAlignment] = React.useState("appointed");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const appointedArr = [
    {
      image: "/images/myAppointment/appointments_4.jpg",
      h6_text_1: "ייעוץ אונליין",
      h6_text_2: 'ד"ר חנה לוי',
      image_p_text: "05:25:23",
      p_text: "04.04.2022 12:00 pm",
      link: "",
    },
    {
      image: "/images/myAppointment/appointments_5.jpg",
      h6_text_1: "",
      h6_text_2: "מסירת בדיקות. שליש שני.",
      image_p_text: "17:25:23",
      p_text: "04.04.2022 at 12:00 pm",
      link: "",
    },
  ];

  const pastArr = [
    {
      image: "/images/myAppointment/appointments_6.jpg",
      h6_text_1: "פגישה ראשונה, אולטרסאונד",
      h6_text_2: 'ד"ר חנה לוי',
      image_p_text: "ביקורת",
      p_text: "12.03.2022 11:00 am",
      link: "/review",
    },
    {
      image: "/images/myAppointment/appointments_7.jpg",
      h6_text_1: "",
      h6_text_2: "בדיקת תכנון הריון",
      image_p_text: "ביקורת",
      p_text: "07.02.2022 at 10:00 am",
      link: "/review",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page rtl">
        <Head text="הפגישות שלי" />
        <div className="myappointment_page">
          <div className="tab">
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton
                className={
                  alignment === "appointed"
                    ? "appoint-btn active"
                    : "appoint-btn"
                }
                value="appointed"
              >
                פגישות
              </ToggleButton>
              <ToggleButton
                className={
                  alignment === "past" ? "past-btn active" : "past-btn"
                }
                value="past"
              >
                פגישות עבר
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <hr className="new1" />

          {alignment === "appointed" ? (
            <section className="image_section">
              {appointedArr.map((el, i) => {
                return (
                  <MyAppointmentSquareList
                    key={i}
                    image={el.image}
                    h6_text_1={el.h6_text_1}
                    h6_text_2={el.h6_text_2}
                    image_p_text={el.image_p_text}
                    p_text={el.p_text}
                    link={el.link}
                  />
                );
              })}
            </section>
          ) : (
            <section className="image_section">
              {pastArr.map((el, i) => {
                return (
                  <MyAppointmentSquareList
                    key={i}
                    image={el.image}
                    h6_text_1={el.h6_text_1}
                    h6_text_2={el.h6_text_2}
                    image_p_text={el.image_p_text}
                    p_text={el.p_text}
                    link={el.link}
                  />
                );
              })}
            </section>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyAppointment;
