import React from "react";
import { Container } from "react-bootstrap";
import DepartmentSqaure from "./DepartmentSqaure";

const SqaureList = () => {
  const sqaureListArr = [
    {
      img: "/images/web/department/department1.jpg",
      title: "המחלקה שלנו",
      link: "/web/page/aboutdepartment",
    },
    {
      img: "/images/web/department/department2.jpg",
      title: "החדשות שלנו",
      link: "#",
    },
    {
      img: "/images/web/department/department3.jpg",
      title: "ייעוץ מקצועי",
      link: "#",
    },
  ];

  return (
    <>
      <div className="sqaureList mt-5">
        {/* <Container className="d-flex justify-content-center"> */}
          {sqaureListArr.map((el, i) => {
            return (
              <>
                <DepartmentSqaure
                  img={el.img}
                  title={el.title}
                  link={el.link}
                  key={i}
                />
              </>
            );
          })}
        {/* </Container> */}
      </div>
    </>
  );
};

export default SqaureList;
