import { useState } from "react";
import { Link } from "react-router-dom";
import { validateAll } from "indicative/validator";
import {
  Form,
  Input,
  FormFeedback,
  InputGroup,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import { useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { DoubleArrow } from "@material-ui/icons";
import { Person } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LanguageIcon from "@mui/icons-material/Language";

import { UserAction } from "../../actions/user";

import Loader from "../repeats/loader/Loader";

import Nav from "../web/nav/Nav";

import webLoginBg from "../../assets/images/opening/webLoginBg.jpg";

import "./Opening.css";

const Signup = () => {
  let navigate = useNavigate();

  const loader = useSelector((state) => state.loader);

  const [visiblePassword, setVisiblePassword] = useState(false);

  const userAction = UserAction();

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    business_url: "",
    password: "",
    error: [],
  });
  const { firstName, lastName, email, password, error } = state;

  const onChangeValue = (e) => {
    let value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const submitSignUp = (e) => {
    e.preventDefault();
    const rules = {
      firstName: "required",
      lastName: "required",
      email: "required|email",
      password: "required|string|min:6",
    };
    const message = {
      "firstName.required": "First Name is Required.",
      "lastName.required": "Last Name is Required.",
      "email.string": "Email Must Be String.",
      "email.required": "Email is Required.",
      "email.email": "Please Enter Valid Email.",
      "password.required": "Password is Required.",
      "password.string": "Password Must Be string.",
      "password.min": "Password Must Be More Then 6 Character.",
    };

    validateAll(state, rules, message)
      .then(async () => {
        setState({
          ...state,
          error: [],
        });

        let data = {};

        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;
        data.password = password;

        userAction.signUp(data);
      })
      .catch((errors) => {
        const formattedErrors = {};
        if (errors) {
          errors.forEach(
            (error) => (formattedErrors[error.field] = error.message)
          );
        }
        setState({
          ...state,
          error: formattedErrors,
        });
      });
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <Loader loader={loader.loader} />
      <MobileView>
        <div className="PageDad">
          <div className="page openingPage">
            <div className="firstPage loginPage">
              <div className="loginArrowDiv">
                <Link to="/first">
                  <img
                    className="loginArrow"
                    src="/images/opening/rightArrow.png"
                  />
                </Link>
              </div>
              <div className="loginHDiv">
                <div className="loginH">
                  יצירת <br />
                  חשבון חדש
                </div>
              </div>
              <div className="firstPageContent loginPageContent">
                <div className="loginPageInputsAndBtns">
                  <Form className="loginForm" role="form">
                    <div className="signinPageInputs">
                      <InputGroup className="emailDiv nameDiv form-group">
                        <Input
                          type="text"
                          className="firstName"
                          invalid={error && error.firstName ? true : false}
                          placeholder="שם פרטי"
                          id="firstName"
                          name="firstName"
                          value={firstName ? firstName : ""}
                          onChange={(e) => onChangeValue(e)}
                        />
                        <Person className="person" />
                      </InputGroup>
                      <p className="signUpFormFeedback">
                        {error && error.firstName ? error.firstName : ""}
                      </p>

                      <InputGroup className="emailDiv nameDiv form-group">
                        <Input
                          type="text"
                          className="lastName"
                          invalid={error && error.lastName ? true : false}
                          placeholder="שם משפחה"
                          id="lastName"
                          name="lastName"
                          value={lastName ? lastName : ""}
                          onChange={(e) => onChangeValue(e)}
                        />
                        <Person className="person" />
                      </InputGroup>
                      <p className="signUpFormFeedback">
                        {error && error.lastName ? error.lastName : ""}
                      </p>

                      <InputGroup className="emailDiv form-group">
                        <Input
                          type="text"
                          className="email"
                          invalid={error && error.email ? true : false}
                          placeholder="אימייל"
                          id="email"
                          name="email"
                          value={email ? email : ""}
                          onChange={(e) => onChangeValue(e)}
                        />
                        <DoubleArrow className="double-arrow" />
                      </InputGroup>
                      <p className="signUpFormFeedback">
                        {error && error.email ? error.email : ""}
                      </p>

                      <InputGroup className="passwordDiv">
                        <Input
                          type={visiblePassword ? "text" : "password"}
                          className="password"
                          invalid={error && error.password ? true : false}
                          placeholder="סיסמה"
                          id="password"
                          name="password"
                          value={password ? password : ""}
                          onChange={(e) => onChangeValue(e)}
                        />
                        <span
                          className="password-show-hide"
                          onClick={() => setVisiblePassword(!visiblePassword)}
                        >
                          {visiblePassword ? (
                            <Visibility className="visible" />
                          ) : (
                            <VisibilityOff className="visibleoff" />
                          )}
                        </span>
                      </InputGroup>
                      <p className="signUpFormFeedback">
                        {error && error.password ? error.password : ""}
                      </p>

                      <div className="forget">שכחת את הסיסמה?</div>
                    </div>
                    <div className="firstPageBtns loginPageBtns signinPageBtns">
                      <Link to="/login">
                        <div className="loginBtn loginLoginBtn">התחברי</div>
                      </Link>
                      <div className="orDiv">
                        <div className="or">או</div>
                      </div>
                      <a onClick={(e) => submitSignUp(e)}>
                        <div className="signinBtn loginSigninBtn">הרשמי</div>
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <Nav />
        <div className="webSignUp rtl d-flex justify-content-center align-items-center flex-column">
          <div className="webSignUpMain">
            <div className="webSignUpCard">
              <div className="container">
                <Button
                  type="button"
                  className="webSignUpImgBtn"
                  onClick={goToLogin}
                >
                  התחברי
                </Button>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-6">
                    <div className="webSignUpImgDiv">
                      <img
                        src={webLoginBg}
                        className="webSignUpImg"
                        alt="login"
                      />
                      <h1 className="webSignUpImgH">ברוכה הבאה</h1>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-6">
                    <div className="webSignUpCardText">
                      <h1 className="webSignUpCardTextH">צרי חשבון חדש</h1>
                      <div className="webSignUpCardForm">
                        <Form className="form" role="form">
                          <FormGroup className="webSignUpFormGroup">
                            <Label className="webSignUpCardFormLabel">
                              שם פרטי
                            </Label>
                            <InputGroup className="webInputGroup">
                              <Person className="formUserIcon" />
                              <Input
                                type="text"
                                className="webSignUpCardFormEmail"
                                invalid={
                                  error && error.firstName ? true : false
                                }
                                id="firstName"
                                name="firstName"
                                value={firstName ? firstName : ""}
                                placeholder="הכנס את שמך הפרטי"
                                onChange={(e) => onChangeValue(e)}
                              />
                            </InputGroup>
                            <p className="websignUpFormFeedback">
                              {error && error.firstName ? error.firstName : ""}
                            </p>
                          </FormGroup>
                          <FormGroup className="webSignUpFormGroup">
                            <Label className="webSignUpCardFormLabel">
                              שם משפחה
                            </Label>
                            <InputGroup className="webInputGroup">
                              <Person className="formUserIcon" />
                              <Input
                                type="text"
                                className="webSignUpCardFormEmail"
                                invalid={error && error.lastName ? true : false}
                                id="lastName"
                                name="lastName"
                                value={lastName ? lastName : ""}
                                placeholder="הזן את שם המשפחה שלך"
                                onChange={(e) => onChangeValue(e)}
                              />
                            </InputGroup>
                            <p className="websignUpFormFeedback">
                              {error && error.lastName ? error.lastName : ""}
                            </p>
                          </FormGroup>
                          <FormGroup className="webSignUpFormGroup">
                            <Label className="webSignUpCardFormLabel">
                              כתובת אימייל
                            </Label>
                            <InputGroup className="webInputGroup">
                              <MailOutlineIcon className="formMailIcon" />
                              <Input
                                type="text"
                                className="webSignUpCardFormEmail"
                                invalid={error && error.email ? true : false}
                                id="email"
                                name="email"
                                value={email ? email : ""}
                                placeholder="הכניסי את כתובת האימייל שלך"
                                onChange={(e) => onChangeValue(e)}
                              />
                            </InputGroup>
                            <p className="websignUpFormFeedback">
                              {error && error.email ? error.email : ""}
                            </p>
                          </FormGroup>
                          {/* <FormGroup className="webSignUpFormGroup">
                            <Label className="webSignUpCardFormLabel">
                              עסקי URL
                            </Label>
                            <InputGroup className="webInputGroup">
                              <LanguageIcon className="formLanguageIcon" />
                              <Input
                                type="text"
                                className="webSignUpCardFormEmail"
                                id="business_url"
                                name="business_url"
                                placeholder="הכניסי את הURL העסקי שלך"
                                onChange={(e) => onChangeValue(e)}
                              />
                            </InputGroup>
                            <p className="websignUpFormFeedback"></p>
                          </FormGroup> */}
                          <FormGroup className="webSignUpFormGroup">
                            <Label className="webSignUpCardFormLabel">
                              סיסמה
                            </Label>
                            <InputGroup className="webInputGroup">
                              <span
                                className="webPasswordShowHide"
                                onClick={() =>
                                  setVisiblePassword(!visiblePassword)
                                }
                              >
                                {visiblePassword ? (
                                  <Visibility className="webPasswordVisible" />
                                ) : (
                                  <VisibilityOff className="webPasswordVisibleoff" />
                                )}
                              </span>
                              <Input
                                type={visiblePassword ? "text" : "password"}
                                className="webSignUpCardFormPassword"
                                invalid={error && error.password ? true : false}
                                id="password"
                                name="password"
                                value={password ? password : ""}
                                placeholder="הכניסי סיסמה"
                                onChange={(e) => onChangeValue(e)}
                              />
                            </InputGroup>
                            <p className="websignUpFormFeedback">
                              {error && error.password ? error.password : ""}
                            </p>
                          </FormGroup>
                          <div className="webSignUpFormBtnDiv mt-5">
                            <Button
                              type="button"
                              className="webSignUpCardFormLoginBtn"
                              onClick={(e) => submitSignUp(e)}
                            >
                              צרי חשבון
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
    </>
  );
};

export default Signup;
