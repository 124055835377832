import { Routes, Route } from "react-router-dom";
import Nav from "./../../web/nav/Nav";
import Footer from "./../../web/footer/Footer";
import Home from "./../../web/home/Home";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const MultipleLayout = ({componentName}) => {
  return (
      <>
    <MobileView>
        {componentName}
    </MobileView>  
    <BrowserView>
        <div className="webPage">
        <Nav />
        <Home />
        <Footer sidebarComponent={componentName}/>
        </div>
    </BrowserView>
    </>
  );
};

export default MultipleLayout;
