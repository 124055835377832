import './Staff.css';
import OpenSquare from '../../../regularPages/repeatsComponents/opensquare/OpenSquare';
import Search from '../../../regularPages/repeatsComponents/search/Search'
import ListHeader from '../../../regularPages/repeatsComponents/listheader/ListHeader'
import Head from '../../../repeats/head/Head';
import Footer from '../../../repeats/footer/Footer';

import StarRoundIcon from '@mui/icons-material/Star';

const Staff = () => {

	const StaffSquareArr = [
		{
			img: '/images/staff/doctor.jpg',
			header: 'לדוקטור',
			text: 'אבחונים, אולטרסאונד וכד',
			link: '/fordoctors',
		},
		{
			img: '/images/staff/nurse.jpg',
			header: 'לאחות',
			text: 'טיפים לטיפול במטופלים',
			link: '/fornurse',
		},
	]

	const ListHeaderObj = {
		header: 'מטופלת - ',
		headerSpan: 'שרה זילבר',
		par: 'מחלקת יולדות - ',
		parSpan: `חדר מס' 28, מיטה  מס' 2`,
	}

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='לצוות הרפואי' />
				<div className='staffPage'>
					<Search icon={<StarRoundIcon className="searchStar" />} />
					<ListHeader
						header={ListHeaderObj.header}
						headerSpan={ListHeaderObj.headerSpan}
						par={ListHeaderObj.par}
						parSpan={ListHeaderObj.parSpan}
					/>
					<div className='staffSquaresDiv'>
						{
							StaffSquareArr.map((el, i) => {
								return (
									<OpenSquare
										img={el.img}
										header={el.header}
										text={el.text}
										link={el.link}
										key={i}
									/> 
								)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>

	)
}

export default Staff