import "./Banner.css";
import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ img, text, link }) => {
  return (
    <div className="gynecologybanner">
      <img className="bannerBG" src={img} />
      <Link to={link}>
        <div className="bannerText">
          <div className="bannerTextH">{text}</div>
          <img className="bannerTextImg" src="/images/gynecology/arrow.png" />
        </div>
      </Link>
    </div>
  );
};

export default Banner;
