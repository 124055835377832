import "./Confirm.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DoctorAbout from "./DoctorAbout";
import Head from "../../repeats/head/Head";

import doctor1 from "../../../assets/images/doctor/doctor1.jpg";

const Confirm = () => {
  useEffect(() => {
    document.querySelector(".head").style.marginBottom = "5px";
  }, []);

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="אישור פגישה" />
        <div className="startPage confirmPage">
          <img
            className="startImg"
            src="/images/start/congrats.png"
            alt="congrats"
          />
          <main className="startMain">
            <h1 className="startH1">נקבעה לך פגישה</h1>
            <h2 className="startH2">יש לך פגישה עם דוקטור חנה לוי</h2>
            <DoctorAbout
              img={doctor1}
              header='ד"ר חנה לוי'
              text="גינקולוגית"
              link=""
            />
            <div className="date_area">
              <h1>
                Friday <span>11.04</span> at <span>11:00 am</span>
              </h1>
            </div>
          </main>
          <div className="fixed_btn_section">
            <Link to="/myappointment">
              <button className="startSubmit">לאישור</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
