import React from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import "./VandorDetails.css";

import Banner from "./banner/Banner";

import TabBtn from "../../regularPages/repeatsComponents/tabsMenu/TabBtn";
import VandorDetailsSquareList from "./VandorDetailsSquare/VandorDetailsSquareList";

const VandorDetails = () => {
  const tabsBtnsArr = [
    {
      text: "הכל",
    },
    {
      text: "נעליים",
    },
    {
      text: "אלקטרוניקה",
    },
    {
      text: "בגדים",
    },
  ];

  const active = (e) => {
    const choosen = e.target.parentElement;
    const options = document.querySelectorAll(".tabsMenuLi");
    for (let i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
    choosen.classList.add("active");
  };

  return (
    <>
      <div className="PageDad">
        <div className="vandor_details_pages rtl">
          <Head text="פוט לוקר" />

          <Banner />

          <div className="description_div">
            <h1 className="description_div_h1">תיאור</h1>
            <p className="description_div_p">
פוט לוקר - התחלנו מחנות קטנה שמוכרת נעליים ייחודיות ומגניבות, אבל לא הפסקנו להתפתח, היום יש בחנות אלפי זוגות נעליים בעיצובים שונים, ביגוד לכל אחד מהטי שירט הפשוטה ועד לחליפה היוקרתית ואפילו מוצרי אלקטרוניקה.
            </p>
          </div>

          <section className="vandor_details_list_sec">
            <div className="tabs">
              <ul
                id="tabs-nav"
                className="list-unstyled"
              >
                {tabsBtnsArr.map((el, i) => {
                  return (
                    <TabBtn active={active} text={el.text} key={i} i={i} />
                  );
                })}
              </ul>
              {/* <!-- END tabs-nav --> */}
              <div id="tabs-content">
                <div id="tab1" className="tab-content">
                  <VandorDetailsSquareList />
                </div>
              </div>
              {/* <!-- END tabs-content --> */}
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default VandorDetails;
