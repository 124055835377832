import React, { Component } from "react";
import "../../hospitalStay/patient/staff/Staff.css";
import ToggleSquareImg from "../../regularPages/repeatsComponents/togglesquare/ToggleSquareImg.js";
import ArrowSquare from "../../regularPages/repeatsComponents/togglesquare/ArrowSquare.js";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import NotificationsIcon from '@mui/icons-material/Notifications';
import { SiGooglenews } from 'react-icons/si';
import { IoDocumentText } from 'react-icons/io5';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HelpIcon from '@mui/icons-material/Help';
import { GoSignOut } from 'react-icons/go';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const Settings = () => {
  const settingsTogglesArr = [
    {
      header: "התראות",
      img: <NotificationsIcon className='toggleSquareImg' />,
      link: "/settingsnotifications",
    },
    {
      header: "עקבי אחר החדשות שלנו",
      img: <SiGooglenews className='toggleSquareImg' />,
      link: "/subscribe",
    },
  ];

  const settingsArrowArr = [
    {
      header: "תנאים והגבלות",
      img: <IoDocumentText className='toggleSquareImg' />,
      link: "#",
    },
    {
      header: "פרטיות",
      img: <PrivacyTipIcon className='toggleSquareImg' />,
      link: "#",
    },
    {
      header: "מועדפים",
      img: <FavoriteIcon className='toggleSquareImg' />,
      link: "/favorites",
    },
    {
      header: "עזרה",
      img: <HelpIcon className='toggleSquareImg' />,
      link: "#",
    },
    {
      header: "יציאה",
      img: <GoSignOut className='toggleSquareImg' />,
      link: "#",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <MobileView className="mv">
          <Head text="הגדרות " />
        </MobileView>
        <div className="staffPage">
          <div className="staffSquaresDiv">
            {settingsTogglesArr.map((el, i) => {
              return (
                <ToggleSquareImg
                  header={el.header}
                  img={el.img}
                  link={el.link}
                  key={i}
                />
              );
            })}
            {settingsArrowArr.map((el, i) => {
              return (
                <ArrowSquare
                  header={el.header}
                  img={el.img}
                  link={el.link}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
      <MobileView>
        <Footer />
      </MobileView>
    </div>
  );
};

export default Settings;
