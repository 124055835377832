import axios from "axios";
import { toast } from "react-toastify";
let apiBaseUrl = process.env.REACT_APP_API_URL


function AxiosApiCall() {

  const withToken = async (authToken, url, type, data, version = 'api/v3/') => {
    const config = {
      method: type,
      url: `${apiBaseUrl}${url}`,
      data: data,
      headers: {
        "Authorization": authToken,

      },
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })
      .catch((error) => {
        if (error.response.data) {
          let res = error.response.data;
          if (res.message === 'Unauthorised user!') {
            toast.error("Session Time out please, Login Again.!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            result.login = false;
            // dispatch(logout());
            // navigate("/login", { replace: true });
          }
        }
        if (error.response) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.request) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    return result;
  };
  const withoutToken = async (url, type, data, headers = {}) => {
    const config = {
      method: type,
      url: `${apiBaseUrl}${url}`,
      data: data,
      headers: headers,
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })

      .catch((error) => {
        if (error.response) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.request) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    return result;
  };

  const withTokenFullUrl = async (authToken, url, type, data) => {
    const config = {
      method: type,
      url: `${url}`,
      data: data,
      headers: {
        "Authorization": authToken,

      },
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })
      .catch((error) => {
        if (error.response) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.request) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    return result;
  };
  const withoutTokenFullUrl = async (url, type, data) => {
    const config = {
      method: type,
      url: `${url}`,
      data: data
    };
    let result = {};
    await axios(config)
      .then(async (response) => {
        result = response.data;
      })

      .catch((error) => {
        if (error.response) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.request) {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("There is a some issue..! Please try after some time.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    return result;
  };
  return {
    withToken,
    withoutToken,
    withTokenFullUrl,
    withoutTokenFullUrl
  };
}

const AxiosApi = AxiosApiCall();
export default AxiosApi;
