import React, { useEffect, useRef, useState } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import "./Hospitals.css";
import OpenRound from "./openRound/OpenRound";
import Filter from "./filter/Filter";
import filter_icon from "../../../assets/images/Filter.png";
import filterIcon from "../../../assets/images/web/filter/filterBtn.png";
import search_icon from "../../../assets/images/search.png";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HospitalAction } from "../../../actions/hospital";
import Toast from "../../../helpers/toast";


const Hospitals = () => {
  const [showfilter, setShowfilter] = useState(false);

  const [showdrp, setShowdrp] = useState(false);

  const [showshortdrp, setShowshortdrp] = useState(false);

  const messagesEndRef = useRef();

  const messagesEndRef1 = useRef();

  const scrollToBottom = () => {
    messagesEndRef1.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const dispatch = useDispatch();

  const hospitalAction = HospitalAction();

  const [hospitals, setHospitals] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     //This is to get service list
  //     await hospitalAction
  //       .getHospitalList()
  //       .then((response) => {
  //         dispatch({ type: "UNSET_LOADER" });

  //         if (response) {
  //           if (response.success == true) {
  //             setHospitals(response.vendors);
  //           } else {
  //             Toast.error(response.message);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         Toast.error(error.message);
  //       });
  //   })();
  // }, []);

  const hospitalsArr = [
    {
      img: "/images/hospitals/hospital1.jpg",
      header: "המרכז לטיפול בהריון",
      // link: "/hospital/0",
    },
    {
      img: "/images/hospitals/hospital2.jpg",
      header: "מרכז רפואי סורוקה",
      // link: "/hospital/1",
    },
    {
      img: "/images/hospitals/hospital3.jpg",
      header: "בית חולים וולפסון",
      // link: "/hospital/2",
    },
    {
      img: "/images/hospitals/hospital4.jpg",
      header: "כללית שירותי בריאות",
      // link: "/hospital/3",
    },
    {
      img: "/images/hospitals/hospital5.jpg",
      header: "בית חולים אסותא",
      // link: "/hospital/4",
    },
    {
      img: "/images/hospitals/hospital6.jpg",
      header: "המרכז הרפואי סורסקי",
      // link: "/hospital/5",
    },
  ];

  return (
    <>
      <div className="PageDad">
        <div className="page">
          <Head text="בתי חולים" />
          <section className="search_sec" ref={messagesEndRef}>
            <div className="search_area">
              <Link
                to={"#"}
                className="filter_icon"
                onClick={() => {
                  setShowfilter(!showfilter);
                  if (showfilter) {
                    scrollToTop();
                  } else {
                    scrollToBottom();
                  }
                }}
              >
                <img src={filterIcon} alt="filter" />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  {/* <img src={search_icon} alt="Search" className="SearchImg" /> */}
                  <SearchIcon className="SearchImg" />
                </button>
                <input
                  type="Search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חפשי לפי שם"
                />
              </div>
            </div>
          </section>

          <Filter
            showfilter={showfilter}
            setShowfilter={setShowfilter}
            scrollToTop={scrollToTop}
            scrollToBottom={scrollToBottom}
            showdrp={showdrp}
            setShowdrp={setShowdrp}
            showshortdrp={showshortdrp}
            setShowshortdrp={setShowshortdrp}
            messagesEndRef1={messagesEndRef1}
          />

          <div className="hospitalsPage">
            <div className="staffSquaresDiv">
              {hospitalsArr.length > 0
                ? hospitalsArr.map((el, i) => {
                  return (
                    <OpenRound
                      key={i}
                      // id={el.id}
                      // img={el.posterImage}
                      // header={el.title}
                      img={el.img}
                      header={el.header}
                      id={1}
                    />
                  );
                })
                : ""}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Hospitals;
