import "./Gynecology.css";
import Head from "../../repeats/head/Head";
import Banner from "../../regularPages/repeatsComponents/banner/Banner";
import Footer from "../../repeats/footer/Footer";
import DoctorList from "../../regularPages/doctorList/DoctorList";
import ServiceList from "../../regularPages/serviceList/ServiceList";
import SquareList from "./SquareList";
import ServiceListHeader from '../../regularPages/serviceList/ServiceListHeader'
import BlogItem from '../../regularPages/repeatsComponents/blogItem/BlogItem'

const Gynecology = () => {
	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='גינקולוגיה' />
				<div className='gynecologyPage'>
					<Banner 
						img='/images/gynecology/bannerBG.jpg'
						text='הזמנת יעוץ'
						link='/appointment'
					/>
					<div className='gynecologyMain'>
						<DoctorList />
						<SquareList />
						<ServiceList />
				        <ServiceListHeader text='הבלוג שלנו:' link='/blog' />
						<BlogItem
							link='/PostPage1'
							img='/images/gynecology/blog1.jpg'
							header1='ביקור ראשון אצל הגינקולוג:'
							header2='מידע חשוב'
							text='6 דברים שצריך לדעת לפני ביקור אצל גינקולוג'
							blogClass='blog row-rev'
							blogContentClass='blogContent pad'
						/>

						<BlogItem 
							link='/PostPage2'
							img='/images/gynecology/blog2.jpg'
							header1='דכאון במהלך'
							header2='ההריון.'
							text='בהריון ובשבועות הראשונים שלאחר הלידה, אמא חדשה עוברת רכבת הרים של רגשות.'
							blogClass='blog'
							blogContentClass='blogContent'
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Gynecology