import React, { Component } from "react";
import "./Menu.css";
import { Link, useNavigate } from "react-router-dom";
import logoutIcon from "../../../assets/images/icons/out.png";

import { UserAction } from "../../../actions/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isBrowser, isMobile } from "react-device-detect";

const Menu = ({ display }) => {

  const userAction = UserAction();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {

    const menuItemsArr = [];
    menuItemsArr.push({
      link: "/account",
      text: "הפרופיל שלי",
      img: "/images/head/menu/account.png",
    });

    menuItemsArr.push({
      link: "/pregnancy",
      text: "הריון",
      img: "/images/head/menu/pregnancy.png",
    });

    menuItemsArr.push({
      link: "",
      text: "המיקום שלי",
      img: "/images/head/menu/location.png",
    });

    menuItemsArr.push({
      link: "",
      text: `קבוצות צ'אט`,
      img: "/images/head/menu/group_chat.png",
    });

    menuItemsArr.push({
      link: "",
      text: `צ'אט`,
      img: "/images/head/menu/chat.png",
    });

    menuItemsArr.push({
      link: "/settings",
      text: "הגדרות",
      img: "/images/head/menu/settings.png",
    });

    if (!user || user.id == null || user.id == '') {
      menuItemsArr.push({
        link: "/first",
        text: "רישום",
        img: "",
      });
    }

    menuItemsArr.push({
      link: "/hospital/1",
      text: "וולפסון",
      img: "",
    });


    menuItemsArr.push({
      link: "/appointment",
      text: "קביעת פגישה",
      img: "",
    });

    menuItemsArr.push({
      link: "/doctor",
      text: "הרופאה שלך",
      img: "",
    });

    menuItemsArr.push({
      link: "/notifications",
      text: "התראות",
      img: "",
    });

    menuItemsArr.push({
      link: "/about",
      text: "אודות",
      img: "",
    });

    menuItemsArr.push({
      link: "/marketplace",
      text: "חנות שירותים",
      img: "",
    });

    menuItemsArr.push({
      link: "/products",
      text: "מוצרים",
      img: "",
    });

    menuItemsArr.push({
      link: "/club",
      text: "חברות מועדון",
      img: "",
    });

    menuItemsArr.push({
      link: "/doctors",
      text: "רופאים",
      img: "",
    });

    menuItemsArr.push({
      link: "/call",
      text: "שיחה",
      img: "",
    });

    menuItemsArr.push({
      link: "/video",
      text: "שיחת וידאו",
      img: "",
    });

    menuItemsArr.push({
      link: "/gynecology",
      text: "גינקולוגיה",
      img: "",
    });

    menuItemsArr.push({
      link: "/hospitals",
      text: "בתי חולים",
      img: "",
    });

    menuItemsArr.push({
      link: "/departments",
      text: "מחלקות",
      img: "",
    });

    menuItemsArr.push({
      link: "/PostPage1",
      text: "עמוד פוסט",
      img: "",
    });

    menuItemsArr.push({
      link: "/PostPage2",
      text: "עמוד פוסט",
      img: "",
    });


    menuItemsArr.push({
      link: "/blog",
      text: "הבלוגים שלנו",
      img: "",
    });

    menuItemsArr.push({
      link: "/blogpostlist",
      text: "מידע בשביל התינוק",
      img: "",
    });

    menuItemsArr.push({
      link: "/myappointment",
      text: "הפגישות שלי",
      img: "",
    });

    menuItemsArr.push({
      link: "/review",
      text: "סקירה",
      img: "",
    });

    menuItemsArr.push({
      link: "/bills",
      text: "עגלת הקניות שלי",
      img: "",
    });

    menuItemsArr.push({
      link: "/nutricion",
      text: "תזונה נכונה",
      img: "",
    });

    menuItemsArr.push({
      link: "/settingsnotifications",
      text: "הגדרת התראות",
      img: "",
    });


    menuItemsArr.push({
      link: "/subscribe",
      text: "עקבי אחר החדשות שלנו",
      img: "",
    });

    menuItemsArr.push({
      link: "/favorites",
      text: "מועדפים",
      img: "",
    });

    menuItemsArr.push({
      link: "/health",
      text: "מידע בריאותי",
      img: "",
    });

    menuItemsArr.push({
      link: "/tellus",
      text: "תגיד לנו",
      img: "",
    });

    setMenuList(menuItemsArr);
  }, []);



  const logout = () => {
    userAction.logout();

    navigate(isMobile ? "/" : isBrowser ? "/web/page/home" : "", { replace: true });
  };

  return (
    <div className="mainList">
      {menuList.map((el, i) => {
        return (
          <Link
            onClick={() => display(".menu")}
            className="menuItem"
            to={el.link}
            key={i}
          >
            <div className="menuItemIconDiv">
              <img className="menuItemIcon" src={el.img} />
            </div>
            <h1 className="menuItemHeader">{el.text}</h1>
          </Link>
        );
      })}

      {
        (user && user.id != '' && user.id != null) ?
          <a className="menuItem" onClick={logout}>
            <div className="menuItemIconDiv">
              <img className="menuItemIcon" src={logoutIcon} />
            </div>
            <h1 className="menuItemHeader">יציאה</h1>
          </a>
          :
          ''
      }
    </div>
  );
};

export default Menu;
