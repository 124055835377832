import AidCard from './AidCard'

const AidCardList = () => {

	const AidCardListArr = [
		{
			img: '/images/mybody/counter.jpg',
			text: 'מונה  דיגיטלי',
		},
		{
			img: '/images/mybody/contraction.jpg',
			text: 'מחשבון התכווצות',
		},
		{
			img: '/images/mybody/todo.jpg',
			text: 'רשימת משימות',
		},		
	]

	return (
		<div className='aidCardList' >
					{
						AidCardListArr.map((el, i) => {
							return (
								<AidCard 
									img={el.img}
									text={el.text}
									key={i}
								/> 
							)
						})
					}
		</div>
	)
}

export default AidCardList