const Btn = ({img, icon, i}) => {

	let display = ''
	if (i === 0) {
		display = (className) => {
			const item = document.querySelector(className)
			const file = document.querySelector('.fileBtnImg')
			if (item.style.display === 'block') {
				item.style.display = 'none';
				file.style.fill = 'white'
				file.style.background = 'rgba(255, 255, 255, .16)'
			} else {
				item.style.display = 'block';
				file.style.fill = 'var(--primary)'
				file.style.background = 'white'
			}
			const headNotifications = document.querySelector('.headNotifications')
			if (className === '.menu') {
				headNotifications.style.display = 'none'
			}
		}
	} else {
		display = () => {console.log('notFile')}
	}

	return (
		<div onClick={() => display('.shareFilesBoard')} className='videoBtn'>
			{/* <img className='videoBtnImg' src={img} /> */}
			{
				img ? <img className='videoBtnImg' src={img} alt='video' /> : icon
			}
		</div>
	)
}

export default Btn