import './Pregnancy.css';
import React, { useEffect } from 'react';
import PregnancySquare from '../regularPages/repeatsComponents/pregnancySquare/PregnancySquare';
import Head from '../repeats/head/Head';
import Footer from '../repeats/footer/Footer';

const Pregnancy = () => {

	const pregnancyArr = [
		{
			img: '/images/pregnancy/mybody.jpg',
			text: 'הגוף שלי',
			link: '/mybody',
		},
		{
			img: '/images/pregnancy/mybaby.jpg',
			text: 'ההריון שלי',
			link: '/mybaby',
		},
		{
			img: '/images/pregnancy/fitness.jpg',
			text: 'אורח חיים בריא',
			link: '/fitness',
		},
		{
			img: '/images/pregnancy/nutricion.jpg',
			text: 'תזונה נכונה',
			link: '/nutricion',
		},
		{
			img: '/images/pregnancy/vitamins.jpg',
			text: 'תרופות וויטמנים',
			link: '/vitamins',
		},
	]

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='הריון' />
				<main className='pregnancyPage' >
					{
						pregnancyArr.map((el, i) => {
							return (
								<PregnancySquare img={el.img} text={el.text} link={el.link} key={i} />
							)
						})
					}
				</main>
			</div>
			<Footer />
		</div>
	)
}

export default Pregnancy