import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import header_heart from "../../../../assets/images/Icon.png";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import like_heart_icon from "../../../../assets/images/icons/heart.png";
import FavoriteIcon from '@mui/icons-material/Favorite';
import main_product_image from "../../../../assets/images/product_details.png";


const ProductsSwiper = () => {
  const ProductListArr = [
    {
      image: main_product_image,
      title:
        "עגלת התינוק החדישה ביותר גם מושב בטיחות וגם עגלה במוצר אחד",
      link_add: "#",
      link_buy: "#",
      price: "315$",
      sku: "CO2312K",
      categories: "עגלות תינוקות",
      like: "234",
    },
    {
      image: main_product_image,
      title:
        "עגלת התינוק החדישה ביותר גם מושב בטיחות וגם עגלה במוצר אחד",
      link_add: "#",
      link_buy: "#",
      price: "311$",
      sku: "CO2312K",
      categories: "עגלות תינוקות",
      like: "230",
    },
    {
      image: main_product_image,
      title:
        "עגלת התינוק החדישה ביותר גם מושב בטיחות וגם עגלה במוצר אחד",
      link_add: "#",
      link_buy: "#",
      price: "310$",
      sku: "CO2312K",
      categories: "עגלות תינוקות",
      like: "222",
    },
  ];

  const slides = ProductListArr.map((el, i) => (
    <div className="product_card" key={i}>
      <div className="row product_card_row">
        <div className="col-lg-4 col-sm-12 product_card_row_col_img">
          <div className="product_box_img">
            <img src={el.image} alt="service" />
            <div className="time_dt d-flex justify-content-center dark">
              <p className="time_dt_p">10$-</p>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-sm-12 product_card_row_col_detail">
          <div className="likes">
            <FavoriteIcon />
            <span style={{ padding: "10px" }}>{el.like}</span>
          </div>
          <div className="product_price">
            <p className="product_price_number">{el.price}</p>
            <p className="product_price_text">{el.title}</p>
          </div>
          <div className="product_category">
            <p>מספר : {el.sku}</p>
            <p>קטגוריה : {el.categories}</p>
          </div>
          <div className="product_box_btn">
            <div className="product_btn_area">
              {/* <img src={header_heart} className="product_btn_area_heart" alt="heart" /> */}
              <FavoriteBorderIcon className="product_btn_area_heart" />
              <Link
                to={"" + el.link_add + ""}
                className="product_buy_now_btn product_add_to_card_btn btn_line"
              >
                הוסיפי לעגלה
              </Link>
              <Link
                to={"" + el.link_buy + ""}
                className="product_buy_now_btn btn_line"
              >
                קני עכשיו
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Swiper slidesPerView={1} spaceBetween={1} className="mySwiper">
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ProductsSwiper;
