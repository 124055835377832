import './ToggleSquareImg.css';
import { Link } from "react-router-dom"

const ToggleSquareImg = ({header, img, link}) => {
	return (
		<div className='toggleSquare toggleSquareImgDiv'>
			<Link className='ImgAndH' to={link}>
				{/* <img className='toggleSquareImg' src={img} /> */}
				{img}
				<div className='openSquareTextH' >{header}</div>
			</Link>
			<label className="switch">
				<input type="checkbox" />
				<span className="slider round"></span>
			</label>
		</div>
	)
}

export default ToggleSquareImg