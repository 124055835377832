import React from "react";
import Head from "../repeats/head/Head";
import Footer from "../repeats/footer/Footer";

import { Link } from "react-router-dom";
import filter_icon from "../../assets/images/Filter.png";
import location_icon from "../../assets/images/location.png";
import search_icon from "../../assets/images/search.png";

import addBtn from "../../assets/images/Add_btn.png";

import "./Home.css";

import arrow from "../../assets/images/double_arrow_down.png";
import DoctorList from "../regularPages/doctorList/DoctorList";
import HospitalList from "../regularPages/hospitalList/HospitalList";

import CategorySquareList from "./CategorySquare/CategorySquareList";
import MyHealthSquareList from "./MyHealthSquare/MyHealthSquareList";
import PregnantSquareList from "./PregnantSquare/PregnantSquareList";
import BlogSquareList from "./BlogSquare/BlogSquareList";

import filterIcon from "../../assets/images/web/filter/filterBtn.png";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const Home = (props) => {
  return (
    <>
      <div className="PageDad">
        <div className="home_pages rtl">
          {props.viewFrom != 'web'?
            <Head text="בית" /> : ''
          }

          <section className="search_sec">
            <div className="search_area">
              <Link to={"#"} className="filter_icon">
                <img src={filterIcon} alt="filter" />
              </Link>
              <Link to={"#"} className="location_icon">
                <LocationOnIcon />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  <SearchIcon className="SearchImg" />
                </button>
                <input
                  type="search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חיפוש"
                />
              </div>
            </div>
          </section>

          <section className="category_area">
            {/* Category Swiper */}
            <CategorySquareList />
            {/* Category Swiper */}
          </section>

          <div className="container">
            <div className="btn_area">
              <Link to="/appointment" className="go_btn">
                <div className="btn_heading">
                  <div className="text_area">
                    <p>הפגישות שלך </p>
                    <h3>קבעי פגישה חדשה</h3>
                  </div>
                  <div className="add_image">
                    {/* <img
                      className="d-flex align-center"
                      src={addBtn}
                      alt="add-btn"
                    /> */}
                    <AddIcon />
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <section className="my_health_sec">
            <div className="my_health_heading align-center justify-between">
              <Link to={"/pregnancy"} className="align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon className="doubleAroow" />
                לתפריט מלא{" "}
              </Link>
              <h3>הבריאות שלי </h3>
            </div>
            <div className="my_health_list">
              {/* My Health Swiper */}
              <MyHealthSquareList className="doubleAroow" />
              {/* My Health Swiper */}
            </div>
          </section>

          <section className="my_club_pregnant_sec">
            <div className="my_club_pregnant_heading align-center justify-between">
              <Link to={"/club"} className="align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon className="doubleAroow" />
                לפתיחה{" "}
              </Link>
              <h3>מועדון לנשים בהריון</h3>
            </div>
            {/* Pregnant Swiper */}
            <PregnantSquareList />
            {/* Pregnant Swiper */}
          </section>

          <HospitalList />

          <section className="our_blog_sec">
            <div className="our_blog_heading align-center justify-between">
              <Link to={"/blog"} className="align-center">
                {/* <img src={arrow} alt="double-arrow" /> */}
                <KeyboardDoubleArrowLeftIcon className="doubleAroow" />
                לפתיחה{" "}
              </Link>
              <h3>הבלוג שלנו</h3>
            </div>
            {/* Our Blog Swiper */}
            <BlogSquareList />
            {/* Our Blog Swiper */}
          </section>

          {/* Our Doctor Swiper */}
          <DoctorList />
          {/* Our Doctor Swiper */}
          {props.viewFrom != 'web'?
            <Footer /> : ''
          }

        </div>
      </div>
    </>
  );
};

export default Home;
