import './Head.css';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Menu from '../menu/Menu'
import HeadNotifications from '../../notificationsPages/headNotifications/HeadNotifications'

import logo from '../../../assets/images/nav/logo.jpg'
import bell from '../../../assets/images/head/bell1.png'
import CircleIcon from '@mui/icons-material/Circle';
import WestIcon from '@mui/icons-material/West';

const Head = ({ text, isBackArrow }) => {
  const navigate = useNavigate();

  const display = (className) => {
    const item = document.querySelector(className)
    if (item.style.display === 'block') {
      item.style.display = 'none';
    } else {
      item.style.display = 'block';
    }
    const headNotifications = document.querySelector('.headNotifications')
    if (className === '.menu') {
      headNotifications.style.display = 'none'
    }
  }

  const changeHeadNotPosition = () => {
    const head = document.querySelector('.head')
    const headNotifications = document.querySelector('.headNotifications')
    if (head !== null) {
      let rightPosition = head.getBoundingClientRect().y + 38
      headNotifications.style.top = `${rightPosition}px`
    }
  }

  useEffect(() => {
    changeHeadNotPosition()
    document.addEventListener("scroll", changeHeadNotPosition);
  }, []);

  return (
    <>
      {/* <div className='logoDiv' >
				<img className='logoImg' src={logo} />
			</div> */}
      <div className='head' >
        <img onClick={() => display('.menu')} className='menuIcon' src="/images/head/menu.png" width='19px' />
        <div className='headHeader' >{text}</div>
        {
          isBackArrow ? (            
            <WestIcon onClick={() => navigate(-1)} className='backArrow'/>
          ) : (
            <div className='bellAndCircle'>
              <img
                onClick={() => display('.headNotifications')}
                className='bell'
                src={bell}
              />
              <CircleIcon className='bellCircle' />
            </div>
          )
        }
      </div>
      <div className='menu' >
        <img onClick={() => display('.menu')} className='menuEsc' src='/images/head/esc.png' />
        <Menu display={display} />
      </div>
      <HeadNotifications />
    </>
  )
}

export default Head