import React from "react";

import heart from "../../../../assets/images/icons/heart.svg";

import MarketPlaceSquare from "./MarketPlaceSquare";

const MarketPlaceSquareList = () => {
    const MarketPlaceListArr = [
        {
            market_place_img: "/images/marketPlace/market_place3.jpg",
            heart_img: { heart },
            title: "קורס יוגה",
            detail: "לנשים בהריון",
            price: "85$",
            link: "/service/6",
            market_place_img_key: 1,
        },
        {
            market_place_img: "/images/marketPlace/market_place4.jpg",
            heart_img: { heart },
            title: "קורס",
            detail: "הכנה להריון",
            price: "115$",
            link: "/service/7",
            market_place_img_key: 2,
        },
        {
            market_place_img: "/images/marketPlace/market_place5.jpg",
            heart_img: { heart },
            title: "תכנית תזונה",
            detail: "להריון",
            price: "145$",
            link: "/service/8",
            market_place_img_key: 3,
        },
        {
            market_place_img: "/images/marketPlace/market_place6.jpg",
            heart_img: { heart },
            title: "יעוץ לאישה",
            detail: "בהריון",
            price: "40$",
            link: "/service/9",
            market_place_img_key: 4,
        },
    ];

    return (
        <div className="list_box_area d-flex justify-content-between row gx-4">
          {MarketPlaceListArr.map((el, i) => {
            return (
              <MarketPlaceSquare
                market_place_img={el.market_place_img}
                heart_img={el.heart_img}
                title={el.title}
                detail={el.detail}
                price={el.price}
                link={el.link}
                market_place_img_key={el.market_place_img_key}
                key={i}
              />
            );
          })}
        </div>
      );
};

export default MarketPlaceSquareList;