import './About.css';
import Head from '../repeats/head/Head';
import Footer from '../repeats/footer/Footer';
import PList from './PList'

const About = () => {

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='אודות' />
				<div className='aboutPage'>
					<h1 className='aboutH' >פרויקט יולדות</h1>
					<div className='aboutProject'>
						<span>אודות הפרויקט:</span> האפליקציה  pregnancy journey ייחודית עבורך ומלווה אותך מתחילת תהליך ההריון ועד סופו. התהליך כולל ליווי מקצועי, רפואי וקהילתי. האפליקציה מציעה לך מגוון רחב של חידושים:
					</div>
					<PList />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default About