import React from "react";

import ServiceListHeader from "../../../regularPages/serviceList/ServiceListHeader";
import ServicesSqaure from "./ServicesSqaure";

import star from "../../../../assets/images/star.svg";
import { Container } from "react-bootstrap";

const ServicesSqaureList = () => {
  const serviceListArr = [
    {
      image: "/images/web/hospital/services/service1.jpg",
      title: "ייעוץ אונליין",
      detail: "דברי עם הרופאה שלך בכל זמן",
      review: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service2.jpg",
      title: "אולטרסאונד ",
      detail: "כללי או תלת מימד",
      review: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service3.jpg",
      title: "ניהול הריון",
      detail: "פגישות עם רופאים וכד'.",
      review: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service4.jpg",
      title: "ייעוץ הנקה",
      detail: "פגישה עם רופא מיילד",
      review: "4.8 (1474 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service5.jpg",
      title: "אבחונים להריון",
      detail: "כל האבחונים כולל מורכבים",
      review: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service6.jpg",
      title: "ייעוץ לנשים בהריון",
      detail: "באר שבע, רגר 151",
      review: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service7.jpg",
      title: "תכנית תזונה לנשים בהריון",
      detail: "תכנית מותאמת אישית על ידי רופא",
      review: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "#",
    },
    {
      image: "/images/web/hospital/services/service8.jpg",
      title: "יוגה לנשים בהריון",
      detail: "קורס",
      review: "4.6 (1192 ביקורות)",
      star_image: { star },
      link: "#",
    },
  ];

  return (
    <>
      <ServiceListHeader text="השירותים שלנו" link="#" text2='לכל השירותים' />
      {/* <Container> */}
        <div className="hospitalServicesList">
          {serviceListArr.map((el, i) => {
            return (
              <ServicesSqaure
                image={el.image}
                title={el.title}
                detail={el.detail}
                review={el.review}
                star_image={el.star_image}
                link={el.link}
                key={i}
              />
            );
          })}
        </div>
      {/* </Container> */}
    </>
  );
};

export default ServicesSqaureList;
