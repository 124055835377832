import React from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

import "./OurDepartment.css";
import Banner from "./Banner";

import our_department from "../../../assets/videos/ourDepartment/our_department.mp4";

const OurDepartment = () => {
  return (
    <>
      <div className="PageDad">
        <div className="our_department_page">
          <Head text="Gynecology Department" />

          <Banner />

          <div className="our_department_paragraph">
            <p className="our_department_p">
              When you come to the Wolfson Clinic Department of Obstetrics and
              Gynecology, you'll find support on your lifelong journey from
              adolescence to menopause and beyond. Its team-based approach
              includes you as a partner in care, healing, hope and solutions to
              complex problems.
            </p>
            <p className="our_department_p">
              The clinic has one of the largest and most respected obstetrics
              and gynecology practices in the world, with more than 55
              specialists committed to providing comprehensive,
              multidisciplinary medical and surgical care.
            </p>
          </div>

          <div className="our_department_video_div">
            <video
              className="our_department_video"
              width="320"
              height="240"
              controls
            >
              <source src={our_department} type="video/mp4" />
            </video>
            <p className="our_department_video_div_p">Video about the Gynecology Department</p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OurDepartment;
