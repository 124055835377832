import "./OpenRound.css";
import { Link } from "react-router-dom";
import star from "../../../../assets/images/icons/star-icon.png";
import Helper from "../../../../helpers/api";

const OpenRound = (props) => {
  return (
    <div className="hospital_open_round">
      <input type={"hidden"} value={props.id ? props.id : ""} />
      <div className="imgAndText">
        <img
          className="openRoundImg"
          src={
            props.img
            // ? Helper.apiBaseUrl + "uploads" + props.img
            // : "/images/noImage/noImage.png"
          }
          alt="aa"
        />
        <div className="openRoundText">
          <h1 className="openRoundTextH">{props.header ? props.header : ""}</h1>
          <span className="starSection">
            <img className="starImg" src={star} alt="aa" />
            <p>4.6 (1435 ביקורות)</p>
          </span>
        </div>
      </div>
      <div className="hospitalsbtn">
        <Link className="choose" to={`/hospital/${props.id ? props.id : ""}`}>
          <img
            className="openSquareBtn"
            src="/images/hospitals/choose.png"
            alt="aa"
          />
        </Link>
      </div>
    </div>
  );
};

export default OpenRound;
