import React, { useEffect, useRef } from "react";

import { CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles 
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const DoNotChart = ({data, index}) => {
    
    return (
          <div className="nutricion_chart">
              <CircularProgressbar
                value={data.percentage}
                text={`${data.percentage}%`}
                strokeWidth={8}
                styles={buildStyles({
                  textColor: "var(--primary)",
                  pathColor: "var(--primary)",
                  trailColor: "var(--secondry)",
                })}
              />
              <p className="title">{data.title}</p>
          </div>
    );
  };
  
  export default DoNotChart;