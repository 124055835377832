import HealthSquare from "../../pregnancy/mybody/HealthSquare";

const SquareList = () => {
  const HealthListArr = [
    {
      link: "/ourdepartment",
      bg: "/images/gynecology/departments.jpg",
      header: "המחלקה שלנו",
      par: "",
      percentImg: "",
      percent: "",
    },
    {
      link: "",
      bg: "/images/gynecology/news.jpg",
      header: "החדשות שלנו",
      par: "",
      percentImg: "",
      percent: "",
    },
    {
      link: "/appointment",
      bg: "/images/gynecology/advice.jpg",
      header: "ייעוץ מקצועי",
      par: "",
      percentImg: "",
      percent: "",
    },
  ];

  return (
    <div className="healthList">
      {HealthListArr.map((el, i) => {
        return (
          <HealthSquare
            link={el.link}
            bg={el.bg}
            header={el.header}
            par={el.par}
            percentImg={el.percentImg}
            percent={el.percent}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default SquareList;
