import React from "react";
import Banner from "./banner/Banner.js";

import "./Club.css";

import PrivilegesImgSqaure from "./privilegesSqaure/PrivilegesImgSqaure.js";

const Club = () => {
  return (
    <>
      <div className="clubPage rtl">
        <Banner />

        <div className="clubPrivilegesList mt-5">
          <h1 className="clubPrivilegesListH">הטבות לחברות המועדון</h1>
          <p className="clubPrivilegesListP">
            תהני מההטבות המגיעות לחברות המועדון לנשים בהריון של וולפסון
          </p>
          <PrivilegesImgSqaure />
        </div>
      </div>
    </>
  );
};

export default Club;
