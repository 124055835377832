import { Link } from "react-router-dom"

const Off = () => {
	return (
		<Link to='/review' className='offLink'>
			<img className='offImg' src='/images/call/off.png' alt='off' />
		</Link>
	)
}

export default Off