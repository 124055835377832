import React from "react";
import { Input } from "reactstrap";

const DetailSquare = (props) => {
  return (
    <section className="detailSquare">
      {/* <div className="detailImgDiv">
        <img className="detailImg" src="/images/contact/photo.png" />
      </div>
      <div className="detailTextDiv">
        <label id="detailInfo" className="detailInfo">
          תמונה
        </label>
      </div> */}

      <div className="detailImgDiv">
        {/* <img className="detailImg" src={props.img} /> */}
        {props.img}
      </div>
      <div className="detailTextDiv">
        {/* {props.inputType == props.type && props.trueFalseState == true ? ( */}
        {false ? (
          <Input
            type={props.type}
            className={props.error ? "userDetail invalid-error" : "userDetail"}
            onChange={(e) => props.onChangeValue(e, props.type)}
            value={props.info}
          />
        ) : (
          <>
            <h1 className='detailInfo' >{props.type}</h1>
            <label id="detailInfo" className="detailInfo">
              {props.info}
            </label>
          </>
        )}
        <p className="signUpFormFeedback">{props.error ? props.error : ""}</p>
        <div className="btnArea">
          {props.inputType == props.type && props.trueFalseState == true ? (
            <button
              id="cancel"
              name="cancel"
              className="detailCancel"
              onClick={(e) => props.cancelBtn(e)}
            >
              Cancel
            </button>
          ) : (
            ""
          )}
          {props.inputType == props.type && props.trueFalseState == true ? (
            <button
              id="detailEdit"
              className="detailEdit"
              onClick={(e) => props.updateContactDetail(e)}
            >
              Update
            </button>
          ) : props.type == "email" ? (
            <button id="detailEdit" className="detailEdit">
              ערוך
            </button>
          ) : (
            <button
              id="detailEdit"
              className="detailEdit"
              onClick={(e) => props.editContactDetail(e, props.type)}
            >
              ערוך
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default DetailSquare;
