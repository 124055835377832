import { Link } from "react-router-dom"

const AppointmentSquare = () => {
	return (
		<Link to='/myappointment' className='appointmentSquare'>
			<div className='appointmentSquareH'>
				הפגישות שלך
			</div>
		</Link>
	)
}

export default AppointmentSquare