import "./Banner.css";
import React from "react";
import { Link } from "react-router-dom";

import banner from "../../../../assets/images/blog/banner.jpg";
import arrow from "../../../../assets/images/icons/arrow.png";

const Banner = () => {
  return (
    <div className="gynecologybanner">
      <img className="bannerBG" src={banner} />
      <Link to={"/appointment"}>
        <div className="bannerText">
          <div className="bannerTextH">הזמנת יעוץ</div>
          <img className="bannerTextImg" src={arrow} />
        </div>
      </Link>
    </div>
  );
};

export default Banner;
