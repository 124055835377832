import "./Patient.css";
import OpenSquare from "../../regularPages/repeatsComponents/opensquare/OpenSquare";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

const Patient = () => {
  const PatientSquareArr = [
    {
      img: "/images/patient/birth.jpg",
      header: "תוכנית לידה",
      text: "הרופאה שלך חנה לוי",
      link: "/birthplan",
    },
    {
      img: "/images/patient/stuff.jpg",
      header: "לצוות רפואי",
      text: "מחלות כרוניות, אלרגיות וכד",
      link: "/staff",
    },
    {
      img: "/images/patient/hospital.jpg",
      header: "פרוצדורות בבבית החולים",
      text: "זריקות, חיסונים וכד",
      link: "/procedures",
    },
    {
      img: "/images/patient/surgical.jpg",
      header: "התערבויות כירורגיות",
      text: "אם היו",
      link: "",
    },
    {
      img: "/images/patient/schedule.jpg",
      header: 'לו"ז',
      text: "ארוחות, זמני ביקורים וכד",
      link: "",
    },
    {
      img: "/images/patient/reviews.jpg",
      header: "ביקורות",
      text: "אנחנו מעריכים את דעתך",
      link: "",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="מידע על המטופל" />
        <div className="patientPage">
          <div className="patientImgDiv">
            <img className="patientImgPhoto" src="/images/patient/main.jpg" alt="aa" />
            <div className="patientImgText">
              <h1 className="patientImgH">טיפול בבית החולים</h1>
              <div className="patientImgP">
                עדכון מתמיד על מצב המטופל בבית החולים
              </div>
            </div>
          </div>
          <div className="patientTextDiv">
            <h2 className="patientTextH">היסטוריית אשפוז</h2>
            <div className="patientTextPDiv">
              <div className="patientTextPText">תאריך קבלת המטופל</div>
              <div className="patientTextPDate">07.11.2022</div>
            </div>
          </div>
          <div className="patientSquaresDiv">
            {PatientSquareArr.map((el, i) => {
              return (
                <OpenSquare
                  img={el.img}
                  header={el.header}
                  text={el.text}
                  link={el.link}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Patient;
