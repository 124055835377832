const initialState = {
  data: {},
};

const doctor = (state = initialState, action) => {
  switch (action.type) {
    case "DOCTOR_LIST":
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default doctor;
