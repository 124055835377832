import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search';

const Search = () => {

	const displaySearch = () => {
		const menu = document.querySelector('.menuSearch')
		if (menu.style.display === 'block') {
			menu.style.display = 'none'
		} else {
			menu.style.display = 'block'
		}
	}

	return (
		<div className='navSearchDiv'>
			<SearchIcon className='navSearchImg' />
			{/* <img className='navSearchImg' src='/images/vitamins/search.png' /> */}
			<input onClick={displaySearch} placeholder='חיפוש' type='search' className='navSearchInput' />
			<MicIcon className='microphone' />
		</div>
	)
}

export default Search