import "./Notifications.css";
import { connect } from "react-redux";
import NotificationsSquare from "./NotificationsSquare";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";

const Notifications = ({ notificationsArr }) => {
  notificationsArr.sort(function (a, b) {
    return new Date(b.formatDate) - new Date(a.formatDate);
  });

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="התראות" />
        <main className="notifications">
          {notificationsArr.map((el, i) => {
            return (
              <NotificationsSquare
                img={el.img}
                massageBeforeBold={el.massageBeforeBold}
                bold={el.bold}
                massageAfterBold={el.massageAfterBold}
                date={el.date}
                btn={el.btn}
                i={i}
                key={i}
              />
            );
          })}
        </main>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationsArr: state.reducer.notificationsArr,
  };
};

export default connect(mapStateToProps)(Notifications);
