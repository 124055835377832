import './Start.css';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Dates from '../../regularPages/repeatsComponents/datePicker/datePicker';
import Head from '../../repeats/head/Head';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Start = () => {

	useEffect(() => {
	// 	setPageInfo({header: 'התחלה', className: 'StartPage'})
	// 	const datePicker = document.querySelector('.mbsc-ios.mbsc-datepicker-inline')
	// 	datePicker.style.border = 0
		document.querySelector('.head').style.marginBottom = '5px'
	},[]);

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='התחלה' />
				<div className='startPage' >
					<img className='startImg' src='/images/start/congrats.png' />
						<main className='startMain'>
							<h1 className='startH1' >בשעה טובה ומוצלחת,<br/> אנו מאחלים לך הריון תקין ולידה קלה</h1>
							<h2 className='startH2' >בחרי תאריך</h2>
							{/*<div className='startDates' >
								<div className='date startDateUp' >
									<div className='day' >27</div>
									<div className='month' >ינואר</div>
									<div className='year' >2021</div>
								</div>
								<div className='date startDateChoosen' >
									<div className='day' >28</div>
									<div className='month' >פברואר</div>
									<div className='year' >2022</div>
								</div>
								<div className='date startDateDown' >
									<div className='day' >01</div>
									<div className='month' >מרץ</div>
									<div className='year' ></div>
								</div>
							</div>*/}
							<input type='date' />
							{/*<Dates />*/}
						</main>
						<div className='fixed_btn_section'>
							<Link to='/'>
								<button className='startSubmit' >לאישור</button>
							</Link>
						</div>
				</div>
			</div>
		</div>
	)
}

export default Start