import checkV from '../../../assets/images/vitamins/victory.png'

const CheckList = () => {
  const checkListArr = [
    {
      header: "אפידורל",
    },
    {
      header: "אפידורל",
    },
  ];

  const mark = (e) => {
    const header = e.target.nextSibling;
    console.log(header.style.color);
    if (header.style.color === "var(--primary)") {
      header.style.color = "#6A6A6A";
      e.target.style.background = "#6A6A6A";
    } else {
      header.style.color = "var(--primary)";
      e.target.style.background = "var(--primary)";
    }
  };

  return (
    <div className="checkList">
      {checkListArr.map((el, i) => {
        return (
          <div className="checkListItem" key={i}>
            {/* <div> */}
              <img
                onClick={mark}
                className="checkListItemV"
                src={checkV}
                alt="check"
              />
            {/* </div> */}
            <div className="checkListItemH">{el.header}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckList;
