import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import leftArrow from "../../../assets/images/icons/left-arrow.png";

const CategorySquareList = () => {
    const CategoryListArr = [
        {
            image: "/images/club/club_image1.png",
            title: "קבעת תור להתייעצות אונליין עם דר חנה לוי",
            arrow_img: { leftArrow },
            image_title: "התראות",
            link: "/myappointment",
        },
        {
            image: "/images/club/club_image1.png",
            title: "קבעת תור להתייעצות אונליין עם דר חנה לוי",
            arrow_img: { leftArrow },
            image_title: "התראות",
            link: "/myappointment",
        },
        {
            image: "/images/club/club_image1.png",
            title: "קבעת תור להתייעצות אונליין עם דר חנה לוי",
            arrow_img: { leftArrow },
            image_title: "התראות",
            link: "/myappointment",
        },
    ];

    const slides = CategoryListArr.map((el, index) => (
        <Link to={"" + el.link + ""} key={index}>
            <img src={el.image} alt="images" className="category_image" />
            <img className="right_arrow_img" src={el.arrow_img.leftArrow} alt="right-arrow" />
            <h6 className="image_title">{el.image_title}</h6>
            <h6 className="image_des_h6_1">{el.title}</h6>
        </Link>
    ));

    return (
        <>
            <Swiper spaceBetween={30}>
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default CategorySquareList;