import React from "react";

import "./PostPage2.css";

import Head from "../../../repeats/head/Head";
import OpenRound from "./openRound/OpenRound";
import Footer from "../../../repeats/footer/Footer";
import imgDoctor from "../../../../assets/images/helen.jpg";
import postPage from "../../../../assets/videos/ourDepartment/our_department.mp4";

import Banner from "./banner/Banner";

const PostPage2 = () => {
  const Header = {
    header: `ד"ר הלן מאיר`,
    text: "רופאת ילדים",
    link: "/fordoctors",
  };
  return (
    <div className="PageDad">
      <div className="page rtl">
        <Head text="מידע בשביל התינוק" />
        <div className="postPage_2">
          <Banner />
          {/* <div className="postImgDiv">
            <img className="postImgPhoto" src={baby} alt="baby" />
            <div className="postImgText">
              <h1 className="postImgH">.קוליק ראשון. איך לעזור לתינוק</h1>
            </div>
          </div> */}

          <div className="postPage_doctor">
            <OpenRound
              img={imgDoctor}
              header={Header.header}
              text={Header.text}
              link={Header.link}
            />
          </div>
          <div className="doctor_detail">
            <p>
              מה זה קוליק? <br />
              קוליק הוא כאשר תינוק בריא בוכה הרבה במשך זמן רב יותר מרוב התינוקות
            </p>
            <p>
              כל הילודים בוכים ומתרגזים לפעמים. במהלך 3 החודשים הראשונים לחייהם,
              הם בוכים יותר מבכל זמן אחר. אבל כאשר תינוק בריא בוכה יותר מ-3 שעות
              ביום, יותר מ-3 ימים בשבוע, הרופא עשוי לומר שלתינוק יש קוליק.
            </p>
            <p>
              קוליק לא אומר שלתינוק יש בעיות בריאותיות כלשהן. עם הזמן, הקוליק
              חולף מעצמו.
            </p>
            <p>לעוד מידע מוזמנת לצפות בסרטון למטה</p>
          </div>
          <div className="postPage_video_div">
            <video className="postPage_video" width="100%" height="240" controls>
              <source src={postPage} type="video/mp4" />
            </video>
            <p className="postPage_video_div_p">סרטון קצר על מחלקת גינקלוגיה</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PostPage2;
