const Photo = ({img}) => {
	return (
		<div className='callImgDiv'>
			<div className='callImgSecondCircle'>
				<img className='callImg' src={img} alt='call' />
			</div>
		</div>
	)
}

export default Photo