import React from "react";
import { Link } from "react-router-dom";
import "./SearchPage.css";

const SearchList = ({ image, text, link }) => {
  return (
    <Link className="searchSquareLink" to={link}>
      <div className="searchSquare">
        <div className="searchSquareImg">
          <img className="searchSquareImgBG" src={image} />
            <h1 className="searchSquareImgOff">{text}</h1>
        </div>
      </div>
    </Link>
  );
};

export default SearchList;
