import Paragraph from './Paragraph'
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { BsHandbag } from 'react-icons/bs';
import ChatIcon from '@mui/icons-material/Chat';

const PList = () => {

	const containerArr = [
		{
			img: <MedicationLiquidOutlinedIcon className='paragraphImg' />,
			text: 'מידע מקיף על בריאותך בזמן ההריון הכולל תזונה נכונה ופעילות גופנית מומלצת ע"י בעלי המקצוע'
		},
		{
			img: <ContactPhoneOutlinedIcon className='paragraphImg' />,
			text: `ליווי רפואי של צוות בתי החולים באמצעות מגוון אופציות לבחירתך הכוללות וידאו, שיחה קולית וצ'אט אונליין הפועלים  24/7`
		},
		{
			img: <SupportAgentIcon className='paragraphImg' />,
			text: 'זימון אוטומטי מראש וניהול הבדיקות השגרתיות לפי שבועות ההריון'
		},
		{
			img: <SmartToyIcon className='paragraphImg' />,
			text: `קבלת תשאולים והמלצות ע"י בינה מלאכותית בנוגע למצבך ולפיכך יומלץ או ייקבע תור אוטומטי לייעוץ פרונטלי/טלפוני/וידאו/צ'אט מול הגורמים הרפואיים כגון- רופא/אחות/דולה בהתאם. זאת ניתן לזמן בכל קופות החולים, דבר אשר יקל על תהליך הבדיקות הרפואיות וייסע לך לעבור אותו בהצלחה. הדבר נוסף על ביטוחי הבריאות הקיימים`
		},
		{
			img: <BsHandbag className='paragraphImg' />,
			text: `חנות מקוונת הכוללת את כל המוצרים הנדרשים לך במהלך ההריון ולקראת הלידה, הדבר כולל תשלום אונליין ומשלוח`
		},
		{
			img: <ChatIcon className='paragraphImg' />,
			text: `צ'אט פנימי מאובטח ופרטי אשר יאפשר לך שיתוף מסעך עם קרובייך`
		},
	]

	return (
		<main className='PList' >
			{
				containerArr.map((el, i) => {
					return (
						<Paragraph img={el.img} text={el.text} /> 
					)
				})
			}
		</main>
	)
}

export default PList