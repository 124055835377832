import './Home.css'
import AppointmentSquare from './AppointmentSquare'
import MyHealth from './MyHealth'
import ProductsList from '../productsList/ProductsList'
import HospitalList from './hospitalList/HospitalList'
import DoctorListSqaure from './DoctorListSquare/DoctorListSqaure'
import BlogSquareList from './BlogSquare/BlogSquareList'

const Home = () => {
	return (
		<div className='homeWebPage'>
			<AppointmentSquare />
			<MyHealth />
			<ProductsList />
			<HospitalList />
			<DoctorListSqaure />
			<BlogSquareList />
		</div>
	)
}

export default Home