import React from "react";

import our_department from "../../../assets/images/ourDepartment/our_department.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const Banner = () => {
  return (
    <>
      <Swiper spaceBetween={30} className="service_slider">
        <SwiperSlide>
          <div className="ourDepartmentBanner">
            <img className="bannerBG" src={our_department} alt="banner" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="ourDepartmentBanner">
            <img className="bannerBG" src={our_department} alt="banner" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="ourDepartmentBanner">
            <img className="bannerBG" src={our_department} alt="banner" />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Banner;
