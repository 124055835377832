import { Link } from "react-router-dom"

const Btn = ({text, link, className}) => {
	return (
		<div className='StartBtnDiv'>
			<Link to={link} className={className} >{text}</Link>
		</div>
	)
}

export default Btn