import React from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import Search from "../../regularPages/repeatsComponents/search/Search";

import { Link } from "react-router-dom";

import filter_icon from "../../../assets/images/icons/star-filter.png";
import search_icon from "../../../assets/images/icons/search.png";

import doctor1 from "../../../assets/images/doctor/doctor1.jpg";
import bills1 from "../../../assets/images/bills/bills1.jpg";
import dottedBorder from "../../../assets/images/icons/dotted-border.png";
import discount from "../../../assets/images/icons/discount.png";
import leftArrow from "../../../assets/images/icons/leftArrow.png";
import StarRoundIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';

import "./Bills.css";

const Bills = () => {
  return (
    <>
      <div className="PageDad">
        <div className="bills_pages rtl">
          <Head text="עגלת הקניות שלי" />
    
          <Search icon={<StarRoundIcon className="searchStar" />} />
    
          {/* <section className="search_sec">
            <div className="search_area">
              <Link to={"#"} className="filter_icon">
                <img src={filter_icon} alt="filter" />
              </Link>
              <div className="search_form">
                <button type="submit" className="btn_sub">
                  <img src={search_icon} alt="search" className="SearchImg" />
                </button>
                <input
                  type="search"
                  name=""
                  id=""
                  className="input_ser"
                  placeholder="חפשי לפי שם"
                />
              </div>
            </div>
          </section> */}

          <div className="bills_pages_area">
            <div className="bills_pages_doctor_area">
              <div className="img_and_text">
                <img className="bills_pages_doctor_img" src={doctor1} />
                <div className="bills_pages_doctor_text">
                  <h1 className="bills_pages_doctor_text_h1">
                    ד"ר חנה לוי
                  </h1>
                </div>
              </div>
            </div>

            <div className="bills_pages_card_area">
              <div className="img_and_text">
                <img className="bills_pages_card_img" src={bills1} />
                <div className="bills_pages_card_text">
                  <h1 className="bills_pages_card_h1">ייעוץ אונליין</h1>
                  <p className="bills_pages_card_p">היום 24.04.2022</p>
                </div>
              </div>
              <div className="bills_pages_card_btn_area">
                <Link className="bills_pages_card_btn" to={"#"}>
                  50$
                </Link>
              </div>
            </div>

            <div className="bills_pages_coupon_code_area">
              <img
                src={dottedBorder}
                className="dotted_border_img"
                alt="dotted-border"
              />
              <img src={leftArrow} className="right_arrow" alt="right-arrow" />
              <VerifiedIcon className="discount" />
              <input
                type={"text"}
                className="coupon_code"
                placeholder="לשימוש בקופון כתבי כאן את הקוד"
              />
            </div>

            <div className="bills_pages_pay_now_area">
              <div className="bills_pages_pay_now_heading align-center justify-between">
                <Link to={"#"} className="d-flex align-center">
                  $50
                </Link>
                <h3>סה"כ</h3>
              </div>

              <div className="bills_pages_box_btn">
                <div className="bills_pages_box_btn_area">
                  <Link to={"/paynow"} className="bills_pages_box_go_btn">
                    שלמי עכשיו
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Bills;
