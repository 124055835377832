import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import leftArrow from "../../../assets/images/icons/left-arrow.png";

const BlogSquareList = () => {
    const BlogListArr = [
        {
            image: "/images/blog/our_blog1.png",
            title: "תרופות לטיפול בכאב וסימפטומים אחרי לידה ",
            arrow_img: { leftArrow },
            link: "#",
        },
        {
            image: "/images/blog/our_blog1.png",
            title: "איך לבנות לוח זמנים לשינה של התינוק שלך ",
            arrow_img: { leftArrow },
            link: "#",
        },
        {
            image: "/images/blog/our_blog1.png",
            title: "8 תחליפי הנקה שכל אמא  טרייה צריכה להכיר",
            arrow_img: { leftArrow },
            link: "#",
        },
    ];

    const slides = BlogListArr.map((el, index) => (
        <Link to={"" + el.link + ""} key={index}>
            <img src={el.image} alt="images" />
            <img className="right_arrow_img" src={el.arrow_img.leftArrow} alt="right-arrow" />
            <h6 className="image_des_1">{el.title}</h6>
        </Link>
    ));

    return (
        <>
            <Swiper spaceBetween={30}>
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default BlogSquareList;