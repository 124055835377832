import React from "react";
import { Link } from "react-router-dom";

const PrivilegesImgSqaure = () => {
  const privilegesImgArr = [
    {
      img: "/images/web/club/club1.jpg",
      detail: "פגישה אישית עם המיילדת",
      span: "בחינם",
      link: "/postpage1",
    },
  ];

  const privilegesSmallImgArr1 = [
    {
      img: "/images/web/club/club2.jpg",
      detail:
        "קורס הכנה ללידה - מתנה לחברות המועדון שיולדות בוולפסוון",
      link: "/postpage2",
    },
    {
      img: "/images/web/club/club3.jpg",
      detail: "סדנת החייאת תינוקות",
      link: "/postpage1",
    },
  ];

  const privilegesSmallImgArr2 = [
    {
      img: "/images/web/club/club4.jpg",
      detail: "סדנת החייאת תינוקות",
      link: "/postpage2",
    },
    {
      img: "/images/web/club/club5.jpg",
      detail: "סיוע בטיפולים אלטרנטיביים (עיסוי, שיאצו, סו-ג'וק)",
      link: "/postpage1",
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <div className="clubPrivilegesSmallImgList">
            <div className="row">
              {privilegesSmallImgArr1.map((el, i) => {
                return (
                  <div className="col-6" key={i}>
                    <Link to={"" + el.link + ""}>
                      <div className="clubPrivilegesSmallImgDiv">
                        <img
                          src={el.img}
                          className="clubPrivilegesSmallImgDivImg"
                          alt="club"
                        />
                        <p className="clubPrivilegesSmallImgDivP">
                          {el.detail}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="row">
              {privilegesSmallImgArr2.map((el, i) => {
                return (
                  <div className="col-6" key={i}>
                    <Link to={"" + el.link + ""}>
                      <div className="clubPrivilegesSmallImgDiv">
                        <img
                          src={el.img}
                          className="clubPrivilegesSmallImgDivImg"
                          alt="club"
                        />
                        <p className="clubPrivilegesSmallImgDivP">
                          {el.detail}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12">
          {privilegesImgArr.map((el, i) => {
            return (
              <Link to={"" + el.link + ""}>
                <div className="clubPrivilegesImgDiv" key={i}>
                  <img src={el.img} className="clubPrivilegesImg" alt="club" />
                  <p className="clubPrivilegesImgP">
                    {el.detail}{" "}
                    <span className="clubPrivilegesImgSpan">{el.span}</span>
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PrivilegesImgSqaure;
