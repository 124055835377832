export const addAction = () => {
  return {
    type: "ADD",
  };
};

export const addDateAction = () => {
  return {
    type: "ADDDATE",
  };
};

export const changeDateAction = (date) => {
  return {
    type: "CHANGEDATE",
    payload: date,
  };
};

export const updateUserProfileImage = (data) => {
  return {
    type: "UPDATE_PROFILE_IMAGE",
    payload: data,
  };
};
