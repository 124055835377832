// import './OpenRound.css';
import { Link } from "react-router-dom";
import star from "../../../assets/images/star-icon.png";
import StarRoundIcon from '@mui/icons-material/Star';

const DoctorAbout = ({ img, header, text, link }) => {
  return (
    <div className="openRound">
      <div className="imgAndText">
        <img className="openRoundImg" src={img} alt="aa" />
        <div className="openRoundText">
          <div className="openRoundTextH">{header}</div>
          <div className="openRoundTextP">{text}</div>
          <div className="starSection">
            <StarRoundIcon className="starImg" />
            {/* <img className="starImg" src={star} alt="aa" /> */}
            <p>5.0 (1435 ביקורות)</p>
          </div>
        </div>
      </div>
      <div className="doctorsbtn">
        <Link className="about" to={"/doctor"}>
          אודות
        </Link>
      </div>
    </div>
  );
};

export default DoctorAbout;
