import React, { useState, useEffect } from "react";
import Head from "../../repeats/head/Head";
import Banner from "./Banner";
import DoctorList from "../../regularPages/doctorList/DoctorList";
import ServiceList from "../../regularPages/serviceList/ServiceList";
import "./Hospital.css";
import Departments from "./departments/Departments";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HospitalAction } from "../../../actions/hospital";
import Toast from "../../../helpers/toast";

const Hospital = () => {

  const params = useParams();

  const dispatch = useDispatch();

  const hospitalAction = HospitalAction();

  const [hospital, setHospital] = useState([]);

  useEffect(() => {
    (async () => {
      //This is to get service list
      await hospitalAction
        .getHospitalByID(params.id)
        .then((response) => {
          dispatch({ type: "UNSET_LOADER" });

          if (response) {
            if (response.success == true) {
              setHospital(response.vendor);
            } else {
              Toast.error(response.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    })();
  }, []);

  return (
    <>
      <div className="PageDad">
        <div className="hospitalpages rtl">
          <Head text="בתי חולים" isBackArrow={true} />

          <Banner
          // hospital={hospital} 
          />

          <Departments />

          <DoctorList />

          <ServiceList />

          <div className="box_btn">
            <div className="container">
              <div className="btn_area">
                <Link to={"/hospitalstay"} className="go_btn">
                  לאשפוז
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hospital;
