import React from "react";

import { Link } from "react-router-dom";

const ClubSquare = ({ club_image, title, link, club_img_key }) => {
  return (
    <>
      <div className="list_box col-6 pb-5">
        <Link to={"" + link + ""}>
          <div className="box_img">
            <img src={club_image} alt="service" />
            {club_img_key === 1 ? (
              <></>
            ) : club_img_key === 2 ? (
              <>
                <span className="discount_dt">חינם</span>
              </>
            ) : club_img_key === 3 ? (
              <>
                <span className="discount_dt">חינם</span>
              </>
            ) : club_img_key === 4 ? (
              <></>
            ) : (
              ""
            )}
          </div>
        </Link>
        <div className="box_detail d-flex justify-content-between">
          <h6>{title}</h6>
        </div>
      </div>
    </>
  );
};

export default ClubSquare;
