import "./Blog.css";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import Search from "../../regularPages/repeatsComponents/search/Search";
import OpenSquare from "../../regularPages/repeatsComponents/opensquare/OpenSquare";

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Blog = () => {
  const blogSquareArr = [
    {
      img: "/images/blog/mom.jpg",
      header: "מידע בשביל האמא",
      text: "",
      link: "/blogpostlist",
    },
    {
      img: "/images/blog/baby.jpg",
      header: "מידע בשביל התינוק",
      text: "",
      link: "/blogpostlist",
    },
    {
      img: "/images/blog/pregnancy.jpg",
      header: "מחקרים רפואיים",
      text: "",
      link: "/blogpostlist",
    },
    {
      img: "/images/blog/postpartum.jpg",
      header: "הכנה להריון",
      text: "",
      link: "/blogpostlist",
    },
    {
      img: "/images/blog/article.jpg",
      header: "מה עושים לאחר הלידה",
      text: "",
      link: "/blogpostlist",
    },
    {
      img: "/images/blog/research.jpg",
      header: "מאמרים שימושיים",
      text: "",
      link: "/blogpostlist",
    },
  ];

  return (
    <div className="PageDad">
      <div className="page">
        <Head text="הבלוגים שלנו" />
        <div className="blogPage">
          <Search icon={<FavoriteBorderIcon className="searchStar" />} />
          <div className="staffSquaresDiv">
            {blogSquareArr.map((el, i) => {
              return (
                <OpenSquare
                  img={el.img}
                  header={el.header}
                  text={el.text}
                  link={el.link}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
