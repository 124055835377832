import './BirthPlan.css';
import BirthPlanSquare from './BirthPlanSquare'
import Search from '../../regularPages/repeatsComponents/search/Search'
import ListHeader from '../../regularPages/repeatsComponents/listheader/ListHeader'
import Head from '../../repeats/head/Head';
import Footer from '../../repeats/footer/Footer';

import StarRoundIcon from '@mui/icons-material/Star';

const BirthPlan = () => {

	const birthPlanSquareArr = [
		{
			header: ':תאריך יעד/תאריך כניסה',
			text: '07.11.2022',
			content: 'בלה בלה בלה בלה בלה בלה'
		},
		{
			header: 'צוות הלידה',
			text: 'חנה לוי / לאה מזרחי / חווה פרישברג / דנה בלום',
			content: 'רופאה: חנה לוי, אחות: לאה מזרחי, אשת תמיכה: חווה פרישברג, דולה: דנה בלום',
		},
		{
			header: ':במהלך הצירים',
			text: 'אם זה אפשרי רפואית, אני ארצה ש (סמני למטה)',
		},
		{
			header: ':התערבויות רפואיות' ,
			text: 'אני מעדיפה ש (סמני למטה)',
		},
		{
			header: ':משככי כאבים',
			text: 'אני מעדיפה ש (סמני למטה)',
		},
		{
			header: 'לידה',
			text: 'אני ארצה ש (סמני למטה)',
		},
	]

	const ListHeaderObj = {
		header: ' אשת התמיכה: - ',
		headerSpan: 'חווה פרישברג',
		par: 'מרכז לידה - ',
		parSpan: 'המרכז לטיפול בהריון',
	}

	return (
		<div className='PageDad' >
			<div className='page' >
				<Head text='תוכנית לידה' />
				<div className='birthPlanPage'>
					<Search icon={<StarRoundIcon className="searchStar" />} />
					<ListHeader
						header={ListHeaderObj.header}
						headerSpan={ListHeaderObj.headerSpan}
						par={ListHeaderObj.par}
						parSpan={ListHeaderObj.parSpan}
					/>
					<div className='birthPlanSquaresDiv'>
						{
							birthPlanSquareArr.map((el, i) => {
								return (
									<BirthPlanSquare
										header={el.header}
										text={el.text}
										content={el.content}
										link={el.link}
										i={i}
										key={i} 
									/> 
								)
							})
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>

	)
}

export default BirthPlan