import React, { useState, useEffect } from "react";
import Head from "../../repeats/head/Head";
import Footer from "../../repeats/footer/Footer";
import "./Departments.css";
import OpenRound from "./openRound/OpenRound";
import Filter from "../../../assets/images/icons/Filter.png";
import Search from "../../regularPages/repeatsComponents/search/Search";
import search_icon from "../../../assets/images/icons/search.png";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link } from "react-router-dom";
import { DepartmentAction } from "../../../actions/department";
import { useDispatch } from "react-redux";
import Toast from "../../../helpers/toast";

const Departments = () => {
  const departmentAction = DepartmentAction();

  const dispatch = useDispatch();

  const [department, setDepartment] = useState([]);

  useEffect(() => {
    (async () => {
      //This is to get service list
      await departmentAction
        .getDepartments()
        .then((response) => {
          dispatch({ type: "UNSET_LOADER" });

          if (response) {
            if (response.success == true) {
              setDepartment(response.departments);
            } else {
              Toast.error(response.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    })();
  }, []);

  const departmentsArr = [
    {
      img: "/images/departments/department1.jpg",
      header: "יולדות",
      link: "/gynecology",
    },
    {
      img: "/images/departments/department2.jpg",
      header: "גינקולוגיה",
      link: "/gynecology",
    },
    {
      img: "/images/departments/department3.jpg",
      header: "אמהות",
      link: "/gynecology",
    },
    {
      img: "/images/departments/department4.jpg",
      header: "תינוקות",
      link: "/gynecology",
    },
    {
      img: "/images/departments/department5.jpg",
      header: "ילדים",
      link: "/gynecology",
    },
    {
      img: "/images/departments/department6.jpg",
      header: "ניתוחים",
      link: "/gynecology",
    },
  ];

  return (
    <>
      <div className="PageDad">
        <div className="page">
          <Head text="המחלקות שלנו" />

          <div className="departmentsPage">
            <Search icon={<FavoriteBorderIcon className="searchStar" />} />
            {/* <section className="search_sec">
              <div className="search_area">
                <Link to={"#"} className="filter_icon">
                  <img src={Filter} alt="filter" />
                </Link>
                <div className="search_form">
                  <button type="submit" className="btn_sub">
                    <img src={search_icon} alt="Search" className="SearchImg" />
                  </button>
                  <input
                    type="Search"
                    name=""
                    id=""
                    className="input_ser"
                    placeholder="חפשי לפי שם"
                  />
                </div>
              </div>
            </section> */}

            <div className="staffSquaresDiv">
              {departmentsArr.map((el, i) => {
                return <OpenRound key={i} img={el.img} header={el.header} />;
                // return <OpenRound key={i} img={el.photo} header={el.name} />;
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Departments;
