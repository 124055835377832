import { Routes, Route } from "react-router-dom";
import "./Page.css";
import Nav from "./nav/Nav";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import ProductsDetails from "./productsDetails/ProductsDetails";
import DoctorsSearch from "./searchPages/doctorsSearch/DoctorsSearch";
import TreatmentsSearch from "./searchPages/treatmentsSearch/TreatmentsSearch";
import DepartmentsSearch from "./searchPages/departmentsSearch/DepartmentsSearch";
import SearchPage from "./searchPages/search/SearchPage";
// import SettingsPage from "./settingsPage/SettingsPage";
import Account from "./account/Account";
import ContactUs from "./contactUs/ContactUs";
import Hospital from "./hospital/Hospital";
import LikePage from "./likePage/LikePage";
import AboutDepartment from "./aboutdepartment/AboutDepartment";
import Department from "./department/Department";
import Hospitals from "./hospitals/Hospitals";
import SearchAll from "./searchPages/searchAll/SearchAll";
import Club from "./club/Club";
import { useSelector } from "react-redux";

const Page = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className="webPage">
      <Nav />
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="productdetails" element={<ProductsDetails />} />
        <Route path="doctorsearch" element={<DoctorsSearch />} />
        <Route path="treatmentsearch" element={<TreatmentsSearch />} />
        <Route path="departmentsearch" element={<DepartmentsSearch />} />
        {/* <Route path="settings" element={<SettingsPage />} /> */}
        {user.id ? <Route path="account" element={<Account />} /> : ""}
        <Route path="contactus" element={<ContactUs />} />
        <Route path="hospital" element={<Hospital />} />
        <Route path="like" element={<LikePage />} />
        <Route path="department" element={<Department />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="aboutdepartment" element={<AboutDepartment />} />
        <Route path="hospitals" element={<Hospitals />} />
        <Route path="searchall" element={<SearchAll />} />
        <Route path="club" element={<Club />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Page;
