import { Link } from "react-router-dom";

const InfoSquare = (props) => {
  return (
    <Link to={props.link} className="infoSquare">
      <div className="infoSquareImgDiv">
        <img className="infoSquareImg" src={props.img} />
      </div>
      <div className="infoSquareTextDiv">
        <h1 className="infoSquareH">{props.header}</h1>
        <div className="infoSquareP">{props.paragraph}</div>
      </div>
      <div className="infoSquareEdit">
        <Link className="linkEdit" to={props.link}>
          עריכה
        </Link>
      </div>
    </Link>
  );
};

export default InfoSquare;
