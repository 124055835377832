import React from "react";
import { Routes, Route } from "react-router-dom";
// import Frontendlayout from "../../components/Frontend/Layout/Frontendlayout";

import Home from "./../components/home/Home";

import Account from "./../components/profile/account/Account";
import Contact from "./../components/profile/contact/Contact";
import Health from "./../components/profile/health/Health";

import Settings from "./../components/settingsPages/settings/Settings";
import SettingsNotifications from "./../components/settingsPages/settingsNotifications/SettingsNotifications";

import MultipleLayout from "./../components/repeats/multipleLayout/multipleLayout";

export default function UserPrivateRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/account" element={<Account />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/health" element={<Health />} />

        <Route
          path="/settings"
          element={<MultipleLayout componentName={<Settings />} />}
        />
        <Route
          path="/settingsnotifications"
          element={<SettingsNotifications />}
        />
      </Routes>
    </>
  );
}
