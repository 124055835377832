import Btn from './Btn'

const BtnsList = () => {

	const btnsListArr = [
		{
			link: '/web/page/home',
			text: 'בית'
		},
		{
			link: '/web/page/productdetails',
			text: 'חנות'
		},
		{
			link: '/web/page/hospital',
			text: 'בתי-חולים'
		},
		{
			link: '/web/page/club',
			text: 'מועדון'
		},
		{
			link: '/web/page/settings',
			text: 'הגדרות'
		},
	]

	return (
		<div className='navBtnsList'>
			{
				btnsListArr.map((el, i) => {
					return (
						<Btn
							link={el.link}
							text={el.text}
							key={i}
						/> 
					)
				})
			}
		</div>
	)
}

export default BtnsList