import { Link } from "react-router-dom"

const MyHealthSquare = ({img, link, h6_text}) => {
	return (
    <Link className="my_health_box_img" to={link} >
        <img src={img} alt="my_health1" />
        <div className='my_health_boxH'>{h6_text}</div>
    </Link>
	)
}

export default MyHealthSquare