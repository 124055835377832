const AidCard = ({img, text}) => {
	return (
		<div className='aidCard'>
			<img className='aidCardImg' src={img} />
			<div className='aidCardHDiv'>
				<div className='aidCardH' >{text}</div>
			</div>
		</div>
	)
}

export default AidCard