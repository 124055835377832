import "./OpenRound.css";
import { Link } from "react-router-dom";
import Helper from "../../../../helpers/api";

const OpenRound = (props) => {
  return (
    <div className="departments_open_round">
      <div className="imgAndText">
        <img
          className="openRoundImg"
          src={
            props.img
              ? props.img
              // ? Helper.apiBaseUrl + "uploads" + props.img
              : "/images/noImage/noImage.png"
          }
        />
        <div className="openRoundText">
          <h1 className="openRoundTextH">{props.header}</h1>
        </div>
      </div>
      <div className="departmentsbtn">
        <Link className="choose" to={"/gynecology"}>
          <img className="openRoundBtn" src="/images/opensquare/openHeb.png" />
        </Link>
      </div>
    </div>
  );
};

export default OpenRound;
