import React from "react";
import { Container } from "react-bootstrap";
import BlogSquareList from "../home/BlogSquare/BlogSquareList";
import DoctorListSqaure from "../home/DoctorListSquare/DoctorListSqaure";
import Banner from "./banner/Banner";
import DepartmentsList from "./departmentsList/DepartmentsList";
import ServicesSqaureList from "./servicesSqaure/ServicesSqaureList";

import "./Hospital.css";

const Hospital = () => {
  return (
    <>
      <div className="hospitalWebPages rtl">
        {/* <Container> */}
          <Banner />
          <DepartmentsList />
          <DoctorListSqaure />
          <ServicesSqaureList />
          <BlogSquareList />
        {/* </Container> */}
      </div>
    </>
  );
};

export default Hospital;
